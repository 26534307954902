import { Button, CircularProgress, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ItemWithSerialNumberViewProps } from './ItemWithSerialNumber.type';
import { ItemWithSerialNumbers } from '../../states/component/ItemWithSerialNumber';
import UiTable from '../Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
import { ItemWithSerialNumberFormik } from './ItemWithSerialNumber.formik';
import { useTranslation } from 'react-i18next';
// import module

export const ItemWithSerialNumberContent: React.FC<ItemWithSerialNumberViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='ItemWithSerialNumber'
                    data={useRecoilValue(ItemWithSerialNumbers)}
                    loading={props.isLoading}
                    columns={props.columns}
                    onAdd={() => props.openModal}
                    total={props.total}
                    extraFiltering={props.extraFiltering}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter, extraFiltering }) => {
                        props.fetchData(page + 1, limit, order, filter, extraFiltering);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'createdAt',
                        desc: true
                    }}
                    hiddenColumns={['createdAtFrom', 'createdAtTo']}
                    forceRefresh={props.refresh}
                    hasExportSKU
                />
            </Paper>

            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                title={props.dataModal?.title}
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <ItemWithSerialNumberFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            dataModal={props.dataModal}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {translate('t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                        </Button>
                    </>
                }
            />
        </>
    );
};
