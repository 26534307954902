import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import StockLocationDropdown from '../StockLocationDropdown/StockLocationDropdown';
import SkusDropDown from '../SkusDropDown/SkusDropDown';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { GenericItemFormik as GenericItemFormikType, IGenericItemsForm } from './GenericItems.type';
import { InputElement } from '../FormElements';
import { useTranslation } from 'react-i18next';

export const GenericItemFormik: React.FC<GenericItemFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const Formschema = Yup.object({
        skuSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        }),
        quantity: Yup.number()
            .required(translate('p.this_field_is_required') as string)
            .moreThan(0, translate('p.quantity_must_be_greater_than_0') as string),
        locationSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        })
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: IGenericItemsForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<IGenericItemsForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='initial' direction='row'>
                                <SkusDropDown
                                    name='skuSelect'
                                    url='/type/generic_item/all'
                                    paramsUrl={[{ id: 'type', value: 'active' }]}
                                    label={translate('t.sku')}
                                    value={props.dataModal?.isAdd ? values.skuSelect[0] : values.skuSelect}
                                    error={!!errors.skuSelect}
                                    onChange={(value) => {
                                        setFieldValue('skuSelect', value ? value : '');
                                    }}
                                    required={true}
                                    textRequired={translate('p.this_field_is_required')}
                                />

                                <StockLocationDropdown
                                    name='locationSelect'
                                    label={translate('t.stock_location')}
                                    value={props.dataModal?.isAdd ? values.locationSelect[0] : values.locationSelect}
                                    error={!!errors.locationSelect}
                                    onChange={(value) => {
                                        setFieldValue('locationSelect', value ? value : '');
                                    }}
                                    required={true}
                                    textRequired={translate('p.this_field_is_required')}
                                />

                                <InputElement
                                    label={translate('t.quantity')}
                                    name='quantity'
                                    value={values.quantity}
                                    error={errors.quantity}
                                    touched={touched.quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type='number'
                                    required={true}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
