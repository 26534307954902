import styled from 'styled-components';

export const StepFiveContent = styled.div``;

export const FieldsetCustom = styled.fieldset`
    border-radius: 5px;
    border: thin solid #A9A9A9;
`;

export const StepFiveLegend = styled.legend`
  text-transform: capitalize;
`;



