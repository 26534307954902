import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import * as style from './Dashboard.style';
import { DashboardProps } from './Dashboard.type';
import { useTranslation } from 'react-i18next';

export const DashboardContent: React.FC<DashboardProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <style.DashboardContent data-testid='DashboardContent'>
            <div className='dashboardContent'>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper className='dashboardContainer'>
                            <h2>{translate('t.stock_control')}</h2>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <style.Dashboardbody />
        </style.DashboardContent>
    );
};
