import React, { useEffect, useState } from 'react';
import Wrapper from '../../../helpers/wrapper';
import { SiteSurveyStepOneForm, StepOneProps } from './StepOne.type';
import { StepOneContent } from './StepOne.view';
import { ITimeZoneFormatted, TimeZoneResponseType } from '../../../models/ApiTimeZone.type';
import ApiTimeZone from '../../../api/ApiTimeZone';
import { getSortedTimezone } from '../../../helpers/converter';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
import { Success, Warning } from '../../Popup/Popup';
import ApiSurvey from '../../../api/ApiSurvey';
import { useTranslation } from 'react-i18next';
import { DateTimeFormat } from '../../../helpers/Converters';
// import module

const apiTimeZone = new ApiTimeZone();
const apiSurvey = new ApiSurvey();

const StepOne: React.FC<StepOneProps> = ({
    activeStep,
    publicMode,
    bottonHiddenUpdate,
    handleNext,
    handleBack,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [surveyData, setSurveyData]  = useRecoilState(SurveyState);
    const [timeZones, setTimeZones] = useState<ITimeZoneFormatted[]>([]);
    const [emails, setEmails] = useState<string>(surveyData?.email.join(',') ?? '');
    const [loadingEmails, setLoadingEmails] = useState<boolean>(false);
    const dateTimeFormat = useRecoilValue(DateTimeFormat);
    // eslint-disable-next-line max-len
    const emailOmboarding = process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.includes('https://webtrack-dev.atmstechnology.com')
    ? 'onboarding_test@bmst.bridgestone'
    : 'onboarding@bmst.bridgestone';
    
    const dataStepOne: SiteSurveyStepOneForm = {
        mineOwner: checkSurvey(surveyData, 'mineOwner') ?? '',
        location: checkSurvey(surveyData, 'location') ?? '',
        startDate: checkSurvey(surveyData, 'startDate') ?? '',
        siteName: checkSurvey(surveyData, 'siteName') ?? '',
        timeZone: !surveyData?.timezone ? checkSurvey(surveyData, 'timeZone') : surveyData?.timezone.id,
        surveyDate: checkSurvey(surveyData, 'surveyDate') ?? '',
        customerAttention: checkSurvey(surveyData, 'customerAttention') ?? '',
        customerEmail: checkSurvey(surveyData, 'customerEmail') ?? '',
        customerTelephone: checkSurvey(surveyData, 'customerTelephone') ?? '',
        customerAddress: checkSurvey(surveyData, 'customerAddress') ?? '',
        projectCoordinatorName: checkSurvey(surveyData, 'projectCoordinatorName') ?? '',
        projectCoordinatorEmail: checkSurvey(surveyData, 'projectCoordinatorEmail') ?? '',
        projectCoordinatorTelephone: checkSurvey(surveyData, 'projectCoordinatorTelephone') ?? '',
        mineITName: checkSurvey(surveyData, 'mineITName') ?? '',
        mineITEmail: checkSurvey(surveyData, 'mineITEmail') ?? '',
        mineITTelephone: checkSurvey(surveyData, 'mineITTelephone') ?? '',
        mineDispatchName: checkSurvey(surveyData, 'mineDispatchName') ?? '',
        mineDispatchEmail: checkSurvey(surveyData, 'mineDispatchEmail') ?? '',
        mineDispatchTelephone: checkSurvey(surveyData, 'mineDispatchTelephone') ?? '',
        tyreProviderName: checkSurvey(surveyData, 'tyreProviderName') ?? '',
        tyreProviderEmail: checkSurvey(surveyData, 'tyreProviderEmail') ?? '',
        tyreProviderTelephone: checkSurvey(surveyData, 'tyreProviderTelephone') ?? ''
        // shippingAttention: checkSurvey(surveyData, 'shippingAttention'),
        // shippingEmail: checkSurvey(surveyData, 'shippingEmail'),
        // shippingTelephone: checkSurvey(surveyData, 'shippingTelephone'),
        // shippingAddress: checkSurvey(surveyData, 'shippingAddress'),
    };

    const getSelect = async () => {
        const { items }: TimeZoneResponseType = await apiTimeZone.getTimeZoneCodeBookPublic();
        items && setTimeZones(getSortedTimezone(items, dateTimeFormat) );
        
    };

    const resendSurvey = async () => {
        setLoadingEmails(true);
        Warning({
            text: `${translate('t.resending_survey')}`
        });

        await apiSurvey.updateEmails(
            surveyData?.id ?? 0,
            { email: emails.includes(emailOmboarding) ? emails : `${emails},${emailOmboarding}` }
        );

        await apiSurvey.resend(surveyData?.id ?? 0).then(() => {
            Success({
                text: `${translate('t.survey_resent')}`
            });
        });
        setLoadingEmails(false);
    };

    const updateEmails = async () => {
        setLoadingEmails(true);
        await apiSurvey.updateEmails(
            surveyData?.id ?? 0,
            { email: emails.includes(emailOmboarding) ? emails : `${emails},${emailOmboarding}` }
        ).then(({ data }) => {
            if(surveyData) {
                setSurveyData({ ...surveyData, email: data.customerOnboardingInvitation.email });
            }
            Success({
                text: `${translate('t.saved_changes')}`
            });
        });
        setLoadingEmails(false);
    };

    useEffect(() => {
        getSelect();
    }, []);

    return (
        <StepOneContent 
            data-testid='StepOne-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={dataStepOne}
            timeZones={timeZones}
            publicMode={publicMode}
            bottonHiddenUpdate={bottonHiddenUpdate}
            emails={emails}
            loadingEmails={loadingEmails}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            updateSurveyTemporal={updateSurveyTemporal}
            setEmails={setEmails}
            resendSurvey={resendSurvey}
            updateEmails={updateEmails}
            setModalReturnToProgress={setModalReturnToProgress}
        />
    );
};

export default Wrapper(StepOne);