import React from 'react';
import { Chip } from '@mui/material';
import { ChipBarProps, ModelCurrent } from './ChipBar.type';
import { SpanFilters } from './ChipBar.style';
import Wrapper from '../../../../../helpers/wrapper';
import { User } from '../../../../../states/global/User';
import { FullDataUser } from '../../../../User/User.type';
import { useRecoilValue } from 'recoil';
import { getLSUser, setToLSUser } from '../../../../../helpers/localStorage';
import { InitialStateType } from '../../UiTable.type';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ChipBar: React.FC<ChipBarProps> = (props): JSX.Element | null => {
    const { t: translate } = useTranslation();
    const instanceTableState: InitialStateType | Record<string, unknown> =
        getLSUser<InitialStateType>(`table.${props?.table?.title}Table`) || ({} as Record<string, unknown>);
    const userState = useRecoilValue<FullDataUser | null>(User);

    const onDelete = (filter: number | string): void => {
        props.table.setFilter(filter, undefined);
        props.setCurrentFilters((currentFilters) => {
            return currentFilters.filter((currentFilter) => currentFilter.id !== filter);
        });
        const getFilter: ModelCurrent[] | undefined = getLSUser<InitialStateType>(
            `table.${props.table?.title}Table`
        )?.filters;

        if (getFilter && !!getFilter.length) {
            const newListFilter = getFilter.filter((currentFilter) => currentFilter.id !== filter);
            props.table?.title &&
                setToLSUser(`table.${props.table?.title}Table`, {
                    ...instanceTableState,
                    filters: newListFilter
                });
        } else {
            props.table?.title &&
                setToLSUser(`table.${props.table?.title}Table`, {
                    ...instanceTableState,
                    filters: []
                });
        }
    };

    return props.currentFilters.length > 0 ? (
        <>
            <SpanFilters>{translate('t.active_filters')}:</SpanFilters>
            {props.currentFilters.map((filter, key) => {
                let selectValue: string = filter.value.humanValue || filter.value.value;

                // Check selectValue is array or not?
                if (Array.isArray(selectValue)) {
                    selectValue = selectValue.join(',');
                }

                if (filter.value.type == 'date' && userState && userState?.userSetting.dateFormat) {
                    selectValue = moment(filter.value.value, 'YYYY-MM-DD').format(userState?.userSetting.dateFormat);
                }

                const currentValue: string[] = selectValue?.split(';');
                const index: number = currentValue.length === 2 ? 1 : 0;

                const value = filter.id === 'serialNumber' ? currentValue[index] : currentValue[index];
                return (
                    <Chip
                        style={{ margin: '2px' }}
                        variant='outlined'
                        key={key}
                        label={
                            <>
                                {filter.value.name}:
                                <strong>
                                    {value}
                                </strong>
                            </>
                        }
                        onDelete={() => {
                            onDelete(filter.id);
                        }}
                    />
                );
            })}
        </>
    ) : null;
};

export default Wrapper(ChipBar);
