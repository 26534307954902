import moment from 'moment-timezone';
import * as type from './converterType';
import * as UserState from '../../states/global/User';
import { useRecoilValue } from 'recoil';
import { convertQs, dotToCamel } from '../converter/text';
import { ExtraFilter } from '../../types/common';
import { ITimeZoneFormatted, TimeZoneType } from '../../models/ApiTimeZone.type';

export const numberConverter: type.numberConverter = {
    decimalToHex: (number) => {
        return parseInt(number, 10).toString(16).toUpperCase();
    },
    hexToDecimal: (number) => {
        return parseInt(number, 16).toString(10);
    }
};

export const datetimeConverter: type.datetimeConverter = {
    fromUTCToTimezone: (utc: string, displaySeconds = false) => {
        const userData = useRecoilValue(UserState.User);
        const userTimezone: string = userData?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        const dateFormat = 'YYYY-MM-DD';
        let timeFormat = 'HH:mm:ss';

        if (!displaySeconds) {
            timeFormat = timeFormat.replace(':ss', '');
        }
        return moment.utc(utc).tz(userTimezone).format(`${dateFormat} ${timeFormat}`);
    },
    fromTimezoneToUTC: (datetime) => {
        const userData = useRecoilValue(UserState.User);

        return moment
            .tz(datetime, userData?.userSetting.timezone.timezoneName || 'Europe/Bratislava')
            .utc()
            .valueOf();
    },
    fromUserTimezoneToTimezone: (datetime) => {
        const userData = useRecoilValue(UserState.User);

        return moment
            .utc(moment.tz(datetime, userData?.userSetting.timezone.timezoneName || 'Europe/Bratislava').utc())
            .tz(userData?.userSetting.timezone.timezoneName || 'Europe/Bratislava');
    }
};

export const removeSpaces = (value: string): string => {
    const newValue: RegExpMatchArray | null = value.match(/\S+/g);
    return newValue ? newValue.join(' ') : '';
};

export const formatParametersToGetPagination = (
    page,
    limit,
    order,
    filter,
    search = '',
    extraFilter: ExtraFilter[] = []
) => {
    const params = { page, limit };
    if (order?.length > 0) {
        const desc: string = order[0].desc ? 'desc' : 'asc';
        params['order'] = `${order[0].id}:${desc}`;
    }

    if (filter?.length) {
        for (let i = 0, l = filter.length; i < l; i++) {
            const key: string = dotToCamel(filter[i].id);

            if (Array.isArray(filter[i].value.value)) {
                params[key] = filter[i].value.value;
            } else {
                const fValue: string[] = filter[i].value.value.split(';');
                params[key] = fValue[0];
            }
        }
    }

    if (search?.length) {
        params['search'] = search;
    }

    if (extraFilter && !!extraFilter.length) {
        for (let i = 0, l = extraFilter.length; i < l; i++) {
            params[extraFilter[i].id] = convertQs(extraFilter[i].value);
        }
    }

    return params;
};

export const toCamelCaseAndRemove_ = (value: string): string =>
    value.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase()).replace(/(['_'])/g, ' ');

export const getValueOfAnObject = (object: Record<string, unknown>, keyString: string): string =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyString
        .split('.')
        .reduce((obj: any, key) => (typeof obj === 'undefined' || obj === null ? obj : obj[key]), object);

export function OrderAlphabetically<T>(list: T[], orderBy: string, asc = true): T[] {
    list.sort((a, b) => {
        if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
            return asc ? -1 : 1;
        }
        if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
            return asc ? 1 : -1;
        }
        return 0;
    });
    return list;
}

export const getTimezoneOffset = (timezoneName: string): number => {
    const now: moment.Moment = moment().tz(timezoneName);
    return now.utcOffset() / 60;
};

export const getSortedTimezone = (
    timezones: TimeZoneType[],
    dateTimeFormat: (displaySeconds: boolean) => string
): ITimeZoneFormatted[] => {
    const newTimezones = timezones.filter(timeZone => timeZone.timezoneName !== '');
    return newTimezones
        ?.map((timezone) => {
            const nowInTimezone = moment().tz(timezone.timezoneName);
            const formatString = dateTimeFormat(true);
            if (typeof nowInTimezone.format !== 'function') {
                return {
                    ...timezone,
                    timezoneFormatted: `${timezone?.timezoneName || 'Error'} (Time Formatting Error)`,
                    offset: getTimezoneOffset(timezone.timezoneName)
                };
            }
            return {
                ...timezone,
                // eslint-disable-next-line max-len
                timezoneFormatted: `${timezone?.timezoneName && timezone?.timezoneName} (${nowInTimezone.format(formatString)})`,
                offset: getTimezoneOffset(timezone.timezoneName)
            };
        })
        .sort((a, b) => {
            if (a.offset > b.offset) {
                return 1;
            }
            if (a.offset < b.offset) {
                return -1;
            }
            return 0;
        });
};
