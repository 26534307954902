import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ClickToCopy: React.FC<{ children: JSX.Element; text: string | number; enabled?: boolean }> = ({
    children,
    text,
    enabled = true
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [titleTooltip, setTitleTooltip] = useState<string>(translate('t.click_to_copy') as string);

    const handleCopy = (textToCopy): void => {
        navigator.clipboard
            .writeText(textToCopy.toString() ?? ' ')
            .then(() => {
                setTitleTooltip(`${translate('t.copied')}!`);
            })
            .catch((error) => {
                console.error('Error when copy:', error);
            });
    };

    useEffect(() => {
        setTimeout(() => {
            setTitleTooltip(translate('t.click_to_copy') as string);
        }, 2000);
    }, [titleTooltip]);

    if (!enabled) {
        return children;
    }

    return (
        <Tooltip title={titleTooltip} aria-label={titleTooltip}>
            <div onClick={() => handleCopy(text)} className='textClickToCopy'>
                {children}
            </div>
        </Tooltip>
    );
};

export default ClickToCopy;
