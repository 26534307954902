import { Box, IconButton, Switch } from '@mui/material';
import {
    ArrowForwardOutlined, Delete, KeyboardArrowDown, KeyboardArrowRight, ListAlt
} from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import * as type from './CreateOrder.type';
import * as view from './CreateOrder.view';
import ApiShippingOrders from '../../api/ApiShippingOrders';
import ApiItems from '../../api/ApiItems';
import BoxItems from '../BoxItems/BoxItems';
import { Success, Warning } from '../Popup/Popup';
import Wrapper from '../../helpers/wrapper';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { StockLocation } from '../../states/global/StockLocation';
import * as UserState from '../../states/global/User';
import { useHistory } from 'react-router-dom';
import { ColumnsTable, ModelSkuCodeBook } from '../../types/common';
import * as SkuModel from '../../models/ApiSku.type';
import { LabelSwitchFilter, TextEllipsis } from '../../styled/global.style';
import { ItemsAvailablesFilter } from './CreateOrder.type';
import { removeSpaces } from '../../helpers/converter';
import { Loading } from '../../helpers/Loading';
import { ItemsType } from '../Box/Box.type';
import { ModelCustomerItrack } from '../../models/ApiCustomer.type';
import ApiCustomer from '../../api/ApiCustomer';
import { ItemsModel, StockItemsType } from '../../models/ApiItems.type';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import module

const api = new ApiShippingOrders();
const apiItem = new ApiItems();
const apiCustomers = new ApiCustomer();

const CreateOrder: React.FC<type.CreateOrderProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [boxId, setBoxId] = useState<number>();
    const [modalItems, setModalItems] = useState<boolean>(false);
    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const [stockLocationFrom, setStockLocationFrom] = useState<any>(null);
    const userState = useRecoilValue<any>(UserState.User);
    const [itemsFrom, setItemsFrom] = useState<any[]>([]);
    const [itemsFromValue, setItemsFromValue] = useState<any[]>([]);
    const [itemsFromData, setItemsFromData] = useState<any[]>([]);
    const [itemsToSend, setItemsToSend] = useState<any[]>([]);
    const [usedLocations, setUsedLocations] = useState<any[]>([]);
    const [SKUs, setSKUs] = useState<ModelSkuCodeBook[]>([]);
    const [loadingItems, setLoadingItems] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [resetLocationTo, setResetLocationTo] = useState<boolean>(false);
    const [resetPageIndexTable, setResetPageIndexTable] = useState<boolean>(false);
    const stockLocationState = useRecoilValue<any>(StockLocation);
    const history = useHistory();
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [enablePartialSelection, setEnablePartialSelection] = useState<boolean>(false);
    const [customers, setCustomers] = useState<ModelCustomerItrack[]>([]);
    const [locationFilter, setLocationFilter] = useState<ItemsAvailablesFilter>({
        location: '',
        type: '',
        skuId: '',
        serialNumber: ''
    });
    const [valuesInitForm, setvaluesInitForm] = useState({
        locationFrom: '',
        locationTo: '',
        itrackCustomerId: '',
        shipper: '',
        type: '',
        saleOrderId: '',
        shipmentAcceptanceEmail: '',
        trackingEnabled: true,
        invoiceIncoerms: '',
        invoiceExw: ''
    });

    const Formschema = Yup.object().shape({
        locationFrom: Yup.string().required(translate('p.this_field_is_required') as string),
        locationTo: Yup.string().required(translate('p.this_field_is_required') as string),
        shipper: Yup.string(),
        itrackCustomerId: Yup.string(),
        type: Yup.string(),
        saleOrderId: Yup.string().trim().required(translate('p.this_field_is_required') as string)
            .matches(/^[a-zA-Z0-9_-]+$/, translate('p.only_letters_numb_hyphens') as string),
        invoiceIncoerms: Yup.string().trim().max(100),
        invoiceExw: Yup.string().trim().max(100),
        shipmentAcceptanceEmail: Yup.string()
            .test('emailsValidation', translate('p.please_enter_valid_email') as string, function (value) {
                if (!value) { return true; }

                const emailSchema = Yup.array().of(Yup.string().email());
                const emails = value.split(';').map((email) => email.trim());

                return emailSchema.isValidSync(emails);
            })
            .nullable(),
        trackingEnabled: Yup.boolean()
    });

    if (!stockLocationFrom && stockLocationState) setStockLocationFrom(stockLocationState);

    const getItems = async (id, enable = true) => {
        setEnablePartialSelection(enable);
        setBoxId(id);
        setModalItems(true);
    };

    const cancelOrder = (resetForm: any) => {
        setLoading(true);
        resetForm({});
        setItemsFrom([]);
        setItemsFromValue([]);
        setItemsToSend([]);
        setStockLocationFrom(null);

        cleanValuesInitForm();

        setTimeout(() => {
            setLoading(false);
        }, 400);
    };

    const cleanValuesInitForm = async (type = '') => {
        setvaluesInitForm({
            locationFrom: '',
            locationTo: '',
            itrackCustomerId: '',
            shipper: '',
            type: type,
            saleOrderId: '',
            shipmentAcceptanceEmail: '',
            trackingEnabled: true,
            invoiceExw: '',
            invoiceIncoerms: ''
        });
    };

    const parserItems = (items: StockItemsType[] | ItemsModel[], sonBox = false) => {
        return items.length
            ? items.map((Item) => {
                let nameItem: string = Item.sku.name;
                let parentId: number | null | undefined = Item.parentId;

                if( Item.sku.skuType.type === ItemsType.BOX ) {
                    nameItem = Item.sku.customerLabel;
                    
                    if(!parentId) {
                        parentId = Item.id;
                    }
                }
                
                return {
                    id: Item.id,
                    type: Item.sku.skuType.type,
                    name: nameItem,
                    itemId: Item.itemId,
                    parentId: parentId,
                    sku: Item.sku,
                    itrackDeviceId: Item.itrackDeviceId,
                    version: Item.version,
                    quantity: Item.quantity || Item.quantity === 0 ? Item.quantity : 1,
                    quantityTem: Item.quantity || Item.quantity === 0 ? Item.quantity : 1,
                    quantityOrigin: Item.quantity || Item.quantity === 0 ? Item.quantity : 1,
                    serialNumber: Item.serialNumber,
                    sonBox: sonBox,
                    boxItemsQuantity: Item.boxItemsQuantity as number || 0,
                    itemAttribute: Item.itemAttribute || []
                };
            })
            : [];
    };

    const getItemsAvailables = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoadingItems(true);
            let paramTypeFilter = extraFiltering.type;
            let paramTypeTablet = '';
            let filterBySerialNumber = '';
            let paramTypeSKU = '';

            cleanValuesInitForm(extraFiltering.type);

            if (extraFiltering.serialNumber) {
                filterBySerialNumber = `&serialNumber=${extraFiltering.serialNumber}`;
            }

            if (extraFiltering.location) {
                setItemsFrom([]);
                setItemsFromValue([]);
                if (extraFiltering.type && extraFiltering.type !== 'all') {
                    paramTypeFilter = '&type=' + extraFiltering.type;
                    paramTypeSKU = `/${extraFiltering.type}`;
                    if (extraFiltering.skuId) {
                        paramTypeTablet = '&skuId=' + extraFiltering.skuId;
                    } else {
                        setResetFilter(true);
                    }
                } else {
                    setResetFilter(true);
                }

                const { items, paginator } = await apiItem.getAll(
                    // eslint-disable-next-line max-len
                    `?available=1&inBox=0&locationId=${extraFiltering.location}${paramTypeFilter}${paramTypeTablet}${filterBySerialNumber}`,
                    page,
                    limit,
                    order,
                    filter,
                    '/v2'
                );

                if (paramTypeSKU) {
                    const {
                        data: { codeBook }
                    }: SkuModel.ApiModelSkuCodeBook = await api.getGeneral(
                        `stock/sku/type${paramTypeSKU}/assigned`,
                        undefined,
                        'v2'
                    );

                    if (codeBook) {
                        setSKUs(codeBook);
                    }
                }

                let newData: any[] = items.length
                    ? parserItems(items)
                    : [];

                if (!!itemsToSend.length && !!items.length) {
                    newData = newData.map((item) => {
                        itemsToSend.map((ItemTo) => {
                            if (item.id === ItemTo.id) {
                                item.quantity = item.quantityOrigin - ItemTo.quantity;
                                item.quantityTem = item.quantityOrigin - ItemTo.quantity;
                            }
                        });
                        return item;
                    });
                }

                const newDatas: any = newData.length
                    ? newData.filter((addI) => {
                        if (addI.type === ItemsType.GENERIC_ITEM) {
                            return addI.quantity > 0;
                        } else {
                            return true;
                        }
                    })
                    : [];
                const newDataValue = newData.length
                    ? newData.map((ItemFrom) => ({ id: ItemFrom.id, value: null }))
                    : [];
                setItemsFrom(items.length ? newDatas : []);
                setItemsFromValue(items.length ? newDataValue : []);
                setItemsFromData(items.length ? newDatas : []);
                setResetFilter(false);
                setPageCount(paginator.pageCount);
                setTotal(paginator.totalCount);
                setRefresh(false);
            } else {
                if (paramTypeFilter) {
                    Warning({
                        text: translate('p.to_query_by_type_you_must_first_select_the_stock_location_from')
                    });
                }
            }
            setLoadingItems(false);
        },
        [setItemsFrom]
    );

    const filterItemsFrom = (filter: any): any => {
        setLoadingItems(false);
        if (filter === '' || filter === null || filter === undefined) {
            setItemsFrom(itemsFromData);
            return;
        }
        if (itemsFromData.length) {
            valuesInitForm.type === ItemsType.TABLET &&
                setItemsFrom(itemsFromData.filter((item) => item.type.toLowerCase().includes(filter.toLowerCase())));
            valuesInitForm.type === 'sku' &&
                setItemsFrom(
                    itemsFromData.filter((item) =>
                        (item.name.toLowerCase() + ' ' + item.serialNumber.toLowerCase()).includes(filter.toLowerCase())
                    )
                );
            valuesInitForm.type === ItemsType.HUB &&
                setItemsFrom(itemsFromData.filter((item) => item.type.toLowerCase().includes(filter.toLowerCase())));
            valuesInitForm.type === ItemsType.SENSOR &&
                setItemsFrom(itemsFromData.filter((item) => item.type.toLowerCase().includes(filter.toLowerCase())));
            valuesInitForm.type === '' &&
                setItemsFrom(itemsFromData.filter((item) => item.type.toLowerCase().includes(filter.toLowerCase())));
        }
    };

    const formatItem = (row: any) => {
        switch (row.type) {
            case ItemsType.GENERIC_ITEM:
                return row.name;
            case ItemsType.BOX: {
                const itemAttributes: { value: string }[] = row.itemAttribute;
                let name = row.name;
                if (itemAttributes && itemAttributes.length) {
                    name += '-' + itemAttributes[0].value;
                }

                return `${name}: ${translate('t.items')}(${row.boxItemsQuantity})`;
            }
            case ItemsType.TABLET:
            case ItemsType.SENSOR:
                return row.name + ' | ' + row.type;
            case ItemsType.HUB:
                return (
                    row.name +
                    ' [ID:' +
                    (row.itrackDeviceId || row.itemId) +
                    '] ' +
                    (row.version ? translate('t.revision') + ' ' + row.version : translate('t.no_revision'))
                );
            default:
                return row.type;
        }
    };

    const setItemsAvailable = (row, isAdd) => {
        let listTemp = isAdd ? itemsToSend : itemsFrom;
        const listTempReverse = !isAdd ? itemsToSend : itemsFrom;
        let newList: any = [...listTemp];

        const quantityInput: HTMLElement | null = document.getElementById('cant_' + row.id);
        const quantityInputValue: number = parseInt(quantityInput?.['value']);
        const generitItem: boolean = isAdd && row.type === ItemsType.GENERIC_ITEM;
        const hubItem: boolean = isAdd && row.type === ItemsType.HUB;

        if (hubItem) {
            if (!row.version) {
                Warning({ text: translate('p.cant_submit_hub_without_revision') });
                quantityInput?.focus();
                throw quantityInput;
            }
        }

        if (generitItem) {
            if (!quantityInputValue || quantityInputValue <= 0) {
                Warning({ text: translate('p.quantity_must_be_greater_than_0') });
                quantityInput?.focus();
                throw quantityInput;
            }

            const updateListFrom: any = itemsFromValue.map((itemsFrom) => {
                if (itemsFrom.id === row.id) {
                    itemsFrom.value = null;
                }
                return itemsFrom;
            });

            if (quantityInputValue > parseInt(row.quantityTem)) {
                Warning({ text: translate('p.you_must_enter_an_amount_less_than') + ' ' + row.quantityTem });
                quantityInput?.focus();
                throw quantityInput;
            }

            setItemsFromValue(updateListFrom);
        }

        const exists = !!listTemp.filter((obj) => obj.id === row.id).length;
        // const existsBox = !!listTemp.filter((obj) => obj.parentId === row.id).length;
        const existsBoxItems: number[] = listTemp.filter((obj) => obj.parentId === row.id)?.map(o => o.id);

        if( isAdd && existsBoxItems.length ) {
            listTemp = listTemp.filter(item => !existsBoxItems.includes(item.id));
        }

        const nameItems: string = row.name ? row.name : row.type;
        if (listTemp.length) {
            const rowIsGenericItem: boolean =
                row.type === 'generic_item' && !!listTemp.filter((key) => key.id === row.id).length;
            if (rowIsGenericItem) {
                if (isAdd) {
                    listTemp = listTemp.map((key) => {
                        if (key.id === row.id) {
                            if (key.quantity === row.quantityOrigin) {
                                Warning({
                                    text: nameItems + ' ' + translate('p.are_already_aggregated_with_the_maximum')
                                });
                            } else {
                                key.quantity = parseInt(key.quantity) + quantityInputValue;
                            }
                        }
                        return key;
                    });
                    row.type === 'generic_item' ? (newList = [...listTemp]) : (newList = [row, ...listTemp]);
                } else {
                    if (row.type === 'generic_item') {
                        newList = newList.map((key) => {
                            if (key.id === row.id) {
                                key.quantity = key.quantityOrigin;
                                key.quantityTem = row.quantityOrigin;
                            }
                            return key;
                        });
                    } else {
                        newList = newList.unshift(row);
                    }
                }
            } else {
                if (row.type === 'generic_item') {
                    isAdd ? (row.quantity = quantityInputValue) : (row.quantityTem = row.quantity);
                }

                if (isAdd) {
                    newList = [row, ...listTemp];
                    if (exists) {
                        Warning({ text: nameItems + ' ' + translate('p.is_already_added') });
                        return false;
                    }
                } else {
                    newList = [row, ...listTemp];
                }
            }
        } else {
            if (isAdd && row.type === 'generic_item') row.quantity = quantityInputValue;

            newList = [row];
        }

        let newListItemsFilter: type.IItem[] = [];
        if (generitItem) {
            newListItemsFilter = listTempReverse.map((key) => {
                if (key.id === row.id && key.quantity > 0) {
                    key.quantityTem = key.quantityTem - quantityInputValue;
                }
                return key;
            });
            newListItemsFilter = newListItemsFilter.filter((obj) => obj.quantityTem !== 0);
        } else {
            newListItemsFilter = listTempReverse.filter((key) => key.id !== row.id);
        }

        if (!row.sonBox) {
            setItemsFrom(!isAdd ? newList : newListItemsFilter);
        }
        setItemsToSend(isAdd ? newList : newListItemsFilter);
    };

    const resetListItems = (stockLocationFromId: string, setFieldValue: any): void => {
        setFieldValue && setFieldValue('locationFrom', stockLocationFromId);
        setLocationFilter({ ...locationFilter, location: stockLocationFromId });

        if (stockLocationFromId) {
            getItemsAvailables(1, 10, {}, {}, {});
        }

        setItemsToSend([]);
    };

    const checkLocationDuplication = (
        locationFromId: string,
        locationToId: string,
        setFieldValue: (name: string, value: string) => void
    ): void => {
        if (locationFromId && locationFromId && locationFromId === locationToId) {
            Warning({
                text: translate('p.from_and_to_equal_values')
            });
            setResetLocationTo(true);
            setFieldValue('locationTo', '');
            setTimeout(() => {
                setResetLocationTo(false);
            }, 300);
        }
    };

    const checkDeliveryDuplication = async (
        locationFromId: string,
        locationToId: string,
        customerId: string
    ) => {
        if (locationFromId && locationToId && customerId) {
            const items: any = await api.getUsedLocations(
                locationFromId,
                locationToId,
                customerId
            );

            setUsedLocations(items.data.codeBook);
        }
    };

    const validateQuantitySku = (e: any, row: any) => {
        const quantityinput: any = document.getElementById('cant_' + row.id);

        if (parseInt(e.target.value) > parseInt(row.quantityTem)) {
            Warning({ text: translate('p.you_must_enter_an_amount_less_than') + ' ' + row.quantityTem });
            quantityinput.value = row.quantityTem;
            quantityinput.focus();
        }
    };

    const setValueFrom = (row: any, resetValue = false) => {
        const quantityinput: any = document.getElementById('cant_' + row.id);

        itemsFromValue.map((itemsFrom) => {
            if (itemsFrom.id === row.id) itemsFrom.value = resetValue ? null : quantityinput.value;
            return itemsFrom;
        });
    };

    const currentValueFrom = (row: any): number => {
        const valueItems: any =
            itemsFromValue && !!itemsFromValue.length ? itemsFromValue.find((item) => item.id === row.id) : 1;
        return valueItems ? valueItems.value : 1;
    };

    const elementSRowEdit = (row) => {
        if (row.type === 'generic_item') {
            return (
                <Box sx={{ display: 'grid' }}>
                    <input
                        type='number'
                        style={{ marginLeft: '3px', width: '40%' }}
                        max={row.quantityTem}
                        min={1}
                        title={translate('t.the_quantity_is_indicated_in_the_SKU_type_item') as string}
                        disabled={row.type === 'generic_item' ? false : true}
                        key={`quantity_${row.id}`}
                        id={`cant_${row.id}`}
                        defaultValue={currentValueFrom(row)}
                        onChange={(e) => {
                            validateQuantitySku(e, row);
                            setValueFrom(row);
                        }}
                    />
                    Max: {row.quantityTem}
                </Box>
            );
        } else {
            return <> ---- </>;
        }
    };

    const ElementSRowEditMemoComponent = (props) => elementSRowEdit(props.row);
    ElementSRowEditMemoComponent.propTypes = {
        row: PropTypes.object.isRequired
    };
    const ElementSRowEditMemo = React.memo(ElementSRowEditMemoComponent);
    ElementSRowEditMemo.displayName = 'ElementSRowEditMemo';

    const setStockLocationFromF = (valor): void => setStockLocationFrom(valor);

    const handleSubmit = async (e: any, resetForm: any, setSubmitting: any) => {
        const withQuantity: any[] = itemsToSend.map((item) => {
            return {
                item: item.id,
                quantity: item.type === ItemsType.GENERIC_ITEM ? item.quantity : 1
            };
        });

        if (!itemsToSend.length) return Warning({ text: translate('p.the_list_items_to_send_cant_be_empty') });
        if (e.locationFrom === e.locationTo)
            return Warning({ text: translate('p.location_from_must_not_equal_location_a') });

        e.shippingOrderItem = withQuantity;
        e.shippingOrderStatus = 1;
        e.trackingEnabled = e.trackingEnabled ? 1 : 0;
        e.itrackCustomerId = e.itrackCustomerId ? e.itrackCustomerId : null;

        delete e.type;
        delete e.quantityTem;
        delete e.quantityOrigin;
        delete e.shipper;

        e.saleOrderId = removeSpaces(e.saleOrderId);
        e.shipmentAcceptanceEmail = removeSpaces(e.shipmentAcceptanceEmail || '');

        try {
            const {
                data: { shippingOrder }
            }: any = await api.create(e);

            if (!shippingOrder) {
                throw shippingOrder;
            }
            resetForm({});

            Success({
                text: `${translate('t.shipping_order_created_successfully')} [ID ${shippingOrder.id}]`
            });

            setItemsFrom([]);
            setItemsToSend([]);
            setSubmitting(false);
            history.push('/app/current-order/outbound');
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
            setStockLocationFrom(null);
        }
    };

    const columnsAvailableItems: ColumnsTable[] = [
        {
            Header: () => null,
            disableFilters: true,
            accessor: 'expander',
            disableGroupBy: true,
            disableSortBy: true,
            disableExport: true,
            width: 10,
            canFilters: false,
            Cell: ({ row, isLoading }: CellProps): CellProps<JSX.Element> => {
                if (row.original.type !== ItemsType.BOX) {
                    return (<></>);
                }

                const toggleRowExpandedProps = row.getToggleRowExpandedProps();

                const onClick = async (event) => {
                    if (!isLoading) {
                        toggleRowExpandedProps.onClick(event);
                    }
                };

                return (
                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                paddingLeft: `${row.depth}rem`
                            }
                        })}
                        onClick={onClick}
                    >
                        {row.isExpanded ? (
                            <IconButton
                                size='small'
                                style={{ minWidth: 'auto', color: '#039be5' }}
                                title={translate('t.box_items') as string}
                                id={`btn_view_${row.original.id}`}
                                color='primary'
                                data-testid='CreateOrder-Button-ExpandedBox-ArrowDown'
                            >
                                <KeyboardArrowDown />
                            </IconButton>
                        ) : (
                            <IconButton
                                size='small'
                                style={{ minWidth: 'auto', color: '#039be5' }}
                                title={translate('t.box_items') as string}
                                id={`btn_view_${row.original.id}`}
                                color='primary'
                                data-testid='CreateOrder-Button-ExpandedBox-ArrowRight'
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        )}
                    </span>
                );
            }
        },
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 30
        },
        {
            Header: translate('t.item'),
            accessor: 'sku.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 80,
            minWidth: 80,
            Cell: ({ row: { original } }: CellProps) => (
                <TextEllipsis
                    title={formatItem(original)}
                    className={!original.version && original.type === ItemsType.HUB ? 'danger' : ''}
                >
                    {formatItem(original)}
                </TextEllipsis>
            )
        },
        {
            Header: translate('t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            width: 60,
            canFilters: true,
            Cell: ({ row }: CellProps) =>
                row.original.type !== ItemsType.BOX ?
                    row.original.serialNumber : row.original?.label ? row.original.label : ' '
        },
        {
            Header: translate('t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            width: 40,
            minWidth: 40,
            canFilters: true,
            Cell: ({ row }: CellProps) => <ElementSRowEditMemo row={row.original} />
        },
        {
            Header: translate('t.actions'),
            nameLabelFilter: translate('t.item') as string,
            accessor: 'action',
            width: 40,
            minWidth: 40,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => {
                return (
                    <>
                        <IconButton
                            size='small'
                            className={
                                row.original.type === ItemsType.HUB && !row.original.version ? 'default' : 'success'
                            }
                            style={{ minWidth: 'auto' }}
                            title={translate('t.add') as string}
                            id={`btn_edit_${row.original.id}`}
                            onClick={() => setItemsAvailable(row.original, true)}
                            color='primary'
                        >
                            <ArrowForwardOutlined />
                        </IconButton>

                        {row.original.type === ItemsType.BOX ? (
                            <IconButton
                                size='small'
                                style={
                                    row.original.type === ItemsType.BOX
                                        ? { minWidth: 'auto', color: '#039be5' }
                                        : { minWidth: 'auto' }
                                }
                                title={translate('t.box_items') as string}
                                id={`btn_view_item_box_${row.original.id}`}
                                onClick={() => getItems(row.original.id)}
                                color='primary'
                            >
                                <ListAlt />
                            </IconButton>
                        ) : (
                            ''
                        )}
                    </>
                );
            }
        },
        {
            width: 0,
            Header: '',
            isVisible: false,
            disableSortBy: true,
            disableGroupBy: true,
            accessor: 'archived',
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <div className='ContainerChecboxFilter'>
                        <LabelSwitchFilter>{translate('t.archived')}</LabelSwitchFilter>
                        <Switch
                            checked={column.filterValue?.value === '1'}
                            onChange={(e) => {
                                let value: { name: string; value: string; humanValue: string } | undefined = undefined;
                                if (e.target.checked) {
                                    value = {
                                        name: translate('t.archived'),
                                        value: '1',
                                        humanValue: translate('t.yes')
                                    };
                                }
                                setFilter(column.id, value);
                            }}
                            color='primary'
                            name='_checkboxInactive'
                            inputProps={{ 'aria-label': 'secondary checkbox', color: '#ff1616' }}
                        />
                    </div>
                );
            }
        }
    ];

    const columnsItemsToSend: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 30
        },
        {
            Header: translate('t.item'),
            nameLabelFilter: translate('t.item') as string,
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 60,
            Cell: ({ row: { original } }: CellProps) => (
                <TextEllipsis
                    title={formatItem(original)}
                    className={!original.version && original.type === ItemsType.HUB ? 'danger' : ''}
                >
                    {formatItem(original)}
                </TextEllipsis>
            )
        },
        {
            Header: translate('t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40,
            Cell: ({ row }: CellProps) => (row.original.type === ItemsType.BOX ? '---' : row.original?.serialNumber)
        },
        {
            Header: translate('t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40,
            minWidth: 40,
            Cell: ({ row }: CellProps) => (row.original.type === 'generic_item' ? row.original.quantity : '---')
        },
        {
            Header: translate('t.actions'),
            nameLabelFilter: translate('t.item') as string,
            accessor: 'action',
            width: 40,
            minWidth: 40,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <>
                    <IconButton
                        size='small'
                        style={{ minWidth: 'auto', color: '#ef4430' }}
                        title={translate('t.remove') as string}
                        id={`btn_edit_${row.original.id}`}
                        onClick={() => setItemsAvailable(row.original, false)}
                        color='secondary'
                    >
                        <Delete />
                    </IconButton>

                    {row.original.type === ItemsType.BOX ? (
                        <IconButton
                            size='small'
                            style={
                                row.original.type === ItemsType.BOX
                                    ? { minWidth: 'auto', color: '#039be5' }
                                    : { minWidth: 'auto' }
                            }
                            title={translate('t.box_items') as string}
                            id={`btn_view_item_box_${row.original.id}`}
                            onClick={() => getItems(row.original.id, false)}
                            color='primary'
                        >
                            <ListAlt />
                        </IconButton>
                    ) : (
                        ''
                    )}
                </>
            )
        }
    ];

    const getCustomers = async (locationToId): Promise<void> => {
        if (!locationToId) {
            setCustomers([]);
            return;
        }

        const customes: ModelCustomerItrack[] = await apiCustomers.getCustomersCodeBook({
            itrackStockPrimaryDeliveryLocationId: locationToId
        });
        setCustomers(customes);
    };

    const sendSelectedItems = async (items: StockItemsType[]): Promise<void> => {
        if (items.length) {
            const newItemsList = parserItems(items, true);
            newItemsList.map((item) => {
                setItemsToSend((prevState) => {
                    let exists = false;
                    let existsBoxChildrem = false;
                    const nameItem: string | number = item.serialNumber ? item.serialNumber : item.sku.customerLabel;

                    if (prevState.length) {
                        exists = !!prevState.filter((obj) => obj.id === item.id ).length;
                        existsBoxChildrem = !!prevState.filter((obj) => item.parentId === obj.id ).length;
                    }

                    if (existsBoxChildrem) {
                        Warning({ text: translate('p.existing_item_in_box', {
                            nameItem,
                            boxId: item.parentId
                        }) });
                        return prevState;
                    }

                    if (exists) {
                        Warning({ text: nameItem + ' ' + translate('p.is_already_added') });
                        return prevState;
                    }

                    return [
                        item,
                        ...prevState
                    ];
                });

            });
        }
        setModalItems(false);
    };

    useEffect(() => {
        if (!itemsFrom.length && stockLocationFrom) {
            resetListItems(stockLocationFrom?.id, null);
        }
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [userState, translate('p.this_field_is_required')]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <BoxItems
                        BoxId={boxId}
                        modalItems={modalItems}
                        setModalItems={setModalItems}
                        setBoxId={setBoxId}
                        typeModal='drawer'
                        sendSelectedItems={enablePartialSelection && sendSelectedItems}
                    />
                    <view.CreateOrderContent
                        data-testid='CreateOrder-testid'
                        openModal={() => false}
                        handleSubmit={handleSubmit}
                        closeModal={() => false}
                        setItemsAvailable={setItemsAvailable}
                        cancelOrder={cancelOrder}
                        open={true}
                        formschema={Formschema}
                        columns={[]}
                        valuesInitForm={valuesInitForm}
                        itemsFrom={itemsFrom}
                        itemsToSend={itemsToSend}
                        resetListItems={resetListItems}
                        filterItemsFrom={filterItemsFrom}
                        setStockLocationFrom={setStockLocationFromF}
                        isLoading={loadingItems}
                        columnsAvailableItems={columnsAvailableItems}
                        columnsItemsToSend={columnsItemsToSend}
                        SKUs={SKUs}
                        resetFilter={resetFilter}
                        resetPageIndexTable={resetPageIndexTable}
                        setResetPageIndexTable={setResetPageIndexTable}
                        fetchData={getItemsAvailables}
                        total={total}
                        pageCount={pageCount}
                        refresh={refresh}
                        serialNumber={serialNumber}
                        setSerialNumber={setSerialNumber}
                        extraFiltering={locationFilter}
                        setLocationFilter={setLocationFilter}
                        resetLocationTo={resetLocationTo}
                        checkLocationDuplication={checkLocationDuplication}
                        checkDeliveryDuplication={checkDeliveryDuplication}
                        usedLocations={usedLocations}
                        customers={customers}
                        getCustomers={getCustomers}
                        sendSelectedItems={sendSelectedItems}
                        getItems={getItems}
                    />
                </>
            )}
        </>
    );
};

export default Wrapper(CreateOrder);
