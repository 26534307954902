import PortalApi from '../helpers/api/portalApi';
import { TimeZoneResponseType, TimeZoneType } from '../models/ApiTimeZone.type';

export default class ApiTimeZone extends PortalApi<TimeZoneType> {
    route = 'timezone';

    getTimeZoneCodeBook = async (): Promise<TimeZoneType[]> => {
        return this.getCodeBook('/codebook', 'v1');
    };

    getTimeZoneCodeBookPublic = async (): Promise<TimeZoneResponseType> => {
        return this.getTemporal('?limit=100', 'v1/public');
    };
}