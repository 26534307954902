import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { CustomerAddressFormik as CustomerAddressFormikType, ISignUpForm } from './CustomerAddress.type';
import { AutocompleteElement, CheckboxElement, InputElement } from '../FormElements';
import { useTranslation } from 'react-i18next';

export const CustomerAddressFormik: React.FC<CustomerAddressFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const Formschema = Yup.object({
        address1: Yup.string()
            .trim()
            .min(5, 'Address is Too Short.')
            .required(translate('p.this_field_is_required') as string),
        customerId: Yup.string().required(),
        address2: Yup.string(),
        country: Yup.string().required(),
        postcode: Yup.string()
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='space-around' direction='row'>
                                <AutocompleteElement
                                    label={translate('t.webTrack_customer')}
                                    name='customerId'
                                    value={values.customerId}
                                    error={errors.customerId}
                                    touched={touched.customerId}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    options={props.customers ? props.customers : []}
                                    defaultValue={props.customers.find(
                                        (customer) => `${customer?.id}` === `${values.customerId}`
                                    )}
                                    required={true}
                                    optionsPropertyLabel='name'
                                    optionsPropertyValue='id'
                                />
                                <InputElement
                                    label={translate('t.address1')}
                                    name='address1'
                                    value={values.address1}
                                    error={errors.address1}
                                    touched={touched.address1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                />
                                <InputElement
                                    label={translate('t.address2')}
                                    name='address2'
                                    value={values.address2}
                                    error={errors.address2}
                                    touched={touched.address2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <InputElement
                                    label={translate('t.postcode')}
                                    name='postcode'
                                    value={values.postcode}
                                    error={errors.postcode}
                                    touched={touched.postcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <InputElement
                                    label={translate('t.province')}
                                    name='province'
                                    value={values.province}
                                    error={errors.province}
                                    touched={touched.province}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <InputElement
                                    label={translate('t.country')}
                                    name='country'
                                    value={values.country}
                                    error={errors.country}
                                    touched={touched.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                />
                                <CheckboxElement
                                    label={translate('t.primaryAddress')}
                                    name='primaryAddress'
                                    value={values.primaryAddress}
                                    onChange={handleChange}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                />
                                <CheckboxElement
                                    label={translate('t.disabled')}
                                    name='disabled'
                                    value={values.disabled}
                                    onChange={handleChange}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
