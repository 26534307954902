import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { SurveyMsgState } from '../../../states/component/Survey';
import { Alert } from '@mui/material';
// import module

const NotificationMessage: React.FC = (): JSX.Element => {
    const [surveyMsgState, setSurveyMsgState] = useRecoilState(SurveyMsgState);

    useEffect(() => {
        if(surveyMsgState.show) {
            setTimeout(() => {
                setSurveyMsgState({ message: '', show: false });
            }, 5000);
        }
    }, [surveyMsgState]);
    
    return <Alert 
        severity='success' 
        sx={{ padding: '0px 15px', marginRight: '10px', visibility: surveyMsgState.show ? 'inherit' : 'hidden' }} 
    >
        {surveyMsgState.message}
    </Alert>;
};

export default NotificationMessage;