import { atom } from 'recoil';
import { SiteSurveySteps, SurveyType } from '../../models/ApiSurvey.type';

export const Surveys = atom<SurveyType[]>({
    key: 'surveys-atom',
    default: []
});

export const SurveyState = atom<SurveyType | null>({
    key: 'survey-atom',
    default: null
});

export const SurveyShemaNameValidState = atom<boolean>({
    key: 'survey-schema-valid-atom',
    default: true
});

export const SurveyFieldRequiresState = atom<SiteSurveySteps>({
    key: 'survey-field-requires-atom',
    default: {
        stepOne: {
            validStep: true,
            fileds: {
                mineOwner: {
                    keyTrans: 't.mine_owner',
                    valid: true
                },
                location: {
                    keyTrans: 't.location',
                    valid: true
                },
                startDate: {
                    keyTrans: 't.start_date',
                    valid: true
                },
                siteName: {
                    keyTrans: 't.site_name',
                    valid: true
                },
                timeZone: {
                    keyTrans: 't.timezone',
                    valid: true
                },
                surveyDate: {
                    keyTrans: 't.survey_date',
                    valid: true
                },
                customerAttention: {
                    keyTrans: 't.customer_name',
                    valid: true
                },
                customerEmail: {
                    keyTrans: 't.customer_email',
                    valid: true
                },
                customerTelephone: {
                    keyTrans: 't.customer_telephone',
                    valid: true
                },
                customerAddress: {
                    keyTrans: 't.customer_address',
                    valid: true
                }
            }
        },
        stepTwo: {
            validStep: true,
            fileds: {
                cellularAvailable: {
                    keyTrans: 'p.cellularAvailable',
                    valid: true
                },
                cellularRestrictions: {
                    keyTrans: 'p.cellularRestrictions',
                    valid: true
                },
                wifiAvailable: {
                    keyTrans: 'p.wifiAvailable',
                    valid: true
                },
                wifiRouter: {
                    keyTrans: 'p.wifiRouter',
                    valid: true
                },
                wifiEthernet: {
                    keyTrans: 'p.wifiEthernet',
                    valid: true
                }
            }
        },
        stepThree: {
            validStep: true,
            fileds: {
                totalQuantity: {
                    keyTrans: 't.total_quantity',
                    valid: true
                },
                vehicles: {
                    keyTrans: 't.vehicles',
                    valid: true
                }
            }
        },
        stepFour: {
            validStep: true,
            fileds: {
                bridgestone: {
                    keyTrans: 'Bridgestone',
                    valid: true
                },
                michelin: {
                    keyTrans: 'Michelin',
                    valid: true
                },
                goodyear: {
                    keyTrans: 'Goodyear',
                    valid: true
                },
                otherBranch: {
                    keyTrans: 'Other',
                    valid: true
                },
                tyreAdditive: {
                    keyTrans: 'p.tyre_additive_used',
                    valid: true
                },
                fullName: {
                    keyTrans: 't.full_name',
                    valid: true
                },
                jobTitle: {
                    keyTrans: 't.job_title',
                    valid: true
                },
                company: {
                    keyTrans: 't.company',
                    valid: true
                },
                emailPersonal: {
                    keyTrans: 't.email',
                    valid: true
                }
            }
        },
        stepFive: {
            validStep: true,
            fileds: {
                shiftName: {
                    keyTrans: 't.shift_name',
                    valid: true
                },
                shiftStart: {
                    keyTrans: 't.shift_start',
                    valid: true
                },
                shiftEnd: {
                    keyTrans: 't.shift_end',
                    valid: true
                },
                language: {
                    keyTrans: 't.language',
                    valid: true
                },
                altitude: {
                    keyTrans: 't.altitude',
                    valid: true
                },
                pressureUnit: {
                    keyTrans: 't.pressure_unit',
                    valid: true
                },
                lowPressureLimitLevel1: {
                    keyTrans: 't.low_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '1',
                    valid: true
                },
                lowPressureLimitLevel2: {
                    keyTrans: 't.low_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '2',
                    valid: true
                },
                lowPressureLimitLevel3: {
                    keyTrans: 't.low_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '3',
                    valid: true
                },
                highPressureLimitLevel1: {
                    keyTrans: 't.high_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '1',
                    valid: true
                },
                highPressureLimitLevel2: {
                    keyTrans: 't.high_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '2',
                    valid: true
                },
                highPressureLimitLevel3: {
                    keyTrans: 't.high_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '3',
                    valid: true
                },
                temperatureUnit: {
                    keyTrans: 't.temperature_unit',
                    valid: true
                },
                temperatureUnitLevel1: {
                    keyTrans: 't.temperature_unit',
                    extraLabel: 't.level',
                    extraLabelText: '1',
                    valid: true
                },
                temperatureUnitLevel2: {
                    keyTrans: 't.temperature_unit',
                    extraLabel: 't.level',
                    extraLabelText: '2',
                    valid: true
                },
                temperatureUnitLevel3: {
                    keyTrans: 't.temperature_unit',
                    extraLabel: 't.level',
                    extraLabelText: '3',
                    valid: true
                },
                temperatureLimits: {
                    keyTrans: 't.temperature_limits',
                    valid: true
                },
                speedUnit: {
                    keyTrans: 't.speed_unit',
                    valid: true
                },
                maxSpeedLimit: {
                    keyTrans: 't.max_speed_limit',
                    valid: true
                }
            }
        } 
    }
});

export const SurveyFieldRequiresValidationState = atom<SiteSurveySteps>({
    key: 'survey-field-requires-validation-atom',
    default: {
        stepOne: {
            validStep: false,
            fileds: {
                mineOwner: {
                    keyTrans: 't.mine_owner',
                    valid: false
                },
                location: {
                    keyTrans: 't.location',
                    valid: false
                },
                startDate: {
                    keyTrans: 't.start_date',
                    valid: false
                },
                siteName: {
                    keyTrans: 't.site_name',
                    valid: false
                },
                timeZone: {
                    keyTrans: 't.timezone',
                    valid: false
                },
                surveyDate: {
                    keyTrans: 't.survey_date',
                    valid: false
                },
                customerAttention: {
                    keyTrans: 't.customer_name',
                    valid: false
                },
                customerEmail: {
                    keyTrans: 't.customer_email',
                    valid: false
                },
                customerTelephone: {
                    keyTrans: 't.customer_telephone',
                    valid: false
                },
                customerAddress: {
                    keyTrans: 't.customer_address',
                    valid: false
                }
            }
        },
        stepTwo: {
            validStep: false,
            fileds: {
                cellularAvailable: {
                    keyTrans: 'p.cellularAvailable',
                    valid: false
                },
                cellularRestrictions: {
                    keyTrans: 'p.cellularRestrictions',
                    valid: false
                },
                wifiAvailable: {
                    keyTrans: 'p.wifiAvailable',
                    valid: false
                },
                wifiRouter: {
                    keyTrans: 'p.wifiRouter',
                    valid: false
                },
                wifiEthernet: {
                    keyTrans: 'p.wifiEthernet',
                    valid: false
                }
            }
        },
        stepThree: {
            validStep: false,
            fileds: {
                totalQuantity: {
                    keyTrans: 't.total_quantity',
                    valid: false
                },
                vehicles: {
                    keyTrans: 't.vehicles',
                    valid: false
                }
            }
        },
        stepFour: {
            validStep: false,
            fileds: {
                bridgestone: {
                    keyTrans: 'Bridgestone',
                    valid: false
                },
                michelin: {
                    keyTrans: 'Michelin',
                    valid: false
                },
                goodyear: {
                    keyTrans: 'Goodyear',
                    valid: false
                },
                otherBranch: {
                    keyTrans: 'Other',
                    valid: false
                },
                tyreAdditive: {
                    keyTrans: 'p.tyre_additive_used',
                    valid: false
                },
                fullName: {
                    keyTrans: 't.full_name',
                    valid: false
                },
                jobTitle: {
                    keyTrans: 't.job_title',
                    valid: false
                },
                company: {
                    keyTrans: 't.company',
                    valid: false
                },
                emailPersonal: {
                    keyTrans: 't.email',
                    valid: false
                }
            }
        },
        stepFive: {
            validStep: false,
            fileds: {
                shiftName: {
                    keyTrans: 't.shift_name',
                    valid: false
                },
                shiftStart: {
                    keyTrans: 't.shift_start',
                    valid: false
                },
                shiftEnd: {
                    keyTrans: 't.shift_end',
                    valid: false
                },
                language: {
                    keyTrans: 't.language',
                    valid: false
                },
                altitude: {
                    keyTrans: 't.altitude',
                    valid: false
                },
                pressureUnit: {
                    keyTrans: 't.pressure_unit',
                    valid: false
                },
                lowPressureLimitLevel1: {
                    keyTrans: 't.low_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '1',
                    valid: true
                },
                lowPressureLimitLevel2: {
                    keyTrans: 't.low_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '2',
                    valid: true
                },
                lowPressureLimitLevel3: {
                    keyTrans: 't.low_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '3',
                    valid: true
                },
                highPressureLimitLevel1: {
                    keyTrans: 't.high_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '1',
                    valid: true
                },
                highPressureLimitLevel2: {
                    keyTrans: 't.high_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '2',
                    valid: true
                },
                highPressureLimitLevel3: {
                    keyTrans: 't.high_pressure_limit',
                    extraLabel: 't.level',
                    extraLabelText: '3',
                    valid: true
                },
                temperatureUnit: {
                    keyTrans: 't.temperature_unit',
                    valid: false
                },
                temperatureUnitLevel1: {
                    keyTrans: 't.temperature_unit',
                    extraLabel: 't.level',
                    extraLabelText: '1',
                    valid: false
                },
                temperatureUnitLevel2: {
                    keyTrans: 't.temperature_unit',
                    extraLabel: 't.level',
                    extraLabelText: '2',
                    valid: false
                },
                temperatureUnitLevel3: {
                    keyTrans: 't.temperature_unit',
                    extraLabel: 't.level',
                    extraLabelText: '3',
                    valid: false
                },
                temperatureLimits: {
                    keyTrans: 't.temperature_limits',
                    valid: false
                },
                speedUnit: {
                    keyTrans: 't.speed_unit',
                    valid: false
                },
                maxSpeedLimit: {
                    keyTrans: 't.max_speed_limit',
                    valid: false
                }
            }
        } 
    }
});

export const SurveyStepState = atom<number>({
    key: 'survey-step-atom',
    default: 1
});

export const SurveyMsgState = atom<{
    message: string,
    show: boolean
}>({
    key: 'survey-step-msg-atom',
    default: {
        message: '',
        show: false
    }
});
