import React from 'react';
import {
    FormHelperTextError,
    UiPhoneInputNumberContent as UiPhoneInputNumberContentStyle
} from './UiPhoneInputNumber.style';
import { UiPhoneInputNumberViewProps } from './UiPhoneInputNumber.type';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

export const UiPhoneInputNumberContent: React.FC<UiPhoneInputNumberViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    if (props?.value?.length) {
        if (!props.extraValidation(props.value) || !isValidPhoneNumber(props.value ?? '')) {
            props.setIsValid(translate('t.is_not_in_correct_format'));
        }
    }

    return (
        <UiPhoneInputNumberContentStyle data-testid='UiPhoneInputNumberContent' isValid={props.isValid}>
            <PhoneInput
                disabled={props.disabled}
                international={props.international}
                className={props.className}
                defaultCountry={props.defaultCountry}
                placeholder={props.placeholder}
                value={props.value}
                name={props.name}
                rules={{ required: true }}
                onChange={(value?: string | undefined) => {
                    if (value) {
                        if (!props.extraValidation(value) || !isValidPhoneNumber(value)) {
                            props.setIsValid(translate('t.is_not_in_correct_format'));
                        } else {
                            props.setIsValid(null);
                        }
                        props.setFieldValue(
                            props.name,
                            isValidPhoneNumber(value)
                                ? `${formatPhoneNumberIntl(value).replace(/(\s|-)/g, '')}`
                                : formatPhoneNumberIntl(value)
                        );
                    } else {
                        props.setFieldValue(props.name, '');
                        props.setIsValid(null);
                    }
                }}
            />
            {!props.isValid && props.required && <FormHelperTextError> {props.error} </FormHelperTextError>}
            {props.isValid && <FormHelperTextError> {props.isValid} </FormHelperTextError>}
        </UiPhoneInputNumberContentStyle>
    );
};
