import React, { useState } from 'react';
import * as style from './StockLocation.style';
import { StockLocationViewProps } from './StockLocation.type';
import { Button, CircularProgress, Paper } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { StockLocations } from '../../states/component/StockLocation';
import UiTable from '../Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
import { StockLocationFormik } from './StockLocation.formik';
import { CustomerAddressFormik } from './CustomerAddress.formik';
import { verifyPermission } from '../../helpers/Auth';
import { useTranslation } from 'react-i18next';
// import module

export const StockLocationContent: React.FC<StockLocationViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const canCreateAndEditCustomerAddress = !!verifyPermission(['create_and_edit_customer_address']);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <style.StockLocationContainer data-testid='StockLocationContent'>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='stockLocation'
                    data={useRecoilValue(StockLocations)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    onAdd={props.canCreateAndUpdata ? () => props.openModal : null}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter }) => {
                        props.fetchData(page + 1, limit, order, filter);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                    defaultSortBy={{
                        column: 'id',
                        desc: true
                    }}
                />
            </Paper>

            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                title={props.dataModal?.title}
                maxWidthModal='md'
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <StockLocationFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                            locationsType={props.locationsType}
                            regions={props.regions}
                            customers={props.customers}
                            canCreateAndUpdata={props.canCreateAndUpdata}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {props.canCreateAndUpdata ? translate('t.cancel') : translate('t.close')}
                        </Button>
                        {props.canCreateAndUpdata ? (
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                type='submit'
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                            </Button>
                        ) : ''}
                    </>
                }
            />

            <UiModal
                open={props.modalItems}
                closeModal={props.closeModalItems}
                title={`${translate('t.items_stock_location')} - ${props.stockLocation?.name}`}
                maxWidthModal='md'
                content={
                    <UiTable
                        title='stockLocationItems'
                        data={props.dataItemsStockLocation}
                        loading={props.loadingItems}
                        columns={props.columnsItemsStockLocation}
                        total={props.totalItems}
                        pageCount={props.pageCountItems}
                        fetchData={({ page, limit, order, filter }) => {
                            props.fetchDataItems(page + 1, limit, order, filter);
                        }}
                        fetchDataExport={props.fetchDataItemsExport}
                        defaultSortBy={{
                            column: 'id',
                            desc: false
                        }}
                        hiddenColumns={[]}
                        configPrefix='stockLocationItems'
                    />
                }
            />

            <UiModal
                open={props.modalCustomerAddress}
                closeModal={props.closeModelCustomerAddress}
                title={`${translate('t.customer_address')}`}
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <CustomerAddressFormik
                            valuesInitForm={props.valuesCustomerAddressInitForm}
                            handleSubmit={props.customerAddressHandleSubmit}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                            customers={[]}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModelCustomerAddress();
                            }}
                            color='secondary'
                        >
                            {canCreateAndEditCustomerAddress ? translate('t.cancel') : translate('t.close')}
                        </Button>

                        {canCreateAndEditCustomerAddress && (
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                type='submit'
                                disabled={isSubmitting}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                            </Button>
                        )}
                    </>
                }
            />
        </style.StockLocationContainer>
    );
};
