import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars-2';
import { Accordion, ListItemButton } from '@mui/material';
import { ThemeType } from '../../styled/theme.type';

export const SiteSurveyLoadingContainer = styled.div`
    min-height: calc(100vh - 144.5px) !important;
`;

export const SiteSurveyContainer = styled.div`
    padding: 0px 10px;
    padding-top: 10px;
    && .MuiStepLabel-label {
        margin-top: 10px !important;
        margin-bottom: 13px;
    }
`;

export const SiteSurveyContentStyle = styled.div`
    padding: 10px 10px;

    && .MuiStepLabel-label {
        margin-top: 10px !important;
        margin-bottom: 13px;
    }
`;

export const UiScrollbarsSiteSurvey = styled(Scrollbars)`
    height: auto !important;
    min-height: calc(100vh - 240px) !important;
    overflow-x: hidden !important;

    & > div:first-child {
        min-height: calc(100vh - 240px) !important;
        overflow-x: hidden !important;
    }

    border-bottom: thin solid #c0c0c0;
`;

export const UiScrollbarsSiteSurveyValidation = styled(Scrollbars)`
    height: auto !important;
    min-height: calc(100vh - 110px) !important;
    overflow-x: hidden !important;

    & > div:first-child {
        min-height: calc(100vh - 110px) !important;
        overflow-x: hidden !important;
    }
`;

export const ListItemCustom = styled(ListItemButton)<{ isActive: boolean }>`
    border-radius: 4px !important;
    cursor: default !important;
    margin-top: 10px;
    background-color: ${({ theme }: ThemeType) => {
        return theme.name == 'Dark' ? theme.colors.card.background : theme.colors.cardLight;
    }} !important;
`;

export const AccordionCustomer = styled(Accordion)`
    background-color: ${({ theme }: ThemeType) => {
        return theme.name == 'Dark' ? theme.colors.card.background : theme.colors.cardLight;
    }} !important;
`;
