import React from 'react';
import * as style from './CreateInvitation.style';
import { CreateInvitacionViewProps, CreateInvitationForm } from './CreateInvitation.type';
import { Alert, Autocomplete, Button, FormHelperText, Grid, TextField } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ChipsAutocompleteElement, InputElement } from '../../FormElements';
import { ButtonsActionContainer, GridCustom } from './CreateInvitation.style';
import { LoadingButton } from '@mui/lab';
import { Loading } from '../../../helpers/Loading';
import StockLocationDropdown from '../../StockLocationDropdown/StockLocationDropdown';
import { useTranslation } from 'react-i18next';
import { getTimezoneOffsetFormatted } from '../../../helpers/Converters';
// import module

export const CreateInvitationContent: React.FC<CreateInvitacionViewProps> = ({
    valuesInitForm,
    regions,
    // customers,
    timeZones,
    loadingData,
    loadingDataStockLocation,
    handleSubmit,
    updateRegion
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const Formschema = Yup.object({
        name: Yup.string().required(translate('p.this_field_is_required') as string),
        email: Yup.string()
            .required(translate('p.this_field_is_required') as string)
            .test('emailsValidation', translate('p.please_enter_valid_email') as string, function (value) {
                if (!value) {
                    return true;
                }

                const emailSchema = Yup.array().of(Yup.string().email());
                const emails = value.split(',').map((email) => email.trim());

                return emailSchema.isValidSync(emails);
            }),
        region: Yup.number().required(translate('p.this_field_is_required') as string),
        stockLocation: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        })
    });

    if (loadingData) return <Loading />;

    return (
        <style.CreateInvitacionContent data-testid='CreateInvitacionContent'>
            <Formik
                initialValues={valuesInitForm}
                onSubmit={(values: CreateInvitationForm, actions) => {
                    handleSubmit(values, actions.resetForm, actions.setSubmitting);
                }}
                validationSchema={Formschema}
            >
                {(propsF: FormikProps<CreateInvitationForm>) => {
                    const {
                        touched,
                        errors,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        values,
                        isSubmitting,
                        submitForm,
                        resetForm
                    } = propsF;
                    return (
                        <Form>
                            <Grid container direction='row' mt={1} >
                                <Alert severity='info'>
                                    Upon submitting the survey,
                                    the customer will receive a unique link to access and answer the survey.
                                </Alert>
                                <ChipsAutocompleteElement
                                    label={translate('t.email')}
                                    name='email'
                                    value={(values.email || '')
                                        .split(',')
                                        .filter((email) => email !== '')}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChange={(e, value: any) => {
                                        values.email = value.join(',');
                                        setFieldValue('email', value.join(','));
                                    }}
                                    onBlur={handleBlur}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    required={true}
                                />
                                <InputElement
                                    label={translate('t.customer_name')}
                                    name='name'
                                    value={values.name}
                                    error={errors.name}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    required={true}
                                />
                                <GridCustom item lg={6} md={6} sm={12}>
                                    <Autocomplete
                                        id='region'
                                        fullWidth
                                        options={regions ?? []}
                                        onChange={(e, value) => {
                                            setFieldValue('region', value ? value.id : '');
                                            updateRegion(value, setFieldValue);
                                        }}
                                        size='small'
                                        getOptionLabel={(option) =>
                                            option.translationString
                                                ? option.translationString
                                                    .replace('_', ' ')
                                                    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
                                                : ''
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                value={values.region}
                                                name='region'
                                                {...params}
                                                label={<span className='fieldRequired'>
                                                    <i>* </i> {translate('t.region')}
                                                </span>}
                                                variant='outlined'
                                                error={!!errors.region}
                                            />
                                        )}
                                    />
                                    {!!errors.region && (
                                        <FormHelperText error id='error-region'>
                                            {errors.region}
                                        </FormHelperText>
                                    )}
                                </GridCustom>
                                {/* <AutocompleteElement
                                    label={translate('t.customer')}
                                    name='customer'
                                    size='small'
                                    value={values.region}
                                    options={customers ?? []}
                                    onChange={(e, value) => {
                                        setFieldValue('customer', value ? value.id : '');
                                    }}
                                    optionsPropertyLabel='name'
                                    optionsPropertyValue='id'
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                /> */}
                                
                                {loadingDataStockLocation ? (
                                    <InputElement
                                        label={translate('t.loading')}
                                        name='type'
                                        value=''
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled
                                        fullWidth
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                    />
                                ) : (
                                    <StockLocationDropdown
                                        name='stockLocation'
                                        label={translate('t.stock_location')}
                                        value={values.stockLocation ? values.stockLocation[0] : values.stockLocation}
                                        error={!!errors.stockLocation}
                                        onChange={(value) => {
                                            setFieldValue('stockLocation', value ? value : '');
                                        }}
                                        required={true}
                                        textRequired={translate('p.this_field_is_required')}
                                        params={values.region ? {
                                            regionId: '' + values.region
                                        } : undefined}
                                        extendUrl='?notLocationType[]=2'
                                        notAssigned
                                    />
                                )}
                                <GridCustom item lg={12} md={12} sm={12}>
                                    <Autocomplete
                                        options={timeZones}
                                        getOptionLabel={(timeZone) => {
                                            const offset = getTimezoneOffsetFormatted(timeZone.timezoneName);
                                            return `${offset} ${timeZone.timezoneFormatted}`;
                                        }}
                                        onChange={(e, timeZone) => {
                                            setFieldValue('timezone', timeZone ? timeZone?.id : null);
                                        }}
                                        defaultValue={
                                            timeZones && !!timeZones.length
                                                ? timeZones.find((timeZone) => timeZone?.id === values.timezone)
                                                : null
                                        }
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate('t.timezone')}
                                                variant='outlined'
                                                name='timezone'
                                                value={values.timezone}
                                                data-testid='Survey-LocalLimezone-Autocomplete'
                                            />
                                        )}
                                    />
                                </GridCustom>
                            </Grid>
                            <ButtonsActionContainer>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='secondary'
                                    onClick={() => resetForm()}
                                >
                                    {translate('t.reset')}
                                </Button>
                                &nbsp; &nbsp;
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    type='button'
                                    loading={isSubmitting}
                                    onClick={() => {
                                        submitForm();
                                    }}
                                >
                                    {translate('t.send')}
                                </LoadingButton>
                            </ButtonsActionContainer>
                        </Form>
                    );
                }}
            </Formik>
        </style.CreateInvitacionContent>
    );
};