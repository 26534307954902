import { Button, Card, CardContent, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import * as style from './ResetPassword.style';
import * as type from './ResetPassword.type';
import logo from '../../assets/images/logo_stockcontrol.png';
import { useStyles } from '../../styled/global.style';
import { useTranslation } from 'react-i18next';

export const ResetPasswordContent: React.FC<type.ResetPasswordViewProps> = (props): any => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    return (
        <style.ResetPasswordContent data-testid='ResetPasswordContent'>
            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent='space-around' direction='row'>
                <div className='login-containers'>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className='logo-stock'>
                            <img src={logo} className='logo-login' alt='logo' />
                            <Divider variant={'inset'} className='divider-logo' />
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Card className='card-login'>
                            <CardContent>
                                <Typography
                                    align='center'
                                    style={{
                                        color: '#000',
                                        fontWeight: 700,
                                        marginLeft: '3%',
                                        marginBottom: '1%',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {translate('t.reset_password')}
                                </Typography>

                                <Formik
                                    initialValues={props.valuesInitForm}
                                    onSubmit={(values: type.Form, actions) => {
                                        props.handleSubmit(values, actions.setSubmitting);
                                    }}
                                    validationSchema={Yup.object().shape({
                                        newPassword: Yup.string()
                                            .required(translate('p.this_field_is_required') as string)
                                            .min(6, 'Password is Too Short.')
                                            .test(
                                                'passwords-match',
                                                translate(
                                                    'p.current_password_cannot_be_equal_to_new_password') as string,
                                                function (value) {
                                                    return this.parent.current_password !== value;
                                                }
                                            )
                                            .matches(
                                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                translate(
                                                    'p.mustcontain_8_characters' 
                                                ) as string
                                            ),
                                        newPasswordRepeat: Yup.string()
                                            .required(translate('p.this_field_is_required') as string)
                                            .oneOf(
                                                [Yup.ref('newPassword'), null],
                                                translate('t.passwords_must_match') as string
                                            )
                                    })}
                                >
                                    {(propsF: FormikProps<type.Form>) => {
                                        const { values, touched, errors, handleBlur, handleChange, isSubmitting } =
                                            propsF;
                                        return (
                                            <Form>
                                                <Grid container justifyContent='space-around' direction='row'>
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className={classes.textField}
                                                    >
                                                        <TextField
                                                            name='newPassword'
                                                            id='newPassword'
                                                            label={translate('t.new_password')}
                                                            value={values.newPassword}
                                                            type='password'
                                                            helperText={
                                                                errors.newPassword && touched.newPassword
                                                                    ? errors.newPassword
                                                                    : ''
                                                            }
                                                            error={
                                                                errors.newPassword && touched.newPassword ? true : false
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            variant='outlined'
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className={classes.textField}
                                                    >
                                                        <TextField
                                                            name='newPasswordRepeat'
                                                            id='newPasswordRepeat'
                                                            label={translate('t.new_password_repeat')}
                                                            value={values.newPasswordRepeat}
                                                            type='password'
                                                            helperText={
                                                                errors.newPasswordRepeat && touched.newPasswordRepeat
                                                                    ? errors.newPasswordRepeat
                                                                    : ''
                                                            }
                                                            error={
                                                                errors.newPasswordRepeat && touched.newPasswordRepeat
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            variant='outlined'
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className={classes.textField}
                                                    >
                                                        <Button
                                                            size='large'
                                                            variant='contained'
                                                            color='primary'
                                                            type='submit'
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? (
                                                                <CircularProgress size={25} />
                                                            ) : (
                                                                translate('t.send')
                                                            )}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </div>
            </Grid>
        </style.ResetPasswordContent>
    );
};
