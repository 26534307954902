/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import Wrapper from '../../helpers/wrapper';
import SiteSurveyContent from './SiteSurvey.view';
import { SiteSurveyProps } from './SiteSurvey.type';
import ApiSurvey from '../../api/ApiSurvey';
import { SiteSurveyBE, SurveyResponseType } from '../../models/ApiSurvey.type';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
    SurveyFieldRequiresState,
    SurveyFieldRequiresValidationState,
    SurveyMsgState,
    SurveyShemaNameValidState,
    SurveyState,
    SurveyStepState
} from '../../states/component/Survey';
import { Errors, Success } from '../Popup/Popup';
import { cleanObject } from '../../helpers';
import { checkStepValidation, createObjectFromBE, createObjectFromFE, generateValidateUpdate } from './SurveyHelpers';
import { useTranslation } from 'react-i18next';
// import module

const apiSurvey = new ApiSurvey();

declare global {
    interface HTMLButtonElement {
        step?: string; // Declara la propiedad personalizada
    }
}

const SiteSurvey: React.FC<SiteSurveyProps> = ({
    token,
    id,
    publicMode,
    bottonHiddenUpdateProps,
    closeModal
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [survey, setSurvey] = useRecoilState(SurveyState);
    const [surveyFieldRequires, setSurveyFieldRequires] = useRecoilState(SurveyFieldRequiresState);
    const [
        surveyFieldRequiresValidation,
        setsurveyFieldRequiresValidation
    ] = useRecoilState(SurveyFieldRequiresValidationState);
    const [surveyStep, setSurveyStep] = useRecoilState(SurveyStepState);
    const setSurveyMsgState = useSetRecoilState(SurveyMsgState);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [modalReturnToProgress, setModalReturnToProgress] = useState<boolean>(false);
    const [loadingReturnToProgress, setLoadingReturnToProgress] = useState<boolean>(false);
    const bottonHiddenUpdate = bottonHiddenUpdateProps ?? useRef<HTMLButtonElement>(null);
    const setSurveyShemaNameValidState = useSetRecoilState(SurveyShemaNameValidState);
    const resetSurveyShemaNameValidState = useResetRecoilState(SurveyShemaNameValidState);
    const resetSurveyFieldRequiresValidation = useResetRecoilState(SurveyFieldRequiresValidationState);
    const resetSurveyFieldRequiresState = useResetRecoilState(SurveyFieldRequiresState);
    const resetSurveyState = useResetRecoilState(SurveyState);

    const getSurvey = async () => {
        const param = token ?? id;
        const { customerOnboardingInvitation }: SurveyResponseType = await apiSurvey.getByTokenOrId(param ?? 0);

        if (token) {
            await apiSurvey.emailSeen(customerOnboardingInvitation.token);
        }

        setSurveyStep(customerOnboardingInvitation.customerOnboarding?.step
            ? (customerOnboardingInvitation.customerOnboarding?.step - 1)
            : 0
        );

        if (customerOnboardingInvitation.customerOnboarding?.data) {
            const dataV = customerOnboardingInvitation.customerOnboarding?.data;

            const dataParseV = createObjectFromFE(dataV);
            const dataParseC = cleanObject(dataParseV);

            const updateSurvey = {
                ...customerOnboardingInvitation,
                customerOnboarding: {
                    ...customerOnboardingInvitation.customerOnboarding,
                    data: {
                        ...dataParseC
                    }
                }
            } as any;

            const ValidationsUpdate = generateValidateUpdate(dataParseC, surveyFieldRequiresValidation);

            setSurvey(updateSurvey);
            setsurveyFieldRequiresValidation(ValidationsUpdate);
        } else {
            setSurvey(customerOnboardingInvitation);
        }

        setLoadingData(false);
    };

    const getDataUpdateSurvey = async(id: string | number) => {
        let dataUpdateSurvey: any = undefined;

        const { customerOnboardingInvitation }: SurveyResponseType = await apiSurvey.getByTokenOrId(id);

        if (customerOnboardingInvitation.customerOnboarding?.data) {
            const dataV = customerOnboardingInvitation.customerOnboarding?.data;

            const dataParseV = createObjectFromFE(dataV);
            const dataParseC = cleanObject(dataParseV);

            dataUpdateSurvey = {
                ...customerOnboardingInvitation,
                customerOnboarding: {
                    ...customerOnboardingInvitation.customerOnboarding,
                    data: {
                        ...dataParseC
                    }
                }
            } as any;
        }

        return dataUpdateSurvey;
    };

    const updateValidation = (dataUpdateClear, submitAction = false, showMSG?: boolean) => {
        const ValidationsUpdate = generateValidateUpdate(dataUpdateClear, surveyFieldRequiresValidation);
        const checkStepsValidation = checkStepValidation(ValidationsUpdate);

        if(!submitAction) {
            if (!surveyFieldRequiresValidation.stepOne.validStep
                || !surveyFieldRequiresValidation.stepTwo.validStep
                || !surveyFieldRequiresValidation.stepThree.validStep
                || !surveyFieldRequiresValidation.stepFour.validStep
                || !surveyFieldRequiresValidation.stepFive.validStep
            ) {
                setsurveyFieldRequiresValidation(ValidationsUpdate);
            }else{
                if(checkStepsValidation){
                    setsurveyFieldRequiresValidation(ValidationsUpdate);
                }
            }

            if (!surveyFieldRequires.stepOne.validStep
                || !surveyFieldRequires.stepTwo.validStep
                || !surveyFieldRequires.stepThree.validStep
                || !surveyFieldRequires.stepFour.validStep
                || !surveyFieldRequires.stepFive.validStep
            ) {
                setSurveyFieldRequires(ValidationsUpdate);
            }
        }else{
            setsurveyFieldRequiresValidation(ValidationsUpdate);
            setSurveyFieldRequires(ValidationsUpdate);
        }

        if(showMSG) {
            if(checkStepsValidation) {
                Errors({ text: translate('p.error_sending_survey') as string });
                throw 'Error while sending, Required fields are missing';
            }
        }
    };

    const handleSubmit = async (
        e,
        step: number,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting?: any,
        submitAction?: boolean
    ): Promise<void> => {
        setLoadingData(true);
        try {
            const idOrToken = token ?? id;
            let dataUpdateSurvey = await getDataUpdateSurvey(idOrToken ?? 0);
            dataUpdateSurvey = dataUpdateSurvey ?? survey;

            let dataSend = { ...dataUpdateSurvey?.customerOnboarding?.data, ...e };
            const dataUpdate = dataUpdateSurvey?.customerOnboarding?.data
                ? { ...dataUpdateSurvey?.customerOnboarding?.data, ...e }
                : e;

            dataSend = cleanObject(dataSend);
            const dataUpdateClear = cleanObject(dataUpdate);

            const updateSurvey = {
                customerOnboarding: {
                    ...dataUpdateSurvey?.customerOnboarding,
                    data: {
                        ...survey?.customerOnboarding?.data,
                        ...dataUpdate
                    }
                }
            } as any;

            if (step === 5 && submitAction || submitAction) {
                if(!publicMode) {
                    setSurveyShemaNameValidState(step === 5 && e.schemaName !== '');
                }
                
                setSurvey(prevSurvey => ({ ...prevSurvey, ...updateSurvey }));
                updateValidation(dataUpdateClear, true, true);
            }

            const dataForBEOfForm: SiteSurveyBE = createObjectFromBE(dataSend);

            const {
                data: { customerOnboarding }
            }: any = await apiSurvey.saveStep(idOrToken ?? 0, {
                step: step,
                data: JSON.stringify(dataForBEOfForm)
            });

            if (!customerOnboarding) {
                throw customerOnboarding;
            }

            if (step === 5 && submitAction || submitAction) {
                if (publicMode) {
                    await apiSurvey.accept(token ?? '').then(() => {
                        setSurveyMsgState({
                            message: translate('p.survey_submitted'),
                            show: true
                        });
                    });

                    let updateSurveySubmitted:any = await getDataUpdateSurvey(idOrToken ?? 0);
                    updateSurveySubmitted = updateSurveySubmitted ?? survey;

                    const dataParseV = createObjectFromFE(customerOnboarding.data);
                    const dataParseC = cleanObject(dataParseV);

                    const updateSurvey = {
                        acceptedAt: updateSurveySubmitted.acceptedAt,
                        status: updateSurveySubmitted.status,
                        lockedForEditUser: updateSurveySubmitted.lockedForEditUser,
                        customerOnboarding: {
                            ...dataUpdate?.customerOnboarding,
                            data: {
                                ...dataParseC
                            }
                        }
                    } as any;

                    setSurvey(prevSurvey => ({ ...prevSurvey, ...updateSurvey }));
                } else {
                    await apiSurvey.confirm(id ?? 0).then(() => {
                        Success({
                            text: `${translate('t.survey_accepted')}`
                        });
                        closeModal && closeModal();
                    });
                    if(survey) {
                        setSurvey({ ...survey, status: 'accepted' });
                    }
                }
            } else {
                const dataParseV = createObjectFromFE(customerOnboarding.data);
                const dataParseC = cleanObject(dataParseV);

                const updateSurvey = {
                    customerOnboarding: {
                        ...dataUpdate?.customerOnboarding,
                        data: {
                            ...dataParseC
                        }
                    }
                } as any;

                setSurvey(prevSurvey => ({ ...prevSurvey, ...updateSurvey }));

                updateValidation(dataUpdateClear);

                setSurveyMsgState({
                    message: `${translate('t.saved_changes')}`,
                    show: true
                });
            }

            setSubmitting && setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting && setSubmitting(false);
        }

        setTimeout(() => {
            setLoadingData(false);
        }, 500);
    };

    const handleReturnToPogress = async() => {
        setLoadingReturnToProgress(true);
        try {
            await apiSurvey.returnToProgress(id ?? 0).then(() => {
                Success({
                    text: `${translate('t.saved_changes')}`
                });

                if(survey) {
                    setSurvey({ ...survey, status: 'in_progress' });
                }

                setModalReturnToProgress(false);
            });
        } catch (error) {
            console.warn('Error: ', error);
        }
        setLoadingReturnToProgress(false);
    };

    const updateSurveyTemporal = async (values, step?: number) => {
        setLoadingData(true);
        const param = token ?? id;
        let stepSend = step;

        if (bottonHiddenUpdate?.current && bottonHiddenUpdate.current.step && !stepSend) {
            stepSend = +bottonHiddenUpdate.current.step + 1;
        }

        let updateSurvey:any = await getDataUpdateSurvey(param ?? 0);
        updateSurvey = updateSurvey ?? survey;

        let dataSend = { ...updateSurvey?.customerOnboarding?.data, ...values };
        dataSend = cleanObject(dataSend);

        updateValidation(dataSend);

        try {
            const idOrToken = token ?? id;
            const dataForBEOfForm: SiteSurveyBE = createObjectFromBE(dataSend);

            const {
                data: { customerOnboarding }
            }: any = await apiSurvey.saveStep(idOrToken ?? 0, {
                step: stepSend,
                data: JSON.stringify(dataForBEOfForm)
            });

            if (!customerOnboarding) {
                throw customerOnboarding;
            }

            const dataParseV = createObjectFromFE(customerOnboarding.data);
            const dataParseC = cleanObject(dataParseV);

            const updateSurvey = {
                customerOnboarding: {
                    ...survey?.customerOnboarding,
                    data: {
                        ...dataParseC
                    }
                }
            } as any;

            setSurvey(prevSurvey => ({ ...prevSurvey, ...updateSurvey }));

            setSurveyMsgState({
                message: translate('t.saved_changes'),
                show: true
            });
        } catch (error) {
            console.warn('Error to sending data ', error);
        }
        setLoadingData(false);
    };

    const handleNext = (values: any) => {
        if(!survey?.lockedForEditUser && publicMode || !publicMode) {
            updateSurveyTemporal(values, (surveyStep + 1) + 1);
        }
        setSurveyStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = (values: any) => {
        if(!survey?.lockedForEditUser && publicMode || !publicMode) {
            updateSurveyTemporal(values, (surveyStep + 1) - 1);
        }
        setSurveyStep((prevActiveStep) => prevActiveStep - 1);
    };

    const updateStep = (step: number) => {
        if(!survey?.lockedForEditUser && publicMode || !publicMode) {
            if (bottonHiddenUpdate?.current) {
                bottonHiddenUpdate.current.step = '' + step;
                bottonHiddenUpdate.current.dispatchEvent(new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                }));
            }
        }
        setSurveyStep(step);
    };

    useEffect(() => {
        getSurvey();
        return () => {
            resetSurveyShemaNameValidState();
            resetSurveyFieldRequiresValidation();
            resetSurveyFieldRequiresState();
            resetSurveyState();
        };
    }, []);

    return (
        <SiteSurveyContent
            loadingData={loadingData}
            publicMode={publicMode}
            bottonHiddenUpdate={bottonHiddenUpdate}
            modalReturnToProgress={modalReturnToProgress}
            loadingReturnToProgress={loadingReturnToProgress}
            handleSubmit={handleSubmit}
            handleNext={handleNext}
            handleBack={handleBack}
            updateStep={updateStep}
            updateSurveyTemporal={updateSurveyTemporal}
            setModalReturnToProgress={setModalReturnToProgress}
            handleReturnToPogress={handleReturnToPogress}
            data-testid='SiteSurvey-testid'
        />
    );
};

export default Wrapper(SiteSurvey);