import { Button, CircularProgress, Dialog, DialogContent, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import * as style from './ShippingOrderFiles.style';
import * as type from './ShippingOrderFiles.type';
import { Close } from '@mui/icons-material';
import UiTable from '../../../components/Ui/UiTable/UiTable';
import { Loading } from '../../../helpers/Loading';
import { getFullScreenModal } from '../../../helpers/getFullScreenModal';
import { GridFieldContainer } from '../../../styled/global.style';
import { DropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
// import module

export const ShippingOrderFilesContent: React.FC<type.ShippingOrderFilesViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);

    return (
        <style.ShippingOrderFilesContent data-testid='ShippingOrderFilesContent'>
            <Dialog
                fullScreen={getFullScreenModal()}
                fullWidth={true}
                maxWidth='xl'
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
                className='drawer_full_page'
            >
                <style.AppBarContainer style={{ position: 'relative', textAlign: 'center' }}>
                    <style.UiToolbar>
                        <style.TitlePage>
                            {translate('t.show_uploaded_files')} - #{props.shippingOrderId}
                        </style.TitlePage>

                        <style.ContainerBtnBoxItems>
                            <IconButton
                                edge='end'
                                color='inherit'
                                onClick={() => {
                                    props.closeModal();
                                }}
                                aria-label='close'
                            >
                                <Close />
                            </IconButton>
                        </style.ContainerBtnBoxItems>
                    </style.UiToolbar>
                </style.AppBarContainer>

                <style.UiScrollbarsDetail
                    autoHeight
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                        <div {...props} className='scrollYcustom' style={{ ...style }} />
                    )}
                >
                    <DialogContent>
                        <Grid container justify-content='flex-start' direction='row'>
                            {props.loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <Grid item lg={8} md={8} sm={12} xs={12}>
                                        <style.FieldsetContainer>
                                            <legend className='modalLegend'>
                                                {translate('t.uploaded_files')}
                                            </legend>
                                            <div className='bodyRightItems'>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <UiTable
                                                        data={props.shippingOrderFiles}
                                                        loading={false}
                                                        hidePagination={true}
                                                        hideForceRefresh={true}
                                                        noPagination={true}
                                                        columns={props.columns}
                                                        hiddenColumns={[]}
                                                        globalFilter={true}
                                                        customButtonAdd={
                                                            <b>
                                                                <span className='fo-3'>
                                                                    {translate('t.uploaded_files')}
                                                                </span>
                                                            </b>
                                                        }
                                                    />
                                                </Grid>
                                            </div>
                                        </style.FieldsetContainer>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <style.FieldsetContainer>
                                            <legend className='modalLegend'>
                                                {translate('t.upload_new_file')}
                                            </legend>
                                            <div className='bodyRightItems'>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <GridFieldContainer item lg={12} md={12} sm={12} xs={12}>
                                                        <DropzoneArea
                                                            dropzoneClass='textColor'
                                                            dropzoneText={translate('p.drag_and_drop_file') as string}
                                                            acceptedFiles={[
                                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                                'application/vnd.ms-excel',
                                                                'application/msword',
                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                                'application/application/msword',
                                                                'application/vnd.oasis.opendocument.text',
                                                                'application/vnd.oasis.opendocument.spreadsheet',
                                                                'application/vnd.oasis.opendocument.presentation',
                                                                'text/csv',
                                                                'application/vnd.ms-excel',
                                                                'image/png',
                                                                'image/jpg',
                                                                'image/jpeg',
                                                                'application/pdf',
                                                                'application/x-pdf',
                                                                'application/x-bzpdf',
                                                                'application-gzpdf'
                                                            ]}
                                                            onChange={(files) => {
                                                                setFiles(files);
                                                            }}
                                                            filesLimit={20}
                                                            clearOnUnmount={true}
                                                        />
                                                    </GridFieldContainer>
                                                    <GridFieldContainer item lg={12} md={12} sm={12} xs={12}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='small'
                                                            type='submit'
                                                            disabled={isSubmitting || !files.length}
                                                            onClick={(event) => {
                                                                props.handleSubmitUploadFiles(
                                                                    event,
                                                                    files,
                                                                    setIsSubmitting
                                                                );
                                                            }}
                                                        >
                                                            {isSubmitting ? (
                                                                <CircularProgress size={25} />
                                                            ) : (
                                                                translate('t.upload')
                                                            )}
                                                        </Button>
                                                    </GridFieldContainer>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                                            </div>
                                        </style.FieldsetContainer>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                </style.UiScrollbarsDetail>
            </Dialog>
            <style.ShippingOrderFilesbody />
        </style.ShippingOrderFilesContent>
    );
};
