/* eslint-disable max-len */
import { Button, CircularProgress, IconButton, InputAdornment, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import * as style from './CreateOrder.style';
import * as type from './CreateOrder.type';
import {
    AutocompleteElement,
    CheckboxElement,
    ChipsAutocompleteElement,
    InputElement,
    LoadingElement,
    OutlinedInputElement
} from '../FormElements';
import * as ShippingOrderState from '../../states/component/ShippingOrder';
import { IUAlert } from '../../styled/global.style';
import UiTable from '../Ui/UiTable/UiTable';
import { Close } from '@material-ui/icons';
import LocationDropDown from '../LocationDropDown/LocationDropDown';
import StockLocationDropdown from '../StockLocationDropdown/StockLocationDropdown';
import { LocationsItems } from '../../models/ApiItems.type';
import SubTable from '../Ui/UiTable/Components/SubTable/SubTable';
import { useTranslation } from 'react-i18next';
// import module

export const CreateOrderContent: React.FC<type.CreateOrderViewProps> = (props): JSX.Element => {
    const setResetFilters = useSetRecoilState<boolean>(ShippingOrderState.StatusFiltersTable);
    const { t: translate } = useTranslation();
    
    return (
        <style.CreateOrderContent data-testid='CreateOrderContent'>
            <Paper className='cardPageContainer' elevation={1}>
                <Formik
                    initialValues={props.valuesInitForm}
                    onSubmit={(values: type.ISignUpForm, actions) =>
                        props.handleSubmit(values, actions.resetForm, actions.setSubmitting)
                    }
                    validationSchema={props.formschema}
                >
                    {(propsF: FormikProps<type.ISignUpForm>) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            resetForm,
                            submitForm
                        } = propsF;

                        return (
                            <Form>
                                <style.UiScrollbarsOrder
                                    autoHeight
                                    autoHide
                                    renderThumbVertical={({ style, ...props }) => (
                                        <div {...props} className='scrollYcustom' style={{ ...style }} />
                                    )}
                                >

                                    <Grid container>
                                        <>
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 1 }}>
                                                <style.FieldsetLeftContainer>
                                                    <legend className='modalLegend'>
                                                        {translate('t.create_order')}
                                                    </legend>

                                                    <Grid container justifyContent='left' direction='row'>
                                                        <StockLocationDropdown
                                                            name='locationFrom'
                                                            label={translate('t.from')}
                                                            value={values.locationFrom[0]}
                                                            error={!!errors.locationFrom}
                                                            onChange={(value: LocationsItems) => {
                                                                props.resetListItems(
                                                                    value ? value.id : '',
                                                                    setFieldValue,
                                                                    value ? value.name : ''
                                                                );
                                                                props.setStockLocationFrom(
                                                                    value ? { id: value.id, name: value.name } : null
                                                                );
                                                                setResetFilters(true);
                                                                props.checkLocationDuplication(
                                                                    value ? value.id.toString() : '',
                                                                    values.locationTo,
                                                                    setFieldValue
                                                                );

                                                                props.checkDeliveryDuplication(
                                                                    value ? value.id.toString() : '',
                                                                    values.locationTo || '',
                                                                    values.itrackCustomerId || ''
                                                                );
                                                            }}
                                                            required={true}
                                                            textRequired={translate('p.this_field_is_required')}
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />

                                                        {props.resetLocationTo ? (
                                                            <LoadingElement
                                                                label={translate('t.loading')}
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={4}
                                                            />
                                                        ) : (
                                                            <LocationDropDown
                                                                name='locationTo'
                                                                label={translate('t.to')}
                                                                value={values.locationTo[0]}
                                                                error={!!errors.locationTo}
                                                                onChange={(value) => {
                                                                    value && setFieldValue('shipmentAcceptanceEmail', value.email);
                                                                    setTimeout(() => {
                                                                        setFieldValue('locationTo', value ? value.id : '');
                                                                    }, 300);
                                                                    setFieldValue('itrackCustomerId', '');

                                                                    props.checkLocationDuplication(
                                                                        value ? value.id.toString() : '',
                                                                        values.locationFrom,
                                                                        setFieldValue
                                                                    );

                                                                    props.getCustomers(
                                                                        value ? value.id.toString() : ''
                                                                    );

                                                                    props.checkDeliveryDuplication(
                                                                        values.locationFrom || '',
                                                                        value ? value.id.toString() : '',
                                                                        values.itrackCustomerId || ''
                                                                    );
                                                                }}
                                                                required={true}
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={4}
                                                                extendUrl='?notId=1&order=name:asc'
                                                            />
                                                        )}

                                                        {props.customers.length === 0 ? (
                                                            <InputElement
                                                                disabled={true}
                                                                label={translate('t.order_for_customer')}
                                                                name='itrackCustomerId'
                                                                value={''}
                                                                error={''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={4}
                                                            />
                                                        ) : (
                                                            <AutocompleteElement
                                                                disabled={props.customers.length === 0}
                                                                label={translate('t.order_for_customer')}
                                                                name='itrackCustomerId'
                                                                value={values.itrackCustomerId}
                                                                error={!!errors.itrackCustomerId}
                                                                options={props.customers ? props.customers : []}
                                                                onChange={(e, value: any) => {
                                                                    setFieldValue('itrackCustomerId', value ? value.id : '');

                                                                    props.checkDeliveryDuplication(
                                                                        values.locationFrom || '',
                                                                        values.locationTo || '',
                                                                        value && value.id ? value.id.toString() : ''
                                                                    );
                                                                }}
                                                                optionsPropertyLabel='name'
                                                                optionsPropertyValue='id'
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={4}
                                                            />
                                                        )}

                                                        <InputElement
                                                            label={translate('t.invoice_number')}
                                                            name='saleOrderId'
                                                            value={values.saleOrderId}
                                                            error={errors.saleOrderId}
                                                            touched={touched.saleOrderId}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                            required={true}
                                                        />

                                                        <ChipsAutocompleteElement
                                                            label={translate('t.shipment_acceptance_email')}
                                                            name='shipmentAcceptanceEmail'
                                                            value={(values.shipmentAcceptanceEmail || '')
                                                                .split(';')
                                                                .filter((email) => email !== '')}
                                                            error={errors.shipmentAcceptanceEmail}
                                                            touched={touched.shipmentAcceptanceEmail}
                                                            onChange={(e, value: any) => {
                                                                values.shipmentAcceptanceEmail = value.join(';');
                                                                setFieldValue('shipmentAcceptanceEmail', value.join(';'));
                                                            }}
                                                            onBlur={handleBlur}
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                            required={false}
                                                        />

                                                        <CheckboxElement
                                                            label={translate('t.tracking_enabled')}
                                                            name='trackingEnabled'
                                                            value={values.trackingEnabled}
                                                            onChange={handleChange}
                                                            disabled
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />
                                                        <InputElement
                                                            label={translate('t.invoice_incoterms')}
                                                            name='invoiceIncoterms'
                                                            value={values.invoiceIncoerms}
                                                            error={errors.invoiceIncoerms}
                                                            touched={touched.saleOrderId}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />
                                                        <InputElement
                                                            label={translate('t.invoice_exw')}
                                                            name='invoiceExw'
                                                            value={values.invoiceExw}
                                                            error={errors.invoiceExw}
                                                            touched={touched.saleOrderId}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />
                                                        <Grid item xl={3} lg={4} md={4} sm={4} xs={12} sx={{ p: 1.3 }}>
                                                            {Object.keys(errors).length > 0 ? (
                                                                <IUAlert variant='outlined' severity='warning'>
                                                                    {translate('p.all_fields_marked_are_required')}
                                                                </IUAlert>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </style.FieldsetLeftContainer>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <style.FieldsetContainer>
                                                    <legend className='modalLegend'>
                                                        {translate('t.choose_items')}
                                                    </legend>

                                                    <Grid container justifyContent='left' direction='row'>
                                                        <AutocompleteElement
                                                            label={translate('t.type')}
                                                            name='type'
                                                            value={values.type}
                                                            error={errors.type}
                                                            touched={touched.type}
                                                            onBlur={handleBlur}
                                                            setFieldValue={setFieldValue}
                                                            options={[
                                                                { value: 'all', label: translate('t.all') },
                                                                {
                                                                    value: 'generic_item',
                                                                    label: translate('t.generic_item')
                                                                },
                                                                { value: 'box', label: translate('t.box') },
                                                                { value: 'hub', label: translate('t.hub') },
                                                                {
                                                                    value: 'tablet',
                                                                    label: translate('t.tablet')
                                                                },
                                                                {
                                                                    value: 'sensor',
                                                                    label: translate('t.sensor')
                                                                }
                                                            ]}
                                                            defaultValue={{
                                                                value: values.type,
                                                                label: values.type
                                                            }}
                                                            onChange={(e, value: any) => {
                                                                setFieldValue('type', value ? value.value : '');
                                                                setFieldValue('sku', '');
                                                                props.setLocationFilter({
                                                                    ...props.extraFiltering,
                                                                    type: value ? value.value : '',
                                                                    serialNumber: ''
                                                                });
                                                                setResetFilters(true);
                                                                props.setSerialNumber('');
                                                            }}
                                                            xl={3}
                                                            lg={4}
                                                            md={4}
                                                            sm={12}
                                                        />

                                                        {props.resetFilter ? (
                                                            <LoadingElement
                                                                label={translate('t.loading')}
                                                                xl={3}
                                                                lg={4}
                                                                md={4}
                                                                sm={12}
                                                            />
                                                        ) : (
                                                            <>
                                                                {values.type === 'all' || values.type === '' ? (
                                                                    ''
                                                                ) : (
                                                                    <AutocompleteElement
                                                                        label={translate('t.sku')}
                                                                        name='sku'
                                                                        value={values.sku}
                                                                        error={errors.sku}
                                                                        touched={touched.sku}
                                                                        onBlur={handleBlur}
                                                                        setFieldValue={setFieldValue}
                                                                        options={
                                                                            props.SKUs && !!props.SKUs.length
                                                                                ? props.SKUs
                                                                                : []
                                                                        }
                                                                        defaultValue={null}
                                                                        onChange={(e, value: any) => {
                                                                            setFieldValue(
                                                                                'sku',
                                                                                value ? value.id : ''
                                                                            );
                                                                            props.setLocationFilter({
                                                                                ...props.extraFiltering,
                                                                                skuId: value ? value.id : ''
                                                                            });
                                                                        }}
                                                                        optionsPropertyLabel='name'
                                                                        optionsPropertyValue='id'
                                                                        xl={3}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={12}
                                                                    />
                                                                )}
                                                            </>
                                                        )}

                                                        <OutlinedInputElement
                                                            label={translate('t.serial_number')}
                                                            value={props.serialNumber}
                                                            onChange={(e) => {
                                                                props.setSerialNumber(e.target.value);
                                                                props.setResetPageIndexTable(
                                                                    !props.resetPageIndexTable
                                                                );
                                                                props.setLocationFilter({
                                                                    ...props.extraFiltering,
                                                                    serialNumber: e.target.value
                                                                });
                                                            }}
                                                            name='serialNumber'
                                                            endAdornment={
                                                                <InputAdornment
                                                                    title={translate('t.reset')}
                                                                    position='end'
                                                                >
                                                                    <IconButton
                                                                        aria-label={translate(
                                                                   't.serial_number'
                                                                        ) as string}
                                                                        onClick={() => {
                                                                            props.setSerialNumber('');
                                                                            props.setLocationFilter({
                                                                                ...props.extraFiltering,
                                                                                serialNumber: ''
                                                                            });
                                                                        }}
                                                                        edge='end'
                                                                    >
                                                                        {props.serialNumber ? <Close /> : ''}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            show={true}
                                                            lg={4}
                                                            md={4}
                                                            xl={3}
                                                        />
                                                    </Grid>
                                                    <style.ContainerRightItems>
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <UiTable
                                                                title='availableItemsCreateOrder'
                                                                data={props.itemsFrom}
                                                                loading={props.isLoading}
                                                                columns={props.columnsAvailableItems}
                                                                labelDisplayedRows={({ from, to, count }) => {
                                                                    if (
                                                                        props.itemsFrom?.length != to &&
                                                                        !props.isLoading
                                                                    ) {
                                                                        const count = props.itemsFrom?.length || 0;
                                                                        to = from + count - (count ? 1 : 0);
                                                                    }

                                                                    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`
                                                                        }`;
                                                                }}
                                                                customButtonAdd={
                                                                    <b>
                                                                        <span>
                                                                            {translate('t.available_items')}
                                                                        </span>
                                                                    </b>
                                                                }
                                                                total={props.total}
                                                                extraFiltering={props.extraFiltering}
                                                                pageCount={props.pageCount}
                                                                fetchData={({
                                                                    page,
                                                                    limit,
                                                                    order,
                                                                    filter,
                                                                    extraFiltering
                                                                }) => {
                                                                    props.fetchData(
                                                                        page + 1,
                                                                        limit,
                                                                        order,
                                                                        filter,
                                                                        extraFiltering
                                                                    );
                                                                }}
                                                                hiddenColumns={[]}
                                                                forceRefresh={props.refresh}
                                                                customSubTable={({ page }) => {
                                                                    return (
                                                                        <SubTable
                                                                            page={page}
                                                                            sendSelectedItems={props.sendSelectedItems}
                                                                            getItems={props.getItems}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <UiTable
                                                                classesTable='containerDataTable'
                                                                data={props.itemsToSend}
                                                                loading={false}
                                                                hidePagination={true}
                                                                hideFeSidePagination={true}
                                                                hideForceRefresh={true}
                                                                subtractSpace={380}
                                                                noPagination={true}
                                                                columns={props.columnsItemsToSend}
                                                                hiddenColumns={[]}
                                                                globalFilter={true}
                                                                customButtonAdd={
                                                                    <b>
                                                                        <span>{translate('t.item_to_send')} </span>
                                                                    </b>
                                                                }
                                                                resetPageIndex={props.resetPageIndexTable}
                                                            />
                                                        </Grid>

                                                    </style.ContainerRightItems>

                                                </style.FieldsetContainer>
                                            </Grid>
                                        </>
                                    </Grid>
                                </style.UiScrollbarsOrder>
                                <Grid container justifyContent='space-around' direction='row'>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        {props.usedLocations.length > 0 ? (<div>
                                            {translate('t.we_used_to_these_locations')}: &nbsp;
                                            <b>
                                                {props.usedLocations.map((location) => location.name).join(', ')}
                                            </b>
                                        </div>) : ''}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <div style={{ float: 'right', padding: '10px 0px' }}>
                                            <Button
                                                variant='contained'
                                                size='small'
                                                color='secondary'
                                                onClick={() => {
                                                    props.setSerialNumber('');
                                                    props.cancelOrder(resetForm);
                                                }}
                                            >
                                                {translate('t.reset')}
                                            </Button>
                                            &nbsp; &nbsp;
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                size='small'
                                                type='button'
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    submitForm();
                                                }}
                                            >
                                                {isSubmitting ? (
                                                    <CircularProgress size={25} />
                                                ) : (
                                                    translate('t.save')
                                                )}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
                <style.ShippingOrderbody />
            </Paper>
        </style.CreateOrderContent>
    );
};
