import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import { ThemeType } from '../../../styled/theme.type';

export const UiPhoneInputNumberContent = styled.div<{ isValid: string | null }>`
    .PhoneInputCustom input {
        border-radius: 5px;
        border: thin ${(props) => (props.isValid ? '#a80026 solid' : '#b3b3b3 solid')};
        background: transparent;
        padding: 9px;
        font-size: 15px;
        color: ${({ theme }: ThemeType) => theme.colors.text || '#000'};

        &:focus-visible {
            border: thin ${(props) => (props.isValid ? '#a80026 solid' : '#b3b3b3 solid')} !important;
        }
    }

    .PhoneInputCustom .PhoneInputCountry {
        margin-left: 12px;
    }

    .PhoneInputInput {
        max-width: 80%;
        margin: 0px 10px;
    }
`;

export const FormHelperTextError = styled(FormHelperText)`
    color: #f44336 !important;
    margin-left: 15px !important;
`;
