import React, { useEffect, useState } from 'react';
import { SubTableProps } from './SubTable.type';
import SubTableView from './SubTable.view';
import Wrapper from '../../../../../helpers/wrapper';
import { ColumnsTable } from '../../../../../types/common';
import { TextEllipsis } from '../../../../../styled/global.style';
import { CellProps } from 'react-table';
import { ItemsType } from '../../../../Box/Box.type';
import { IconButton } from '@mui/material';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { ModelItemsLocation, StockItemsType } from '../../../../../models/ApiItems.type';
import ApiItems from '../../../../../api/ApiItems';
import { useTranslation } from 'react-i18next';

const apiItems = new ApiItems();

const SubTable: React.FC<SubTableProps> = ({ page, sendSelectedItems, getItems }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [dataBoxItems, setdataBoxItems] = useState<StockItemsType[]>([]);
    const [loading, setLoading] = useState(true);
    const boxId: number = page?.original && Object.keys(page.original).length ? page?.original?.id : 0;

    const getItemsList = async (id): Promise<void> => {

        try {
            const data: ModelItemsLocation = await apiItems.getItemsById(id);

            if (!data.item) {
                throw data.item;
            }

            let items: StockItemsType[] = data.item.children;
            if (items.length) {
                items = items.filter((addI) => {
                    if (addI.sku.skuType.type === ItemsType.GENERIC_ITEM) {
                        return addI.quantity > 0;
                    } else if(addI.sku.skuType.type === ItemsType.BOX) {
                        return addI.parentId = addI.id;
                    } else {
                        return true;
                    }
                });
            }
            setdataBoxItems(items);
            setLoading(false);
        } catch (error) {
            console.error('Error to sending data ', error);
            setLoading(false);
        }
    };

    const formatItem = (row: StockItemsType): string => {
        switch (row.sku.skuType.type) {
            case ItemsType.GENERIC_ITEM:
                return row.sku.name;
            case ItemsType.BOX:
                return row.sku.skuCode;
            case ItemsType.TABLET:
            case ItemsType.SENSOR:
                return row.sku.name + ' | ' + row.sku.skuType.type;
            case ItemsType.HUB:
                return (
                    row.sku.name +
                    ' [ID:' +
                    (row.itrackDeviceId || row.itemId) +
                    '] ' +
                    (row.version ?
                        translate('t.revision') + ' ' + row.version :
                        translate('t.no_revision'))
                );
            default:
                return row.sku.skuType.type;
        }
    };

    const addItem = (row: StockItemsType): void => {
        setdataBoxItems((prevState) => {
            return prevState.filter(item => item.id !== row.id);
        });
        sendSelectedItems([row]); 
    };

    const openBoxItemModal = (boxId: number | string): void => {
        getItems(boxId);
    };

    const columnsSubTable: ColumnsTable[] = [
        {
            Header: translate('t.item'),
            nameLabelFilter: translate('t.item') as string,
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50,
            Cell: ({ row: { original } }: CellProps) => (
                <TextEllipsis
                    title={formatItem(original)}
                    className={!original.version && original.sku.skuType.type === ItemsType.HUB ? 'danger' : ''}
                >
                    {formatItem(original)}
                </TextEllipsis>
            )
        },
        {
            Header: translate('t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40,
            Cell: ({ row }: CellProps) => (row.original.sku.skuType.type === 'box' ? '---' : row.original?.serialNumber)
        },
        {
            Header: translate('t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40,
            minWidth: 40,
            Cell: ({ row }: CellProps) =>
                (row.original.sku.skuType.type === ItemsType.GENERIC_ITEM ? row.original.quantity : '---')
        },
        {
            Header: translate('t.actions'),
            nameLabelFilter: translate('t.item') as string,
            accessor: 'action',
            width: 25,
            minWidth: 25,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => {
                const notAdd = row.original.sku.skuType.type === ItemsType.HUB && !row.original.version;

                return (
                    <IconButton
                        size='small'
                        className={
                            notAdd ? 'default' : 'success'
                        }
                        style={{ minWidth: 'auto' }}
                        title={translate('t.add') as string}
                        id={`btn_edit_${row.original.id}`}
                        onClick={() => !notAdd && addItem(row.original)}
                        color='primary'
                    >
                        <ArrowForwardOutlined />
                    </IconButton>
                );
            }
        }
    ];

    useEffect(() => {
        if (boxId && !dataBoxItems.length) {
            getItemsList(boxId);
        }
    }, [page]);

    return (
        <SubTableView
            data-testid='SubTableView-Component'
            data={dataBoxItems}
            columnsSubTable={columnsSubTable}
            loading={loading}
            openBoxItemModal={openBoxItemModal}
            boxId={boxId}
        />
    );
};

export default Wrapper(SubTable);
