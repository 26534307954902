import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, SkuPriceFormik as SkuPriceFormikType } from './SkuPrices.type';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import { InputElement } from '../FormElements';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { FromUserTimezoneToTimezone, GetDateMask } from '../../helpers/Converters';
import { User } from '../../states/global/User';
import { FullDataUser } from '../User/User.type';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const SkuPriceFormik: React.FC<SkuPriceFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const fromUserTimezoneToTimezone = useRecoilValue(FromUserTimezoneToTimezone);
    const userState = useRecoilValue<FullDataUser | null>(User);
    const getDateMask = useRecoilValue(GetDateMask);

    const Formschema = Yup.object({
        shippingPriceUsd: Yup.number()
            .typeError(translate('t.price_must_be_number') as string)
            .required(translate('p.this_field_is_required') as string)
            .moreThan(0, translate('t.price_must_be_greater_than_0') as string),
        validFrom: Yup.string().trim()
            .required(translate('p.this_field_is_required') as string)

    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, handleChange, handleBlur, values, isSubmitting, submitForm, setFieldValue } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='inicial' direction='row'>
                                <InputElement
                                    label={translate('t.price_in_usd')}
                                    name='shippingPriceUsd'
                                    value={values.shippingPriceUsd}
                                    error={errors.shippingPriceUsd}
                                    touched={touched.shippingPriceUsd}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    sm={12}
                                />

                                <Grid item lg={6} md={6} sm={12} xs={12} className='textFieldFormik'>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDateTimePicker
                                            label={translate('t.valid_from')}
                                            disableFuture={false}
                                            value={values.validFrom}
                                            disablePast={!props.dataModal?.id}
                                            maxDate={values.validTo ? moment(values.validTo) : undefined}
                                            onChange={(newValue) => {
                                                setFieldValue(
                                                    'validFrom',
                                                    newValue ? fromUserTimezoneToTimezone(newValue) : ''
                                                );
                                            }}
                                            onAccept={(value) => {
                                                if (props.dataModal?.id && value) {
                                                    props.isValidDateRange(
                                                        fromUserTimezoneToTimezone(value), values.validTo
                                                    );
                                                }
                                            }}
                                            mask={getDateMask}
                                            inputFormat={userState ? userState?.userSetting.dateFormat : 'YYYY-MM-DD'}
                                            views={['year', 'month', 'day']}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name='validFrom'
                                                    size='small'
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    helperText={
                                                        params.error
                                                            ? errors.validFrom
                                                                ? errors.validFrom
                                                                : errors.validFrom
                                                            : ''
                                                    }
                                                    error={!!errors.validFrom}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {values.shippingPriceUsd && props.dataModal?.id ? (
                                    <Grid item lg={6} md={6} sm={12} xs={12} className='textFieldFormik'>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <MobileDateTimePicker
                                                label={translate('t.valid_to')}
                                                disableFuture={false}
                                                value={values.validTo}
                                                onChange={(newValue) => {

                                                    setFieldValue(
                                                        'validTo',
                                                        newValue ? fromUserTimezoneToTimezone(newValue) : ''
                                                    );
                                                }}
                                                onAccept={(value) => {
                                                    const dateFrom = moment(values.validFrom);
                                                    const dateTo = moment(value);
                                                    const isBefore: boolean = dateTo.isBefore(dateFrom);

                                                    if(isBefore) {
                                                        setFieldValue( 'validFrom', '');
                                                    }

                                                    if (props.dataModal?.id && value && !isBefore) {
                                                        props.isValidDateRange(
                                                            values.validFrom, value
                                                        );
                                                    }
                                                }}
                                                mask={getDateMask}
                                                inputFormat={userState ? 
                                                    userState?.userSetting.dateFormat : 'YYYY-MM-DD'
                                                }
                                                views={['year', 'month', 'day']}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name='validTo'
                                                        size='small'
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        helperText={
                                                            params.error
                                                                ? errors.validTo
                                                                    ? errors.validTo
                                                                    : errors.validTo
                                                                : ''
                                                        }
                                                        error={!!errors.validTo}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
