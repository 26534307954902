import { Checkbox, FormControl, IconButton, MenuItem, Select } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import * as style from './ShipmentAcceptance.style';
import * as type from './ShipmentAcceptance.type';
import * as view from './ShipmentAcceptance.view';
import ApiItems from '../../../api/ApiItems';
import ApiShipmentAcceptance from '../../../api/ApiShipmentAcceptance';
import Wrapper from '../../../helpers/wrapper';
import BoxItems from '../../BoxItems/BoxItems';
import { Success } from '../../Popup/Popup';
import ImagePopup from '../../ImagePopup/ImagePopup';
import { ColumnsTable } from '../../../types/common';
import { toCamelCaseAndRemove_ } from '../../../helpers/converter';
import { popUpConfirm } from '../../../helpers/PopUpConfirm';
import { getFullScreenModal } from '../../../helpers/getFullScreenModal';
import { ItemsType } from '../../Box/Box.type';
import { Boxitems } from './ShipmentAcceptance.type';
// import logo from '../../../assets/images/no-image.png';
import ApiSku from '../../../api/ApiSku';
import { useTranslation } from 'react-i18next';
// import module

const api = new ApiShipmentAcceptance();
const apiItems = new ApiItems();
const apiSku = new ApiSku();

const ShipmentAcceptance: React.FC<type.ShipmentAcceptanceProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const classes = style.useStyles();
    const [itemsList, setItemsList] = useState<Boxitems[]>([] as Boxitems[]);
    const [showListItems, setShowListItems] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [shippingOrder, setShippingOrder] = useState<any>({});
    const [boxItemId, setBoxItemId] = useState<number>(0);

    const handleSubmitForm = async () => {
        setLoadingButton(true);
        const items: any = {};
        items.items = itemsList.length
            ? itemsList.map((item) => {
                  if (item.comment) {
                      return { id: item.id, quantity: item.quantityTem, comment: item.comment };
                  }
                  return { id: item.id, quantity: item.quantityTem };
              })
            : [];

        try {
            const {
                data: { shippingOrder }
            }: any = await api.createGeneral(
                `stock/shipping-order/${props.shippingOrderId}/shipment-acceptance`,
                items
            );
            if (!shippingOrder) {
                throw shippingOrder;
            }
            Success({ text: translate('t.the_order_has_been_saved') + ` [ID ${shippingOrder.id}]` });
            props.closeModal();
            props.setRefresh(true);
            setLoadingButton(false);
        } catch (error) {
            setLoadingButton(false);
            console.warn('Error to sending data ', error);
        }
    };

    const updateDataItems = (type, value, row) => {
        let newList: Boxitems[] = itemsList;

        newList = newList.map((item) => {
            if (item.id === row.id) {
                item.disabled = type === ItemsType.GENERIC_ITEM ? row.quantity === value : !item.disabled;
                item.quantityTem = type === ItemsType.GENERIC_ITEM ? value : value ? 1 : 0;

                if (type === ItemsType.GENERIC_ITEM) {
                    if (row.quantity === value) item.comment = '';
                } else {
                    if (row.quantityTem === 1) item.comment = '';
                }
            }
            return item;
        });
        setItemsList(newList);
    };

    const forOption = (num): JSX.Element[] => {
        const elem: JSX.Element[] = [];
        for (let i = 0; i <= num; i++) {
            elem.push(<MenuItem value={i}>{i}</MenuItem>);
        }
        return elem;
    };

    const elementSRowEdit = (row): JSX.Element => {
        if (row.item.sku.skuType.type === ItemsType.GENERIC_ITEM) {
            return (
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        size='small'
                        value={row.quantityTem}
                        onChange={(e) => updateDataItems(row.item.sku.skuType.type, e.target.value, row)}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                        {forOption(row.quantity)}
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <Checkbox
                    id={`check_${row.id}`}
                    color='primary'
                    inputProps={{ 'aria-label': 'primary' }}
                    checked={row.quantityTem >= 1 ? true : false}
                    onChange={(e) => {
                        updateDataItems(row.item.sku.skuType.type, e.target.checked, row);
                    }}
                />
            );
        }
    };

    const ElementSRowEditMemo = React.memo((props: any) => elementSRowEdit(props.row));
    ElementSRowEditMemo.displayName = 'ElementSRowEditShimentOrderMemo';

    const sendEmail = async (onClose, { e, resetForm, setSubmiting }) => {
        if (!e.emailAcceptance) {
            return;
        }

        try {
            setSubmiting(true);

            const {
                data: { shippingOrder }
            }: any = await api.createGeneral(
                `stock/shipping-order/${props.shippingOrderId}/shipment-acceptance-send-email`,
                e
            );

            if (!shippingOrder) {
                throw shippingOrder;
            }

            resetForm({});
            setSubmiting(false);
            onClose && onClose();
            Success({ text: translate('t.invitation_sent_successfully') + ` [ID ${shippingOrder.id}]` });
        } catch (error) {
            console.warn('Error to send data ', error);
            setSubmiting(false);
        }
    };

    const confirmSendEmail = (e: any, resetForm: any, setSubmiting) =>
        popUpConfirm(
            translate('t.shipment_acceptance') + ` #${props.shippingOrderId}`,
            shippingOrder.emailSent
                ? translate('p.are_you_sure_to_send_email')
                : translate('p.are_you_sure_to_send_invitation'),
            sendEmail,
            { e, resetForm, setSubmiting }
        );

    const getOrder = useCallback(async () => {
        setLoadingForm(true);
        const {
            data: { shippingOrder }
        }: any = await api.getGeneral(`stock/shipping-order/${props.shippingOrderId}`);
        if (!shippingOrder) {
            throw shippingOrder;
        }

        let newData: any = [];
        newData = shippingOrder.shippingOrderItem
            ? shippingOrder.shippingOrderItem.map((item) => {
                  item.quantityTem = item.quantity;
                  item.comment = '';
                  item.disabled = true;
                  return item;
              })
            : newData;

        shippingOrder.items = newData;

        setShippingOrder(shippingOrder);
        setLoadingForm(false);
        !itemsList.length && setItemsList(newData);
    }, [props.shippingOrderId, itemsList.length]);

    const getItemsBox = async (id) => {
        const {
            data: { item }
        }: type.ApiItems = await apiItems.getGeneral(`stock/item/${id}`);

        if (!item) {
            throw item;
        }
        setBoxItemId(id);
        setShowListItems(!showListItems);
    };

    const setValueFrom = (row: Boxitems): Boxitems[] => {
        const quantityinput: any = document.getElementById('comment_' + row.id);

        itemsList.map((item) => {
            if (item.id === row.id) {
                item.comment = quantityinput.value;
            }
            return item;
        });
        quantityinput.focus();
        return itemsList;
    };

    const currentValueFrom = (row: Boxitems, disabled = false): string | boolean | undefined => {
        const valueItem: Boxitems | undefined =
            itemsList && !!itemsList.length ? itemsList.find((item) => item.id === row.id) : undefined;

        if (disabled && valueItem) {
            return valueItem?.disabled;
        }
        return valueItem ? valueItem && valueItem?.comment : '';
    };

    const elementEdit = (row) => {
        let defaultValue = '';

        if (typeof currentValueFrom(row) == 'string') {
            defaultValue = currentValueFrom(row)?.toString() ?? '';
        }

        return (
            <textarea
                style={{ marginLeft: '1px', width: '100%' }}
                title={translate('p.wrong_quantity_of_item') as string}
                key={`comment_${row.id}`}
                id={`comment_${row.id}`}
                defaultValue={defaultValue}
                onChange={() => {
                    setValueFrom(row);
                }}
            />
        );
    };

    const ElementEditMemo = React.memo((props: any) => elementEdit(props.row));
    ElementEditMemo.displayName = 'ElementEditMemo';

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: translate('t.image'),
            accessor: 'sku.image',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            exportType: 'image',
            Export: ({ row }: CellProps) =>
                row.original?.item?.sku?.itemImageS3Key ? apiSku.getImageUrl(row.original.item.sku) : '',
            Cell: ({ row: { original } }: CellProps) =>
                original?.item?.sku.skuType.type !== ItemsType.BOX ? (
                    <ImagePopup
                        imageUrl={apiSku.getImageUrl(original?.item?.sku)}
                        thumbnailUrl={apiSku.getImageUrl(original?.item?.sku)}
                        height='20px'
                    />
                ) : (
                    ''
                ),
            minWidth: 85,
            maxWidth: 90
        },
        {
            Header: translate('t.item'),
            accessor: 'items',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 360,
            minWidth: 360,
            Cell: ({ row: { original } }: CellProps) =>
                original.item.sku.skuType.type === ItemsType.BOX ? (
                    <style.TitleItemWithIcon>
                        {`${translate('t.box_invoice')}: ${shippingOrder?.saleOrderId} (${original.item.boxItemsQuantity})`}
                        <IconButton
                            size='small'
                            className='info'
                            style={{ minWidth: 'auto' }}
                            title={translate('t.items') as string}
                            id={`showItems${original.item.id}`}
                            onClick={() => getItemsBox(original.item.id)}
                        >
                            <Search />
                        </IconButton>
                    </style.TitleItemWithIcon>
                ) : original.item.sku.skuType.type === ItemsType.HUB ? (
                    <span className={!original.item.version ? 'danger' : ''}>
                        {original?.item
                            ? original?.item.sku.name +
                              ' [ID:' +
                              (original.item.itrackDeviceId || original.item.itemId) +
                              '] ' +
                              (original.item.version
                                  ? translate('t.revision') + ' ' + original.item.version
                                  : translate('t.no_revision'))
                            : '---'}
                    </span>
                ) : (
                    `${original?.item ? original?.item.sku.name : '---'} `
                )
        },
        {
            Header: translate('t.type'),
            accessor: 'item_type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => toCamelCaseAndRemove_(row.original.item.sku.skuType.type),
            Export: ({ row }: CellProps) => toCamelCaseAndRemove_(row.original.item.sku.skuType.type)
        },
        {
            Header: translate('t.serial_number'),
            accessor: 'item.serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) =>
                row.original.item.sku.skuType.type !== 'box'
                    ? row.original.item?.serialNumber
                    : row.original.item?.label
                    ? row.original.item.label
                    : ' '
        },
        {
            Header: translate('t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 70
        },
        {
            Header: translate('t.received'),
            accessor: 'received',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 70,
            Cell: ({ row }: CellProps) => <ElementSRowEditMemo row={row.original} />
        },
        {
            Header: translate('t.comment'),
            accessor: 'comment',
            disableGroupBy: true,
            width: 350,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => <ElementEditMemo row={row.original} />
        }
    ];

    useEffect(() => {
        !itemsList.length && getOrder();
    }, [getOrder, itemsList.length]);

    return (
        <>
            <view.ShipmentAcceptanceContent
                data-testid={'ShipmentAcceptance-testid'}
                {...props}
                fullScreenModal={getFullScreenModal()}
                columns={columns}
                handleSubmit={() => false}
                handleSubmitForm={handleSubmitForm}
                itemsLists={itemsList}
                shippingOrder={shippingOrder}
                sendEmail={sendEmail}
                confirmSendEmail={confirmSendEmail}
                loadingButton={loadingButton}
                loadingForm={loadingForm}
            />
            <BoxItems
                BoxId={boxItemId}
                modalItems={showListItems}
                setModalItems={setShowListItems}
                setBoxId={setBoxItemId}
                typeModal='drawer'
                showAction={true}
            />
        </>
    );
};

export default Wrapper(ShipmentAcceptance);
