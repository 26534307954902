import PortalApi from '../helpers/api/portalApi';
import { Model } from '../models/Account.type';

export default class Account extends PortalApi<Model> {
    route = 'stock/account';

    getAccountParsed = async () => {
        return this.getTemporal<{ user: Model }, { user: Model }>().then((res) => {
            const parsedResponse = parseDateTime(res);
            return parsedResponse.user;
        });
    };
}

export const parseDateTime = (res: { user: Model }) => {
    const { dateFormat, timeFormat, useMineTimezone, timezone } = res.user.userSetting;
    const timeFormatType = ['HH:mm', 'hh:mm'];
    const dateFormatType = ['yyyy-MM-dd', 'YYYY/MM/DD', 'MM/DD/YYYY', 'DD/MM/YYYY'];

    if (timeFormatType.includes(timeFormat) || dateFormatType.includes(dateFormat)) {
        return res;
    }

    const newUserData = {
        ...res,
        user: {
            ...res.user,
            userSetting: {
                ...res.user.userSetting,
                dateFormat: dateFormat.replace(/Y/, 'YYYY').replace(/m/, 'MM').replace(/d/, 'DD'),
                timeFormat: timeFormat.replace(/H/, 'HH').replace(/h/, 'hh').replace(/i/, 'mm'),
                timezone: useMineTimezone ? res.user.customer.timezone : timezone,
                customerTimezone: timezone
            }
        }
    };

    return newUserData;
};
