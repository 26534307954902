import React from 'react';
import Wrapper from '../../../helpers/wrapper';
import { StepTwoContent } from './StepTwo.view';
import { SiteSurveyStepTwoForm, StepTwoProps } from './StepTwo.type';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
import { OptionsSelectsType } from '../SiteSurvey.type';
import { useTranslation } from 'react-i18next';
// import module

const StepTwo: React.FC<StepTwoProps> = ({
    activeStep,
    publicMode,
    bottonHiddenUpdate,
    handleNext,
    handleBack,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyData  = useRecoilValue(SurveyState);
    
    const dataStepTwo: SiteSurveyStepTwoForm = {
        cellularAvailable: checkSurvey(surveyData, 'cellularAvailable') ?? '',
        cellularProvider: checkSurvey(surveyData, 'cellularProvider') ?? '',
        cellularRestrictions: checkSurvey(surveyData, 'cellularRestrictions') ?? '',
        wifiAvailable: checkSurvey(surveyData, 'wifiAvailable') ?? '',
        wifiInternet: checkSurvey(surveyData, 'wifiInternet') ?? '',
        wifiFirewall: checkSurvey(surveyData, 'wifiFirewall') ?? '',
        wifiRouter: checkSurvey(surveyData, 'wifiRouter') ?? '',
        wifiEthernet: checkSurvey(surveyData, 'wifiEthernet') ?? '',
        dispatchSystem: checkSurvey(surveyData, 'dispatchSystem') ?? '',
        dispatchLink: checkSurvey(surveyData, 'dispatchLink') ?? '',
        tmsSystem: checkSurvey(surveyData, 'tmsSystem'),
        networkComment: checkSurvey(surveyData, 'networkComment')
    };

    const optionsSelect: OptionsSelectsType[] = [
        { label: translate('t.yes'), value: 'yes' },
        { label: translate('t.no'), value: 'no' }
    ];

    return (
        <StepTwoContent 
            data-testid='StepTwo-testid'
            publicMode={publicMode}
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={dataStepTwo}
            optionsSelect={optionsSelect}
            bottonHiddenUpdate={bottonHiddenUpdate}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            updateSurveyTemporal={updateSurveyTemporal}
            setModalReturnToProgress={setModalReturnToProgress}
        />
    );
};

export default Wrapper(StepTwo);