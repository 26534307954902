import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import * as style from './ChangeLanguage.style';
import * as type from './ChangeLanguage.type';
import ApiUser from '../../api/ApiUser';
import logoCanada from '../../assets/images/canada.svg';
import logoEEUU from '../../assets/images/eeuu.svg';
import logoEspana from '../../assets/images/espana.svg';
import logoPortugal from '../../assets/images/portugal.svg';
import logoEUK from '../../assets/images/reino-unido.svg';
import logoUcrania from '../../assets/images/ucrania.svg';
import { Lang } from '../../states/global/Translator';
import { useTheme } from '../../styled/useTheme';
import { Button, Menu, MenuItem } from '@mui/material';
import { getFromLocalStorage, setToLocalStorage } from '../../helpers/localStorage';
import { useTranslation } from 'react-i18next';
import { User } from '../../states/global/User';
import { FullDataUser } from '../User/User.type';
import { ResponseUserSetting, UserSetting } from '../../models/ApiUser.type';
import { getCookie } from '../../helpers/cookie';
import { parseDateTime } from '../../api/Account';
// import module

const api = new ApiUser();

const ChangeLanguageContent: React.FC<unknown> = (): JSX.Element => {
    const [currentLang, setCurrentLang] = useState<type.ListLanguageType>({
        id: 'en_GB',
        logo: logoEUK,
        name: 'English UK'
    });
    const [langSelect, setLangSelect] = useRecoilState<string>(Lang);
    const [anchorLan, setAnchorLan] = useState<null | HTMLElement>(null);
    const { theme } = useTheme();
    const loggedIn = getCookie('authToken');
    const { i18n } = useTranslation();
    const [user, setUser] = useRecoilState(User);

    const listLan: type.ListLanguageType[] = [
        { key: 'en_GB', id: 'en_GB', logo: logoEUK, name: 'English UK' },
        { key: 'en_US', id: 'en_US', logo: logoEEUU, name: 'English US' },
        { key: 'fr_CA', id: 'fr_CA', logo: logoCanada, name: 'Français canadien' },
        { key: 'es_ES', id: 'es_ES', logo: logoEspana, name: 'Español' },
        { key: 'pt_PT', id: 'pt_PT', logo: logoPortugal, name: 'Portugal' },
        { key: 'uk_UA', id: 'uk_UA', logo: logoUcrania, name: 'Українська' }
    ];

    const langS: type.ListLanguageType | undefined = listLan.find((l) => l.id === langSelect);

    if (langS) {
        currentLang.id !== langS.id && setCurrentLang(langS);
    }

    const handleMenuClose = async (choice: string | null = null, idLanguage: string | null = null): Promise<void> => {
        setAnchorLan(null);
        if (choice && idLanguage) {
            setLangSelect(idLanguage);
            setToLocalStorage('language', idLanguage);
            i18n.changeLanguage(idLanguage);
            if(loggedIn) {
                const { data }: ResponseUserSetting =
                await api.patchGeneralFree('account/setting', { theme: theme.name, language: idLanguage });
                
                if(data && data?.userSetting && user) {
                    const newState: FullDataUser = { ...user, userSetting: data?.userSetting as UserSetting };
                    setUser(parseDateTime( { user: newState } ).user);
                }
            }
        }
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>, type: string) =>
        type === 'lan' && setAnchorLan(event.currentTarget);

    const handleLangSelectf = () => (!currentLang ? { id: 'en_US', logo: logoEEUU, name: 'English US' } : currentLang);

    const renderLan = (
        <Menu
            id='simple-menu'
            anchorEl={anchorLan}
            keepMounted
            open={Boolean(anchorLan)}
            onClose={() => handleMenuClose(null, null)}
        >
            {!!listLan.length &&
                listLan.map((list) => (
                    <MenuItem
                        key={list.id}
                        onClick={() => {
                            setLangSelect(list.id);
                            handleMenuClose('choice', list.id);
                        }}
                    >
                        <img src={list.logo} width='20px' alt={list.name} /> &nbsp;
                        {list.name}
                    </MenuItem>
                ))}
        </Menu>
    );

    useEffect(() => {
        if (getFromLocalStorage('language') && getFromLocalStorage('language') !== 'null') {
            setLangSelect(getFromLocalStorage('language') as string);
        }
    }, [setLangSelect]);

    useEffect(() => {
        if (getFromLocalStorage('language')) {
            setLangSelect(getFromLocalStorage('language') as string);
        }
    }, [getFromLocalStorage('language')]);

    return (
        <style.ChangeLanguageContent data-testid='ChangeLanguageContent'>
            <Button
                style={{ padding: '10px' }}
                aria-controls='simple-menu'
                color='inherit'
                startIcon={
                    <>
                        <img src={handleLangSelectf().logo} width='20px' alt={handleLangSelectf().name} /> &nbsp;
                        <style.ChangeLanguageTitle>{handleLangSelectf().name}</style.ChangeLanguageTitle>
                    </>
                }
                onClick={(e) => handleProfileMenuOpen(e, 'lan')}
            >
                <ExpandMoreIcon />
            </Button>
            {renderLan}
        </style.ChangeLanguageContent>
    );
};
export default ChangeLanguageContent;
