import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import * as style from './FeSidePagination.style';
import * as type from './FeSidePagination.type';
import { TablePagination as _MuiTablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import module

const rowsPerPageOptions = [5, 10, 25, 50, 100];

const interestingPropsEqual = (prevProps, nextProps) =>
    prevProps?.count === nextProps?.count &&
    prevProps?.rowsPerPage === nextProps?.rowsPerPage &&
    prevProps?.page === nextProps?.page &&
    prevProps?.onChangePage === nextProps?.onChangePage &&
    prevProps?.onChangeRowsPerPage === nextProps?.onChangeRowsPerPage;

type T = typeof _MuiTablePagination;
const MuiTablePagination: T = React.memo(_MuiTablePagination, interestingPropsEqual) as T;

const FeSidePaginationContent: React.FC<type.FeSidePaginationProps> = ({
    instance
}: PropsWithChildren<{ instance: any }>): ReactElement | null => {
    const {
        state: { pageIndex, pageSize, rowCount = instance.rows.length },
        gotoPage,
        nextPage,
        previousPage,
        setPageSize
    } = instance;

    const { t: translate } = useTranslation();

    const handleChangePage = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
            if (newPage === pageIndex + 1) {
                nextPage();
            } else if (newPage === pageIndex - 1) {
                previousPage();
            } else {
                gotoPage(newPage);
            }
        },
        [gotoPage, nextPage, pageIndex, previousPage]
    );

    const onChangeRowsPerPage = useCallback(
        (e) => {
            setPageSize(Number(e.target.value));
        },
        [setPageSize]
    );

    return rowCount && rowCount > 9 ? (
        <style.FeSidePaginationContent data-testid='FeSidePaginationContent'>
            <MuiTablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={rowCount}
                labelRowsPerPage={translate('t.files_per_page')}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={handleChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </style.FeSidePaginationContent>
    ) : null;
};

export default FeSidePaginationContent;
