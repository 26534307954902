import React from 'react';
import * as type from './SurveyValidation.type';
import { SiteSurveyLoadingContainer, UiScrollbarsSiteSurveyValidation } from '../SiteSurvey.style';
import { Alert, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ListItemValidationCustom, SurveyValidationContainer } from './SurveyValidation.style';
import { SurveyFieldRequiresValidationState, SurveyState, SurveyStepState } from '../../../states/component/Survey';
import { Loading } from '../../../helpers/Loading';
import { useTranslation } from 'react-i18next';
// import module

const stepsSurvey = {
    general_information: {
        keyTrans: 't.general_info',
        keyValidation: 'stepOne',
        keyStep: 0
    },
    network_information: {
        keyTrans: 't.network_information',
        keyValidation: 'stepTwo',
        keyStep: 1
    },
    fleet_information: {
        keyTrans: 't.fleet_information',
        keyValidation: 'stepThree',
        keyStep: 2
    },
    tyre_information: {
        keyTrans: 't.tyre_information',
        keyValidation: 'stepFour',
        keyStep: 3
    },
    site_configuration: {
        keyTrans: 't.site_configuration',
        keyValidation: 'stepFive',
        keyStep: 4
    }
};

const keysStepsSurvey = Object.keys(stepsSurvey);

const SurveyValidation: React.FC<type.SurveyValidationProps> = ({ bottonHiddenUpdate, publicMode }): JSX.Element => {
    const survey = useRecoilValue(SurveyState);
    const { t: translate } = useTranslation();
    const setSurveyStep = useSetRecoilState(SurveyStepState);
    const SurveyFieldRequiresValidation = useRecoilValue(SurveyFieldRequiresValidationState);

    if (!survey) return <SiteSurveyLoadingContainer><Loading /></SiteSurveyLoadingContainer>;

    const updateStep = (step: number) => {
        if(!survey?.lockedForEditUser && publicMode || !publicMode) {
            if (bottonHiddenUpdate?.current) {
                bottonHiddenUpdate.current.step = '' + step;
                bottonHiddenUpdate.current.dispatchEvent(new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                }));
            }
        }
        setSurveyStep(step);
    };

    function generateListItems(fields, step: number) {
        const listItems: any[] = [];
        for (const fieldName in fields) {
            // eslint-disable-next-line no-prototype-builtins
            if (fields.hasOwnProperty(fieldName)) {
                const field = fields[fieldName];
                const isValid = field.valid;

                if (!isValid) {
                    // const icon = isValid ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />;
                    const text = field.keyTrans.includes('.') ? translate(field.keyTrans) : field.keyTrans;
                    let extraLabel = field.extraLabel
                        ? field.extraLabel.includes('.') ? translate(field.extraLabel) : field.extraLabel
                        : '';

                    if (field.extraLabelText) {
                        extraLabel = `${extraLabel} ${field.extraLabelText}`;
                    }

                    listItems.push(
                        <ListItemButton
                            key={fieldName}
                            onClick={() => updateStep(step)}
                        >
                            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '5px' }}>
                                <CancelIcon color='error' />
                            </ListItemIcon>
                            <ListItemText secondary={extraLabel}>
                                {text}
                            </ListItemText>
                        </ListItemButton>
                    );
                }
            }
        }
        return listItems;
    }

    const showValidations = keysStepsSurvey.map(key => {
        const step = stepsSurvey[key];
        if (!SurveyFieldRequiresValidation[step.keyValidation].validStep) {
            return (<>
                <ListItemValidationCustom
                    key={step.keyStep}
                    isActive={true}
                >
                    <Typography
                        variant='h5'
                        gutterBottom
                    >
                        <strong>{translate(step.keyTrans)}</strong>
                    </Typography>
                </ListItemValidationCustom>
                <Grid container key={`${step.keyStep}-fileds`} >
                    <Grid item md={12}>
                        <List dense >
                            {generateListItems(SurveyFieldRequiresValidation[step.keyValidation].fileds, step.keyStep)}
                        </List>
                    </Grid>
                </Grid>
            </>);
        }
    });

    return (
        <UiScrollbarsSiteSurveyValidation
            renderThumbVertical={({ style, ...props }) => (
                <div {...props} className='scrollYcustom' style={{ ...style }} />
            )}
        >
            <Alert sx={{ margin: '5px', padding: '0px 5px' }} severity='warning'>
                {translate('t.required_fields')}
            </Alert>
            <SurveyValidationContainer>
                {showValidations}
            </SurveyValidationContainer>
        </UiScrollbarsSiteSurveyValidation>
    );
};

export default SurveyValidation;