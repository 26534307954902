import React from 'react';
import { SiteSurveyStepTwoForm, StepTwoViewProps } from './StepTwo.type';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { ListItemCustom, UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { AutocompleteElement, InputElement } from '../../FormElements';
import { SurveyFieldRequiresState } from '../../../states/component/Survey';
import { useTranslation } from 'react-i18next';
import ButtonsActionSiteSurvey from '../ButtonsActionSiteSurvey/ButtonsActionSiteSurvey';
// import module

export const StepTwoContent: React.FC<StepTwoViewProps> = ({
    activeStep,
    valuesInitForm,
    optionsSelect,
    publicMode,
    bottonHiddenUpdate,
    handleBack,
    handleNext,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyFieldRequires = useRecoilValue(SurveyFieldRequiresState);

    const validationSchema = Yup.object().shape({
        cellularAvailable: Yup.string(),
        cellularProvider: Yup.string(),
        cellularRestrictions: Yup.string(),
        wifiAvailable: Yup.string(),
        wifiInternet: Yup.string(),
        wifiFirewall: Yup.string(),
        wifiRouter: Yup.string(),
        wifiEthernet: Yup.string(),
        dispatchSystem: Yup.string(),
        dispatchLink: Yup.string(),
        tmsSystem: Yup.string(),
        networkComment: Yup.string()
    });

    const initialErrors = Object.keys(surveyFieldRequires.stepTwo.fileds).reduce((acc, key) => {
        if (!surveyFieldRequires.stepTwo.fileds[key].valid && !valuesInitForm[key]) {
            acc[key] = translate('p.this_field_is_required');
        }
        return acc;
    }, {});

    return (
        <Formik
            initialValues={valuesInitForm}
            initialErrors={initialErrors}
            onSubmit={(values: SiteSurveyStepTwoForm, actions) => {
                handleSubmit(values, 2, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
            validate={() => {
                return initialErrors;
            }}
            validateOnBlur={true}
        >
            {(propsF: FormikProps<SiteSurveyStepTwoForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm,
                    resetForm,
                    setSubmitting
                } = propsF;
                Object.keys(touched).forEach((key) => {
                    try {
                        validationSchema.validateSyncAt(key, values);
                        if (values[key]) {
                            delete errors[key];
                        }
                    } catch (validationError: any) {
                        errors[key] = validationError.errors[0];
                    }
                });
                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Grid container spacing={2}>
                                {/* WI-FI NETWORK */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{translate('t.wifi_network')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.wifiAvailable')}</strong>
                                        </Grid>
                                        <Grid item lg={4} md={4} >
                                            <AutocompleteElement
                                                label=''
                                                name='wifiAvailable'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiAvailable}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiAvailable
                                                )}
                                                error={errors.wifiAvailable}
                                                touched={touched.wifiAvailable}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.wifiInternet')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiInternet'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiInternet}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiInternet
                                                )}
                                                error={errors.wifiInternet}
                                                touched={touched.wifiInternet}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.wifiFirewall')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiFirewall'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiFirewall}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiFirewall
                                                )}
                                                error={errors.wifiFirewall}
                                                touched={touched.wifiFirewall}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.wifiRouter')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiRouter'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiRouter}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiRouter
                                                )}
                                                error={errors.wifiRouter}
                                                touched={touched.wifiRouter}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={true}
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.wifiEthernet')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='wifiEthernet'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.wifiEthernet}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.wifiEthernet
                                                )}
                                                error={errors.wifiEthernet}
                                                touched={touched.wifiEthernet}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                {/* CELLULAR NETWORK */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{translate('t.cellular_network')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.cellularAvailable')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='cellularAvailable'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.cellularAvailable}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.cellularAvailable
                                                )}
                                                error={errors.cellularAvailable}
                                                touched={touched.cellularAvailable}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.cellularProvider')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <InputElement
                                                label=''
                                                name='cellularProvider'
                                                value={values.cellularProvider}
                                                error={errors.cellularProvider}
                                                touched={touched.cellularProvider}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={false}
                                                fullWidth
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.cellularRestrictions')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='cellularRestrictions'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.cellularRestrictions}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.cellularRestrictions
                                                )}
                                                error={errors.cellularRestrictions}
                                                touched={touched.cellularRestrictions}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                {/* DISPATCH SYSTEM */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{translate('t.dispatch_system')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.dispatchSystem')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <InputElement
                                                label=''
                                                name='dispatchSystem'
                                                value={values.dispatchSystem}
                                                error={errors.dispatchSystem}
                                                touched={touched.dispatchSystem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={false}
                                                fullWidth
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.dispatchLink')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <AutocompleteElement
                                                label=''
                                                name='dispatchLink'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.dispatchLink}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.dispatchLink
                                                )}
                                                error={errors.dispatchLink}
                                                touched={touched.dispatchLink}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required={false}
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                {/* TYRE MANAGEMENT SYSTEM (TMS) */}
                                <Grid item md={6} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{translate('t.tyre_management_system')} (tms)</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container paddingLeft='10px'>
                                        <Grid item md={8} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.tmsSystem')}</strong>
                                        </Grid>
                                        <Grid item md={4}>
                                            <InputElement
                                                label=''
                                                name='tmsSystem'
                                                value={values.tmsSystem}
                                                error={errors.tmsSystem}
                                                touched={touched.tmsSystem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={false}
                                                fullWidth
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item md={8} ><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={12}><Divider /></Grid>

                                <InputElement
                                    label={translate('t.comment')}
                                    name='networkComment'
                                    value={values.networkComment}
                                    error={errors.networkComment}
                                    touched={touched.networkComment}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    rows={3}
                                    multiline
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                />
                            </Grid>
                        </UiScrollbarsSiteSurvey>
                        <ButtonsActionSiteSurvey
                            activeStep={activeStep}
                            values={values}
                            publicMode={publicMode}
                            isSubmitting={isSubmitting}
                            errors={Object.keys(errors).length > 0}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            submitForm={submitForm}
                            handleSubmit={handleSubmit}
                            resetForm={resetForm}
                            setSubmitting={setSubmitting}
                            setModalReturnToProgress={setModalReturnToProgress}
                        />
                        <Button
                            onClick={() => updateSurveyTemporal(values)}
                            sx={{ display: 'none' }}
                            variant='contained'
                            size='small'
                            ref={bottonHiddenUpdate}
                        >
                        </Button>
                    </>
                );
            }}
        </Formik>
    );
};