import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import StockLocationDropdown from '../StockLocationDropdown/StockLocationDropdown';
import SkusDropDown from '../SkusDropDown/SkusDropDown';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, TabletFormik as TabletFormikType } from './Tablet.type';
import { AutocompleteElement, InputElement } from '../FormElements';
import { useTranslation } from 'react-i18next';

export const TabletFormik: React.FC<TabletFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const Formschema = Yup.object({
        type:
            props?.dataModal && props.dataModal?.isAdd
                ? Yup.string().required(translate('p.this_field_is_required') as string)
                : Yup.string(),
        serialNumber: Yup.string()
            .trim()
            .min(3, 'Serial Number is Too Short.')
            .required(translate('p.this_field_is_required') as string),
        locationSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        }),
        skuSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        })
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='initial' direction='row'>
                                {props.dataModal && props.dataModal.isAdd ? (
                                    <AutocompleteElement
                                        autoFocus={true}
                                        label={translate('t.type')}
                                        name='type'
                                        value={values.type}
                                        error={errors.type}
                                        touched={touched.type}
                                        onBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        options={[
                                            { value: 'ICD', label: 'ICD' },
                                            { value: 'MOBITRACK', label: 'MOBITRACK' }
                                        ]}
                                        defaultValue={
                                            !props.dataModal?.isAdd
                                                ? {
                                                      value: values.type,
                                                      label: values.type
                                                  }
                                                : {
                                                      value: '',
                                                      label: ''
                                                  }
                                        }
                                        required={true}
                                    />
                                ) : null}

                                <InputElement
                                    label={translate('t.serial_number')}
                                    name='serialNumber'
                                    value={values.serialNumber}
                                    error={errors.serialNumber}
                                    touched={touched.serialNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={!props.dataModal?.isAdd}
                                    required={true}
                                />

                                <StockLocationDropdown
                                    name='locationSelect'
                                    label={translate('t.stock_location')}
                                    value={props.dataModal?.isAdd ? values.locationSelect[0] : values.locationSelect}
                                    error={!!errors.locationSelect}
                                    onChange={(value) => {
                                        setFieldValue('locationSelect', value ? value : '');
                                    }}
                                    required={true}
                                    textRequired={translate('p.this_field_is_required')}
                                />

                                <SkusDropDown
                                    name='skuSelect'
                                    url='/type/tablet/all'
                                    paramsUrl={[{ id: 'type', value: 'active' }]}
                                    label={translate('t.sku')}
                                    value={props.dataModal?.isAdd ? values.skuSelect[0] : values.skuSelect}
                                    error={!!errors.skuSelect}
                                    onChange={(value) => {
                                        setFieldValue('skuSelect', value ? value : '');
                                    }}
                                    required={true}
                                    textRequired={translate('p.this_field_is_required')}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
