import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as style from './ErrorPage404.style';
import * as type from './ErrorPage404.type';
import { useTranslation } from 'react-i18next';
// import module

export const ErrorPage404Content: React.FC<type.ErrorPage404Props> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const history = useHistory();

    return (
        <style.ErrorPage404Content data-testid='ErrorPage404Content'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div className='gif_error404'>
                    <style.ContainerImage404 />
                </div>

                {props.location?.pathname === '/error-page404' && (
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={() => history.push('/app/current-order/inbound')}
                    >
                        {translate('t.go_back')}
                    </Button>
                )}
            </div>
            <style.ErrorPage404body />
        </style.ErrorPage404Content>
    );
};
