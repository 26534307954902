import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { array, object, string } from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, ShipperFormik as ShipperFormikType } from './Shipper.type';
import { AutocompleteElement, InputElement, SelectAsyncPaginate } from '../FormElements';
import { useTranslation } from 'react-i18next';

export const ShipperFormik: React.FC<ShipperFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const Formschema = object({
        name: string().trim().min(3, 'Name is Too Short.').required(translate('p.this_field_is_required') as string),
        shipperType: string().required(translate('p.this_field_is_required') as string),
        trackingUrl: string()
            .trim()
            .required(translate('p.this_field_is_required') as string)
            .min(5, 'Tracking url is Too Short.'),
        shipperLocation: array().nullable()
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='space-around' direction='row'>
                                <InputElement
                                    autoFocus={true}
                                    label={translate('t.name')}
                                    name='name'
                                    value={values.name}
                                    error={errors.name}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                />

                                <AutocompleteElement
                                    label={translate('t.type')}
                                    name='shipperType'
                                    value={values.shipperType}
                                    error={errors.shipperType}
                                    touched={touched.shipperType}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    options={props.shipperType ? props.shipperType : []}
                                    required={true}
                                    defaultValue={
                                        !props.dataModal?.isAdd && props.shipper
                                            ? {
                                                  id: props.shipper?.shipperType?.id,
                                                  name: props.shipper?.shipperType?.name
                                              }
                                            : {
                                                  id: '',
                                                  name: ''
                                              }
                                    }
                                    optionsPropertyLabel='name'
                                    optionsPropertyValue='id'
                                />

                                <SelectAsyncPaginate
                                    name='shipperLocation'
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    label={translate('t.stock_location')}
                                    value={values.shipperLocation}
                                    error={!!errors.shipperLocation}
                                    options={props.stockLocations ? props.stockLocations : []}
                                    onChange={(value) => {
                                        setFieldValue('shipperLocation', value ? value : '');
                                    }}
                                    loadOptions={props.loadMoreStockLocation}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                />

                                <InputElement
                                    label={translate('t.tracking_url')}
                                    name='trackingUrl'
                                    value={values.trackingUrl}
                                    error={errors.trackingUrl}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    required={true}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
