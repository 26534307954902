import React from 'react';
import * as style from './EmailsList.style';
import * as type from './EmailsList.type';
import UiLegend from '../../Ui/UiLegend/UiLegend';
import { IconButtonContent, ListContainer } from './EmailsList.style';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from 'react-i18next';
// import module

const EmailsList: React.FC<type.EmailsListProps> = ({ id, emails }): JSX.Element => {
    const { t: translate } = useTranslation();

    const anchorOriginCustom = {
        vertical: 'top',
        horizontal: 'center'
    };
    const transformOriginCustom = {
        vertical: 'center',
        horizontal: 'center'
    };

    return (
        <style.EmailsListContent data-testid='EmailsListContent'>
            <UiLegend
                title={translate('t.emails')}
                content={
                    <ListContainer sx={{ pt: 0, pb: 0, mt: 0, mb: 0 }} >
                        {emails.map((email) => {
                            return (
                                <ListItem component='p' sx={{ pt: 0, pb: 0 }} key={`${id}-${email}`}>
                                    <ListItemText
                                        key={`${email}-${id}`}
                                        primary={email}
                                        sx={{ pt: 0, pb: 0 }}
                                    />
                                </ListItem>
                            );
                        })}
                    </ListContainer>
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <IconButtonContent>
                        <IconButton
                            aria-label='delete'
                            color='primary'
                            size='small'
                            data-testid={`emails-${id}`}
                            sx={{ fontSize: '12px', color: '#0091ff' }}
                        >
                            <>
                                <PeopleIcon fontSize='small' />
                                {emails.length}
                            </>
                        </IconButton>
                    </IconButtonContent>
                }
            />
        </style.EmailsListContent>
    );
};

export default EmailsList;