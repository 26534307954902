/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { FilterFieldsContainer, useStyles } from './StockLocation.style';
import * as view from './StockLocation.view';
import { DataModal, ISignUpForm, StockLocationI, StockLocationProps } from './StockLocation.type';
import Wrapper from '../../helpers/wrapper';
import { MenuItem, TextField, Typography } from '@mui/material';
import { BrandingWatermark, Delete, Edit, ListAlt } from '@mui/icons-material';
import { CellProps } from 'react-table';
import { useSetRecoilState } from 'recoil';
import ApiRegions from '../../api/ApiRegions';
import ApiStockLocation from '../../api/ApiStockLocation';
import ApiLocationType from '../../api/ApiLocationType';
import ApiCustomer from '../../api/ApiCustomer';
import { Success } from '../Popup/Popup';
import { StockLocations } from '../../states/component/StockLocation';
import { ColumnsTable, ModelCodeBook } from '../../types/common';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ModelCustomerItrack } from '../../models/ApiCustomer.type';
import { ModelGeneral, TypeRegions } from '../../models/ApiRegions.type';
import { CustomerAddressForm, IStockLocation, ModelLocationPOSTOrPATCH } from '../../models/ApiStockLocation.type';
import { LocationType } from '../../models/ApiLocationType.type';
import { OrderAlphabetically, removeSpaces } from '../../helpers/converter';
import { LinkAncla, TextEllipsis } from '../../styled/global.style';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import ApiSkuType from '../../api/ApiSkuType';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import Autocomplete from '@mui/material/Autocomplete';
import { SkuTypeModel } from '../../models/ApiSkuType.type';
import ApiCustomerAddress from '../../api/ApiCustomerAddress';
import { ICustomerAddress } from '../../models/ApiCustomerAddress.type';
import ApiSku from '../../api/ApiSku';
import { ModelSku } from '../../models/ApiSku.type';
import { verifyPermission } from '../../helpers/Auth';
import { useTranslation } from 'react-i18next';
// import module

const apiRegion = new ApiRegions();
const apiStockLocation = new ApiStockLocation();
const apiCustomers = new ApiCustomer();
const apiLocationType = new ApiLocationType();
const apiSku = new ApiSku();
const apiSkuType = new ApiSkuType();
const apiCustomerAddress = new ApiCustomerAddress();

const StockLocation: React.FC<StockLocationProps> = () => {
    const { t: translate } = useTranslation();
    const setdata = useSetRecoilState<IStockLocation[]>(StockLocations);
    const classes = useStyles();
    const [regions, setRegions] = useState<TypeRegions[]>([]);
    const [customers, setCustomers] = useState<ModelCustomerItrack[]>([]);
    const [stockLocationId, setStockLocationId] = useState<StockLocationI>({});
    const [stockLocation, setStockLocation] = useState<StockLocationI>({});
    const [dataItemsStockLocation, setdataItemsStockLocation] = useState<ModelSku[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingForm, setLoadingForm] = useState<boolean>(true);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const [modalItems, setModalItems] = useState<boolean>(false);
    const [modalCustomerAddress, setModalCustomerAddress] = useState<boolean>(false);
    const [locationsType, setLocationsType] = useState<ModelCodeBook[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [pageCountItems, setPageCountItems] = useState<number>(0);
    const [skuType, setSkuType] = useState<SkuTypeModel[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    const canCreateAndUpdata = !!verifyPermission(['create_and_edit_stock_location']);
    const canCreateAndEditCustomerAddress = !!verifyPermission(['create_and_edit_customer_address']);

    const [valuesInitForm, setvaluesInitForm] = useState<ISignUpForm>({
        name: '',
        email: '',
        address: '',
        locationType: '',
        region: '',
        customerId: '',
        //show: true,
        reshipmentEnabled: false
    });
    const [dataModal, setdataModal] = useState<DataModal>({
        isAdd: true,
        title: translate('t.add_new_shipping_location'),
        id: null
    });

    const openModal = async (type_form = 'create', id: number | null = null): Promise<void> => {
        setdataModal({
            isAdd: type_form !== 'edit' ? true : false,
            title:
                type_form !== 'edit'
                    ? translate('t.add_new_shipping_location')
                    : translate('t.shipping_location_update') + ' - #' + id,
            id: type_form !== 'edit' ? null : id
        });
        setTimeout(() => setOpen(true), 100);
        !regions.length && (await getRegions());
        !customers.length && (await getCustomers());
        type_form !== 'edit' && setLoadingForm(false);
        await getLocationType();
    };

    const handleClose = (): void => {
        setvaluesInitForm({
            name: '',
            email: '',
            address: '',
            locationType: '',
            region: '',
            customerId: '',
            //show: true,
            reshipmentEnabled: false
        });
        setdataModal({ isAdd: true, title: translate('t.add_new_shipping_location'), id: null });
        setOpen(false);
    };

    const getLocationType = async (): Promise<void> => {
        const locationTypes: LocationType[] = await apiLocationType.getLocationTypeCodeBook();
        setLocationsType(OrderAlphabetically<LocationType>(locationTypes, 'name'));
    };

    const getRegions = async (): Promise<void> => {
        const {
            data: { items }
        }: ModelGeneral = await apiRegion.getGeneral('region?limit=200');
        setRegions(OrderAlphabetically<TypeRegions>(items, 'translationString'));
    };

    const getCustomers = async (): Promise<void> => {
        const customes: ModelCustomerItrack[] = await apiCustomers.getCustomersCodeBook();
        setCustomers(customes);
    };

    const getSkus = async () => {
        const skuType: SkuTypeModel[] = await apiSkuType.getSkuTypeCodeBook();
        setSkuType(OrderAlphabetically<SkuTypeModel>(skuType, 'type'));
    };

    const getAllStockLocation = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setdata([]);
            const data = await apiStockLocation.getAll(page, limit, order, filter);
            setdata(data.items);
            setPageCount(data.paginator.pageCount);
            setTotal(data.paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter) => {
        let pages = 1;
        let items: IStockLocation[] = [];

        for (let i = 1; i <= pages; i++) {
            const data = await apiStockLocation.getAll(i, 200, order, filter);
            pages = data.paginator.pageCount;
            items = items.concat(data.items);
        }

        return items;
    };

    const getAllItems = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setdataItemsStockLocation([]);
            setLoadingItems(true);
            setStockLocationId(stockLocationId);
            const data = await apiSku.getByLocation(`?locationId=${stockLocationId}`, page, limit, order, filter);
            setdataItemsStockLocation(data.items);
            setPageCountItems(data.paginator.pageCount);
            setTotalItems(data.paginator.totalCount);
            setLoadingItems(false);
        },
        [setdataItemsStockLocation, stockLocationId]
    );

    const getItemsForExport = async (order, filter) => {
        let pages = 1;
        let items: ModelSku[] = [];

        for (let i = 1; i <= pages; i++) {
            const data = await apiSku.getByLocation(`?locationId=${stockLocationId}`, i, 200, order, filter);
            pages = data.paginator.pageCount;
            items = items.concat(data.items);
        }

        return items;
    };

    const getItemsSTockLocations = async (location) => {
        setModalItems(true);
        setStockLocationId(location.id);
        setStockLocation(location);
    };

    const handleSubmit = async (e: ISignUpForm, resetForm: (values: object) => void, setSubmitting): Promise<void> => {
        try {
            e.name = removeSpaces(e.name);
            e.email = removeSpaces(e.email);
            e.address = removeSpaces(e.address);
            e.customerId = e.customerId === 0 || e.customerId === '' ? null : e.customerId;


            const {
                data: { location }
            }: ModelLocationPOSTOrPATCH = dataModal.isAdd
                ? await apiStockLocation.create(e)
                : await apiStockLocation.patch(dataModal.id ?? 0, e);

            if (!location) {
                throw location;
            }
            setRefresh(true);
            resetForm({});

            Success({
                text: dataModal.isAdd
                    ? `${translate('t.shipping_location_created_successfully')} - ${location.name}`
                    : `${translate('t.shipping_location_successfully_updated')} - ${e.name}`
            });
            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editStockLocation = async (row: IStockLocation): Promise<void> => {
        setLoadingForm(true);
        await openModal('edit', row.id);
        const { location }: IStockLocation = await apiStockLocation.getById(row.id);

        if (location) {
            setvaluesInitForm({
                name: location.name ?? '',
                email: location.email ?? '',
                address: location.address ?? '',
                locationType: location?.locationType?.id ?? '',
                region: location.region ? location.region.id : '',
                customerId: location.customerId ?? '',
                //show: location.show ?? false,
                reshipmentEnabled: location?.reshipmentEnabled ?? false
            });
            setStockLocationId(location);
            setStockLocation(location);
        }

        setLoadingForm(false);
    };

    // Customer Address

    const [valuesCustomerAddressInitForm, setvaluesCustomerAddressInitForm] = useState<CustomerAddressForm>({
        customer: '',
        address1: '',
        address2: '',
        postcode: '',
        province: '',
        country: '',
        clientUnid: ''
    });

    const openModelCustomerAddress = async (original: IStockLocation) => {
        if (original.customer) {
            setModalCustomerAddress(true);
            setLoadingForm(false);

            if (original.customer.customerAddress) {
                setvaluesCustomerAddressInitForm({
                    id: original.customer?.customerAddress?.id,
                    customer: original.customerId,
                    clientUnid: original.customer?.clientUnid ?? '',
                    address1: original.customer?.customerAddress?.address1 ?? '',
                    address2: original.customer?.customerAddress?.address2 ?? '',
                    postcode: original.customer?.customerAddress?.postcode ?? '',
                    province: original.customer?.customerAddress?.province ?? '',
                    country: original.customer?.customerAddress?.country ?? '',
                    email: original.customer?.customerAddress?.email ?? '',
                    phoneNumber: original.customer?.customerAddress?.phoneNumber ?? ''
                });
            } else {
                setvaluesCustomerAddressInitForm({
                    customer: original.customerId,
                    clientUnid: original.customer?.clientUnid ?? '',
                    address1: '',
                    address2: '',
                    postcode: '',
                    province: '',
                    country: '',
                    email: '',
                    phoneNumber: ''
                });
            }
        }
    };

    const closeModelCustomerAddress = async () => {
        setModalCustomerAddress(false);
    };

    const customerAddressHandleSubmit = async (
        e: any,
        resetForm: (values: object) => void,
        setSubmitting
    ): Promise<void> => {
        try {
            const { data }: ICustomerAddress = !e.id
                ? await apiCustomerAddress.create(e)
                : await apiCustomerAddress.patch(e.id ?? 0, e);

            if (!data) {
                throw data;
            }

            setRefresh(true);
            resetForm({});

            Success({
                text: `${translate('t.customer_address_successfully_updated')}`
            });

            handleClose();
            setSubmitting(false);
            setModalCustomerAddress(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const confirmDeleteRow = (row) =>
        popUpConfirm(
            translate('t.shipping_location') + `: ${row.name}`,
            translate('p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: translate('t.yes'),
                no: translate('t.no')
            }
        );

    const deleteRow = async (onClose, row: IStockLocation): Promise<void> => {
        try {
            const location: IStockLocation = await apiStockLocation.remove(row.id);
            if (!location) {
                throw location;
            }
            setRefresh(true);
            onClose();
            Success({ text: `${translate('t.shipping_location_successfully_removed')} - ${row.name}` });
        } catch (error) {
            console.warn('Error to sending data', error);
        }
    };

    const closeModalItems = () => {
        setdataItemsStockLocation([]);
        setModalItems(false);
    };

    const ActionsButtons = (original: IStockLocation): JSX.Element => {
        return (
            <>
                {original.customerId && canCreateAndEditCustomerAddress ? (
                    <Typography className='dataTableActionsBtn' onClick={() => openModelCustomerAddress(original)}>
                        <BrandingWatermark />
                        <span>
                            {translate('t.customer_address')}
                        </span>
                    </Typography>
                ) : (
                    <></>
                )}

                <Typography className='dataTableActionsBtn' onClick={() => editStockLocation(original)}>
                    <Edit />
                    <span>{ canCreateAndUpdata ? translate('t.edit') : translate('t.show') }</span>
                </Typography>

                <Typography className='dataTableActionsBtn' onClick={() => getItemsSTockLocations(original)}>
                    <ListAlt />
                    <span>{translate('t.items_stock_location')}</span>
                </Typography>

                {canCreateAndUpdata ? (
                    <>
                        <Typography className={'dataTableActionsBtn danger'} onClick={() => confirmDeleteRow(original)}>
                            <Delete />
                            <span>{translate('t.remove')}</span>
                        </Typography></>
                ) : ''}
            </>
        );
    };

    const typeSelectFilter = (column, setFilter): JSX.Element => {
        return (
            <Autocomplete
                getOptionLabel={(option) => option.name}
                id='locationType'
                onChange={(_, value) => {
                    const values = !value
                        ? undefined
                        : {
                              name: column.Header,
                              humanValue: value.name,
                              value: `${value.name}`
                          };
                    setFilter(column.id, values);
                }}
                defaultValue={locationsType.find(
                    (customer) => `${customer.name}` === `${column.filterValue?.value}` || ''
                )}
                options={locationsType}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={column.Header}
                        value={column.filterValue?.value || null}
                        InputLabelProps={{
                            shrink: true
                        }}
                        className={classes.inputFilterColumn}
                        size='small'
                        variant='outlined'
                        fullWidth
                    />
                )}
            />
        );
    };

    const SelectForFiltered = (column, setFilter): JSX.Element => {
        return (
            <FilterFieldsContainer>
                <Autocomplete
                    getOptionLabel={(option) => option.name}
                    id='customerId'
                    onChange={(_, value) => {
                        const values = !value
                            ? undefined
                            : {
                                  name: column.Header,
                                  humanValue: value.name,
                                  value: `${value.id}`
                              };
                        setFilter(column.id, values);
                    }}
                    defaultValue={customers.find(
                        (customer) => `${customer.id}` === `${column.filterValue?.value}` || ''
                    )}
                    options={customers}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={column.Header}
                            value={column.filterValue?.value || null}
                            InputLabelProps={{
                                shrink: true
                            }}
                            className={classes.inputFilterColumn}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
            </FilterFieldsContainer>
        );
    };

    // const SelectToFilterByType = (column, setFilter): JSX.Element => {
    //     return (
    //         <FilterFieldsContainer>
    //             <Autocomplete
    //                 getOptionLabel={(option) => Trans(`messages.t.${option.type}`)}
    //                 id='type'
    //                 onChange={(_, value) => {
    //                     const values = !value
    //                         ? undefined
    //                         : {
    //                               name: column.Header,
    //                               humanValue: Trans(`messages.t.${value.type}`),
    //                               value: value.type
    //                           };
    //                     setFilter(column.id, values);
    //                 }}
    //                 defaultValue={skuType.find((type) => `${type.type}` === `${column.filterValue?.value}` ?? '')}
    //                 options={skuType}
    //                 renderInput={(params) => (
    //                     <TextField
    //                         {...params}
    //                         label={column.Header}
    //                         value={column.filterValue?.value || null}
    //                         InputLabelProps={{
    //                             shrink: true
    //                         }}
    //                         className={classes.inputFilterColumn}
    //                         size='small'
    //                         variant='outlined'
    //                         fullWidth
    //                     />
    //                 )}
    //             />
    //         </FilterFieldsContainer>
    //     );
    // };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 60
        },
        {
            Header: translate('t.name'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <LinkAncla
                    href='javascript:void(0);'
                    title={row.original.name}
                    onClick={() => getItemsSTockLocations(row.original)}
                >
                    {row.original.name}
                </LinkAncla>
            )
        },
        {
            Header: translate('t.location_address'),
            accessor: 'address',
            width: 200,
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.address}> {row.original.address} </TextEllipsis>
            )
        },
        {
            Header: translate('t.customer_address'),
            accessor: 'customerAddress',
            width: 200,
            disableGroupBy: true,
            defaultCanSort: false,
            disableSortBy: true,
            canFilters: false,
            disableFilters: true,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.customer?.customerAddress?.address1 || '---'}>
                    {' '}
                    {row.original.customer?.customerAddress?.address1 || '---'}{' '}
                </TextEllipsis>
            ),
            Export: ({ row }: CellProps) => row.original.customer?.customerAddress?.address1
        },
        {
            Header: translate('t.type'),
            accessor: 'locationType.name',
            disableGroupBy: true,
            aggregate: 'count',
            width: 90,
            Cell: ({ row }: CellProps) => (row.original.locationType ? row.original.locationType.name : ''),
            Filter: ({ column, setFilter }: CellProps) => {
                return typeSelectFilter(column, setFilter);
            }
        },
        {
            Header: translate('t.region'),
            accessor: 'regionTranslationString',
            align: 'left',
            width: 120,
            aggregate: 'count',
            disableGroupBy: true,
            defaultCanSort: false,
            disableSortBy: false,
            canFilters: true,
            Filter: ({ column, setFilter }: CellProps) => {
                const values = [
                    { value: 'internal', name: 'Internal' },
                    { value: 'south_america', name: 'South' },
                    { value: 'north_america', name: 'North' },
                    { value: 'europe', name: 'Europe' },
                    { value: 'africa', name: 'Africa' },
                    { value: 'australia', name: 'Australia' },
                ];

                return (
                    <FilterFieldsContainer>
                        <TextField
                            select
                            size='small'
                            variant='outlined'
                            label={column.Header}
                            value={column.filterValue?.value || null}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                if (e.target.value) {
                                    const value = values.find((v) => v.value === e.target.value);
    
                                    if (value) {
                                        setFilter(column.id, {
                                            name: column.Header,
                                            humanValue: value.name,
                                            value: value.value
                                        });
                                    }
                                } else {
                                    setFilter(column.id, undefined);
                                }
                            }}
                        >
                            {values.map(({ value, name }) => (
                                <MenuItem key={value} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FilterFieldsContainer>
                );
            },
            Export: ({ row }: CellProps) => row.original.region.translationString.replace('_', ' '),
            Cell: ({ row }: CellProps<JSX.Element>) => {
                return (
                    <div style={{ textTransform: 'capitalize' }}>
                        {row.original.region.translationString.replace('_', ' ')}
                    </div>
                );
            }
        },
        {
            Header: translate('t.webTrack_customer'),
            accessor: 'customer',
            disableGroupBy: true,
            aggregate: 'count',
            width: 120,
            canFilters: true,
            disableSortBy: true,
            Filter: ({ column, setFilter }: CellProps) => {
                return SelectForFiltered(column, setFilter);
            },
            Export: ({ row }: CellProps) => (row.original.customer ? row.original.customer.name : ''),
            Cell: ({ row: { original } }) => (original.customer ? original.customer.name : ' ---- ')
        },
        {
            Header: translate('t.no_of_shipments'),
            accessor: 'shipmentsCount',
            disableGroupBy: true,
            aggregate: 'count',
            width: 120,
            canFilters: false,
            disableFilters: true,
            disableSortBy: true,
            Export: ({ row: { original } }: CellProps) => original.shipmentsCount,
            Cell: ({ row: { original } }) => original.shipmentsCount
        },
        {
            Header: translate('t.actions'),
            accessor: 'action',
            align: 'center',
            width: 60,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    const columnsItemsStockLocation: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: translate('t.sku'),
            accessor: 'skuCode',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps<string>) => row.original.skuCode
        },
        {
            Header: translate('t.quantity'),
            accessor: 'itemsCount',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            width: 50,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps<string>) => row.original.itemsCount
        },
        {
            Header: translate('t.available'),
            accessor: 'availableItemsCount',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            width: 50,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps<string>) => row.original.availableItemsCount
        },
        {
            Header: translate('t.used'),
            accessor: 'usedItemsCount',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            width: 50,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps<string>) => row.original.usedItemsCount
        },
        {
            Header: translate('t.scrapped'),
            accessor: 'scrappedItemsCount',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            width: 50,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps<string>) => row.original.scrappedItemsCount
        }
    ];

    useEffect(() => {
        getCustomers();
        getSkus();
        getLocationType();
    }, []);

    return (
        <view.StockLocationContent
            data-testid='StockLocation-testid'
            openModal={openModal}
            open={open}
            isLoading={loading}
            closeModal={handleClose}
            regions={regions}
            handleSubmit={handleSubmit}
            stockLocation={stockLocation}
            columns={columns}
            valuesInitForm={valuesInitForm}
            dataModal={dataModal}
            fullScreenModal={getFullScreenModal()}
            columnsItemsStockLocation={columnsItemsStockLocation}
            dataItemsStockLocation={dataItemsStockLocation}
            modalItems={modalItems}
            closeModalItems={closeModalItems}
            loadingItems={loadingItems}
            locationsType={locationsType}
            fetchData={getAllStockLocation}
            fetchDataExport={getForExport}
            total={total}
            pageCount={pageCount}
            fetchDataItems={getAllItems}
            fetchDataItemsExport={getItemsForExport}
            totalItems={totalItems}
            pageCountItems={pageCountItems}
            refresh={refresh}
            customers={customers}
            loadingForm={loadingForm}
            modalCustomerAddress={modalCustomerAddress}
            closeModelCustomerAddress={closeModelCustomerAddress}
            valuesCustomerAddressInitForm={valuesCustomerAddressInitForm}
            customerAddressHandleSubmit={customerAddressHandleSubmit}
            canCreateAndUpdata={canCreateAndUpdata}
        />
    );
};

export default Wrapper(StockLocation);
