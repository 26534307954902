import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
// import '@mui/x-date-pickers/themeAugmentation';

const UiTheme = createTheme({
    typography: {
        h1: {
            fontSize: '1.65rem',
            lineHeight: 1.2
        },
        h2: {
            fontSize: '1.5rem',
            lineHeight: 1.334
        },
        h3: {
            fontSize: '1.35rem',
            lineHeight: 1.6
        },
        h4: {
            fontSize: '1.20rem',
            lineHeight: 1.4
        },
        h5: {
            fontSize: '1.07rem',
            lineHeight: 1.3
        },
        h6: {
            fontSize: '1.01rem',
            lineHeight: 1.1
        },
        body1: {
            fontSize: '0.875rem',
            lineHeight: 1.43
        },
        subtitle1: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            fontWeight: 600
        }
    },
    components: {
        MuiClockPicker: {
            styleOverrides: {
              root: {
                '& .MuiClockPicker-arrowSwitcher': {
                    right: '5px',
                    top: '2px'
                },
                '& .MuiIconButton-root.Mui-disabled': {
                    paddingRight: '5px'
                }
              }
            }
          }
      },
});

export const FontSize = '13px';

export default UiTheme;
