import React from 'react';
import { FleetInformationTableType } from './SiteSurvey.type';
import { IconButton } from '@mui/material';
import { ColumnsTable } from '../../types/common';
import { CellProps } from 'react-table';
import Delete from '@material-ui/icons/Delete';
import UiTable from '../Ui/UiTable/UiTable';
import { useTranslation } from 'react-i18next';

const typesVehicle = {
    haul_truck: 'Haul Truck',
    front_end_loader: 'Front End Loader',
    water_cart: 'Water Cart',
    wheel_dozer: 'Wheel Dozer',
    grader: 'Grader',
    articulated_dump_truck: 'Articulated Dump Truck',
    other_state_other: 'Other (state other)'
};

export const FleetInformationTable: React.FC<FleetInformationTableType> = ({ 
    handleRemoveItems, 
    items 
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const columnsItems: ColumnsTable[] = [
        {
            Header: `${translate('t.make')} / ${translate('t.model')}`,
            accessor: 'model',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60
        },
        {
            Header: translate('t.type'),
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => typesVehicle[row.original.type]
        },
        {
            Header: translate('t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60
        },
        {
            Header: `${translate('t.utilization')} (hrs/mth)`,
            accessor: 'utilization',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.utilization || '---'
        },
        {
            Header: `${translate('t.max_speed')} (km/h)`,
            accessor: 'maxSpeed',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.maxSpeed || '---'
        },
        {
            Header: `${translate('t.tyre_size')} (inch)`,
            accessor: 'typeSize',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.tyreSize || '---'
        },
        {
            Header: `${translate('t.front_life')} (hrs)`,
            accessor: 'frontLife',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.frontLife || '---'
        },
        {
            Header: `${translate('t.rear_life')} (hrs)`,
            accessor: 'rearLife',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row }: CellProps) => row.original.rearLife || '---'
        },
        {
            Header: translate('t.actions'),
            nameLabelFilter: translate('t.item') as string,
            accessor: 'action',
            width: 40,
            minWidth: 40,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <>
                    <IconButton
                        size='small'
                        style={{ minWidth: 'auto', color: '#ef4430' }}
                        title={translate('t.remove') as string}
                        id={`btn_edit_${row.original.type}`}
                        onClick={() => handleRemoveItems(row.original, false)}
                        color='secondary'
                    >
                        <Delete />
                    </IconButton>
                </>
            )
        }
    ];

    return (
        <UiTable
            classesTable='containerDataTable'
            data={items}
            loading={false}
            hidePagination={true}
            hideForceRefresh={true}
            subtractSpace={380}
            noPagination={true}
            columns={columnsItems}
            hiddenColumns={[]}
            hideXlsExport={true}
            hideTableActions={true}
        />
    );
};
