import React, { useState } from 'react';
import Wrapper from '../../../helpers/wrapper';
import { StepThreeContent } from './StepThree.view';
import { SiteSurveyStepThreeForm, StepThreeProps } from './StepThree.type';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
import { Warning } from '../../Popup/Popup';
import { useTranslation } from 'react-i18next';
// import module

const VALUESINITFROM = {
    model: '',
    type: '',
    quantity: '',
    utilization: '',
    maxSpeed: '',
    tyreSize: '',
    frontLife: '',
    rearLife: '',
    totalQuantity: 0
};

const validatePositiveNumericValues = (values: Record<string, any>): boolean => {
    for (const key in values) {
        if (typeof values[key] === 'number' && values[key] < 0) {
            return false;
        }
    }
    return true; // No negative numeric values found
};

const StepThree: React.FC<StepThreeProps> = ({
    activeStep,
    publicMode,
    bottonHiddenUpdate,
    handleNext,
    handleBack,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyData = useRecoilValue(SurveyState);
    const [items, setItems] = useState<any[]>(
        surveyData?.customerOnboarding?.data?.vehicles
            ? Object.values(surveyData?.customerOnboarding?.data?.vehicles)
            : []
    );
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [valuesInitForm, setValuesInitForm] = useState<SiteSurveyStepThreeForm>({
        ...VALUESINITFROM,
        totalQuantity: checkSurvey(surveyData, 'totalQuantity')
    });

    const optionsSelect = [
        { value: 'haul_truck', label: 'Haul Truck' },
        { value: 'front_end_loader', label: 'Front End Loader' },
        { value: 'water_cart', label: 'Water Cart' },
        { value: 'wheel_dozer', label: 'Wheel Dozer' },
        { value: 'grader', label: 'Grader' },
        { value: 'articulated_dump_truck', label: 'Articulated Dump Truck' },
        { value: 'other_state_other', label: 'Other (state other)' }
    ];

    const handleAdd = (values, setValues): void => {
        setLoadingForm(true);
        if (values?.model && values?.type && values?.quantity) {
            if ( validatePositiveNumericValues(values) ) {
                if (items.filter((i) => (i.model === values.model && i.type === values.type)).length) {
                    Warning({
                        text: `${translate('p.survey_vehicle_duplicate')}`
                    });
                }else{
                    const { totalQuantity, ...newItemWithoutTotalQuantity } = values;
                    setItems([...items, newItemWithoutTotalQuantity]);
                    setValues({
                        ...VALUESINITFROM,
                        totalQuantity: totalQuantity
                    });
                    setValuesInitForm(VALUESINITFROM);
                    setShowMsg(false);
                }
            }else{
                Warning({
                    text: `${translate('p.survey_vehicle_negative')}`
                });
            }
        } else {
            setShowMsg(true);
        }
        setTimeout(() => {
            setLoadingForm(false);
        }, 500);
    };

    const handleRemoveItems = (item): void => {
        if (items && items.length) {
            setItems(items.filter((i) => (i.model !== item.model || i.type !== item.type)));
        }
    };

    return (
        <StepThreeContent
            data-testid='StepThree-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            valuesInitForm={valuesInitForm}
            optionsSelect={optionsSelect}
            items={items}
            showMsg={showMsg}
            publicMode={publicMode}
            bottonHiddenUpdate={bottonHiddenUpdate}
            loadingForm={loadingForm}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            handleAdd={handleAdd}
            handleRemoveItems={handleRemoveItems}
            updateSurveyTemporal={updateSurveyTemporal}
            setModalReturnToProgress={setModalReturnToProgress}
        />
    );
};

export default Wrapper(StepThree);