/* eslint-disable max-len */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { EmailAcceptanceFormik as EmailAcceptanceFormikType, ISignUpForm } from './ShipmentAcceptance.type';
import { Form, Formik, FormikProps } from 'formik';
import { Button, CircularProgress, Grid } from '@mui/material';
import { ChipsAutocompleteElement } from '../../FormElements';
import { ContainerBtnSendEmail } from './ShipmentAcceptance.style';
import { useTranslation } from 'react-i18next';

export const EmailAcceptanceFormik: React.FC<EmailAcceptanceFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const defaultList: string =
        !props.shippingOrder.sendEmail && props.shippingOrder.shipmentAcceptanceEmail
            ? props.shippingOrder.shipmentAcceptanceEmail
            : '';
    const [newListEmail, setNewListEmail] = useState(defaultList);
    const emailSchema = Yup.array().of(Yup.string().email());

    return (
        <Formik
            initialValues={{
                emailAcceptance: defaultList
            }}
            // onSubmit={(values: ISignUpForm, actions) => {
            //     props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            // }}
            onSubmit={(values: ISignUpForm, actions) => {
                actions.setSubmitting(false);

                if (!newListEmail) {
                    return;
                }

                props.shippingOrder.hash !== null
                    ? props.confirmSendEmail(
                          { emailAcceptance: newListEmail },
                          actions.resetForm,
                          actions.setSubmitting
                      )
                    : props.sendEmail(false, {
                          e: { emailAcceptance: newListEmail },
                          resetForm: actions.resetForm,
                          setSubmiting: actions.setSubmitting
                      });
            }}
            validationSchema={Yup.object().shape({
                emailAcceptance: Yup.string()
                    // .required(translate('p.this_field_is_required'))
                    .test('emailsValidation', translate('p.please_enter_valid_email') as string, function (value) {
                        if (!value) {
                            return true;
                        }
                        const emails = value.split(';').map((email) => email.trim());
                        return emailSchema.isValidSync(emails);
                    })
                    .nullable()
            })}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, handleChange, handleBlur, values, isSubmitting, submitForm } = propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <Grid
                            style={{ marginLeft: '25%' }}
                            container
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            justifyContent='center'
                            direction='row'
                        >
                            <ChipsAutocompleteElement
                                label={translate('t.email')}
                                name='emailAcceptance'
                                value={undefined}
                                defaultValue={
                                    values.emailAcceptance
                                        ? values.emailAcceptance.split(';').filter((email) => email.trim())
                                        : ''
                                }
                                error={errors.emailAcceptance}
                                touched={touched.emailAcceptance}
                                onChange={(e, value: any) => {
                                    values.emailAcceptance = value.join(';');
                                    setNewListEmail(value.join(';'));
                                    handleChange(e);
                                }}
                                onBlur={(e) => {
                                    let listEmail: string | null = null;
                                    if (e.target.value) {
                                        listEmail = e.target.value
                                            .split(';')
                                            .filter((email) => email.trim())
                                            .join(';');
                                        if (values.emailAcceptance && props.shippingOrder.shipmentAcceptanceEmail) {
                                            let initValues: string[] = props.shippingOrder.shipmentAcceptanceEmail
                                                .replace(' ', '')
                                                .split(';')
                                                .filter((email) => email.trim());

                                            if (e.target.value) {
                                                const newList: string[] = e.target.value
                                                    .split(' ')
                                                    .filter((email) => email.trim());
                                                initValues = [...initValues, ...newList];
                                            }
                                            values.emailAcceptance = initValues.join(';');
                                            setNewListEmail(initValues.join(';'));
                                        } else {
                                            if (listEmail) {
                                                values.emailAcceptance = listEmail.replace(' ', ';');
                                                setNewListEmail(listEmail.replace(' ', ';'));
                                            }
                                        }
                                    } else {
                                        setNewListEmail('');
                                    }

                                    handleBlur(e);
                                }}
                                lg={10}
                                md={10}
                                sm={10}
                                xs={10}
                            />
                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                <ContainerBtnSendEmail>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size='small'
                                        type='submit'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : translate('t.send')}
                                    </Button>
                                </ContainerBtnSendEmail>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
