import React, { useState } from 'react';
import * as style from './SkuPrices.style';
import { SkuPricesViewProps } from './SkuPrices.type';
import UiTable from '../../components/Ui/UiTable/UiTable';
import UiModal from '../Ui/UiModal/UiModal';
import Loading from '../Loading';
import { SkuPriceFormik } from './SkuPrice.formik';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import modul

export const SkuPricesContent: React.FC<SkuPricesViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <style.SkuPricesContent data-testid='SkuPricesContent'>
            <UiModal
                open={props.modalSkuPrices}
                closeModal={props.closeModalSkuPrices}
                title={translate('t.prices') + ' ' + props.sku?.skuCode}
                maxWidthModal='md'
                content={
                    <style.SkuPricesModelContent>
                        <UiTable
                            title='skuPrices'
                            data={props.dataSkuPrices}
                            loading={props.modalSkuPricesLoading}
                            columns={props.columnsSkuPrices}
                            onAdd={props.showBtnAdd ? () => props.openAddModal : null}
                            total={props.totalSkuPrices}
                            pageCount={props.pageCountSkuPrices}
                            fetchData={({ page, limit, order, filter }) => {
                                props.fetchDataItems(page + 1, limit, order, filter);
                            }}
                            defaultSortBy={{
                                column: 'validFrom',
                                desc: true
                            }}
                            hiddenColumns={[]}
                            forceRefresh={props.refresh}
                        />
                    </style.SkuPricesModelContent>
                }
            />

            <UiModal
                open={props.openAddModel}
                closeModal={props.handleClose}
                title={props.dataModal?.title}
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <SkuPriceFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            dataModal={props.dataModal}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                            isValidDateRange={props.isValidDateRange}
                            pageCountItems={props.pageCountSkuPrices}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.handleClose();
                            }}
                            color='secondary'
                        >
                            {translate('t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting || !props.sendFrom}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                        </Button>
                    </>
                }
            />
        </style.SkuPricesContent>
    );
};
