import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { SurveyViewProps } from './Survey.type';
import { SurveyContainerModal, SurveyContentStyle } from './Survey.style';
import UiTable from '../Ui/UiTable/UiTable';
import { ButtonCloseDialog, ButtonFab, UiDialogTitle } from '../Box/Box.style';
import { Close } from '@mui/icons-material';
import CreateInvitacion from './CreateInvitation/CreateInvitation';
import * as SurveysState from '../../states/component/Survey';
import { useRecoilValue } from 'recoil';
import SiteSurvey from '../SiteSurvey/SiteSurvey';

// import module

export const SurveyContent: React.FC<SurveyViewProps> = ({
    columnsSurvey,
    open,
    loading,
    total,
    pageCount,
    refresh,
    idDetail,
    openModal,
    closeModal,
    fetchData,
    setRefresh,
}): JSX.Element => {

    return (
        <SurveyContentStyle data-testid='SurveyContent'>
            <UiTable
                title='surveys'
                data={useRecoilValue(SurveysState.Surveys)}
                loading={loading}
                columns={columnsSurvey}
                total={total}
                pageCount={pageCount}
                fetchData={({ page, limit, order, filter }) => {
                    fetchData(page + 1, limit, order, filter);
                }}
                defaultSortBy={{
                    column: 'id',
                    desc: true
                }}
                forceRefresh={refresh}
                hiddenColumns={[]}
                onAdd={() => openModal}
                hideXlsExport={true}
            />

            <Dialog
                fullScreen={false}
                fullWidth={true}
                maxWidth={idDetail ? 'xl' : 'sm'}
                open={open}
                onClose={closeModal}
                aria-labelledby='responsive-dialog-title'
                className='static_action_buttons'
            >
                <UiDialogTitle>
                    {idDetail ? 'Survery' : 'New Survery'} 
                    <ButtonCloseDialog>
                        <ButtonFab
                            size='small'
                            aria-label='add'
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            <Close />
                        </ButtonFab>
                    </ButtonCloseDialog>
                </UiDialogTitle>

                <DialogContent sx={idDetail ? { padding: '0px' } : undefined} >
                    {!idDetail && <CreateInvitacion closeModal={closeModal} setRefresh={setRefresh} />}
                    {idDetail && (
                        <SurveyContainerModal>
                            <SiteSurvey 
                                publicMode={false} 
                                id={idDetail} 
                                closeModal={closeModal} 
                            />
                        </SurveyContainerModal>
                    )}
                </DialogContent>
            </Dialog>
        </SurveyContentStyle>
    );
};