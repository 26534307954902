import { Button, CircularProgress, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './Sku.type';
import { Skus } from '../../states/component/Sku';
import UiTable from '../Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
import { SkuFormik } from './Sku.formik';
import { useTranslation } from 'react-i18next';
// import module

export const SkuContent: React.FC<type.SkuViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='sku'
                    data={useRecoilValue(Skus)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    onAdd={props.showBtnAdd ? () => props.openModal : null}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter }) => {
                        props.fetchData(page + 1, limit, order, filter);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'id',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                />
            </Paper>

            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                title={props.dataModal?.title}
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <SkuFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            dataModal={props.dataModal}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                            skuType={props.skuType ? props.skuType : []}
                            imageUrl={props.dataModal?.id ? props.imageUrl : ''}
                            specificationUrl={props.dataModal?.id ? props.specificationUrl : ''}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {translate('t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                        </Button>
                    </>
                }
            />
        </>
    );
};
