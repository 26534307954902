import React, { useState } from 'react';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { MarkAsShippedContent as MarkAsShippedContentStyle } from './MarkAsShipped.style';
import * as type from './MarkAsShipped.type';
import UiModal from '../../Ui/UiModal/UiModal';
import { MarkAsShippedFormik } from './MarkAsShipped.formik';
import { useTranslation } from 'react-i18next';
// import module

export const MarkAsShippedContent: React.FC<type.MarkAsShippedViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <MarkAsShippedContentStyle data-testid='MarkAsShippedContent'>
            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                maxWidthModal='md'
                minHeight={150}
                title={`${translate('t.mark_as_shipped')} - ${translate('t.order_id')} #${
                    props.shippingOrderId
                }`}
                content={
                    <MarkAsShippedFormik
                        valuesInitForm={props.valuesInitForm}
                        handleSubmit={props.handleSubmit}
                        bindSubmitForm={bindSubmitForm}
                        setIsSubmitting={setIsSubmitting}
                        fromUserTimezoneToTimezone={props.fromUserTimezoneToTimezone}
                        getDateMask={props.getDateMask}
                        shippingOrder={props.shippingOrder}
                        shippers={props.shippers}
                        loadMoreShipper={props.loadMoreShipper}
                    />
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {translate('t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                        </Button>
                    </>
                }
            />
        </MarkAsShippedContentStyle>
    );
};
