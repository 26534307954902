import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as type from './ResetPassword.type';
import * as view from './ResetPassword.view';
import ApiRecoverPassword from '../../api/ApiRecoverPassword';
import { Success } from '../Popup/Popup';
import Wrapper from '../../helpers/wrapper';
import { HashVerificationModel } from '../../models/ApiRecoverPassword.type';
import { Lang } from '../../states/global/Translator';
import { useTranslation } from 'react-i18next';
import { getFromLocalStorage } from '../../helpers/localStorage';
// import module

const api = new ApiRecoverPassword();

const ResetPassword: React.FC<type.ResetPasswordProps> = () => {
    const { t: translate } = useTranslation();
    const setLangSelect = useSetRecoilState<any>(Lang);
    const history = useHistory();

    const { hash }: any = useParams();

    const valuesInitForm: object = {
        newPassword: '',
        newPasswordRepeat: ''
    };

    const handleSubmit = async (e: any, setSubmitting) => {
        delete e.newPasswordRepeat;
        try {
            const ResetPassword: any = await api.patchGeneralFree(
                `public/recover-password/change-password/${hash}`,
                e,
                ''
            );

            if (!ResetPassword) {
                throw ResetPassword;
            }
            Success({ text: translate('p.password_recovered_successfully') as string });
            setSubmitting({});
            setTimeout(() => {
                history.push('/');
            }, 2000);
        } catch (error) {
            console.warn('Error to sending data ', error);
        }
    };

    const hashVerifid = async (): Promise<void> => {
        const {
            data: { hash_valid }
        }: HashVerificationModel = await api.getGeneral(
            `public/recover-password/hash-verification/${hash}`,
            undefined,
            ''
        );

        if (!hash_valid) {
            history.push('/login');
            Success({ text: translate('p.hash_expired') as string });
        }
    };

    useEffect(() => {
        hashVerifid();
    }, []);

    useEffect(() => {
        getFromLocalStorage('language') && setLangSelect(getFromLocalStorage('language'));
    }, [setLangSelect]);

    return (
        <view.ResetPasswordContent
            data-testid={'ResetPassword-testid'}
            handleSubmit={handleSubmit}
            valuesInitForm={valuesInitForm}
        />
    );
};

export default Wrapper(ResetPassword);
