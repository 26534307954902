import React from 'react';
import * as style from './BoxItems.style';
import * as type from './BoxItems.type';
import UiTable from '../../components/Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import modul

export const BoxItemsContent: React.FC<type.BoxItemsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const ElementFilter = props.itemsPartialBox.length ? (
        <Box>
            <Typography variant='subtitle1' gutterBottom>
                {translate('t.selected_items')}: {props.itemsPartialBox.length}
            </Typography>
            <Button
                variant='contained'
                color='primary'
                size='small'
                type='button'
                onClick={() => {
                    props.sendSelectedItems && props.sendSelectedItems(props.itemsPartialBox);
                    props.resetSelectedItems();
                }}
                data-testid='BoxItemsContent-Button-add'
            >
                {translate('t.add')}
            </Button>
        </Box>
    ) : (
        <></>
    );

    return (
        <style.BoxItemsContent data-testid='BoxItemsContent'>
            <UiModal
                open={props.modalItems}
                closeModal={props.closeModalItems}
                title={translate('t.box_items') + ' - #' + props.boxId}
                maxWidthModal='xl'
                content={
                    props.columnsBoxItems.length && !props.modalItemsLoading ? (
                        <UiTable
                            classesTable='containerDataTable'
                            data={props.dataBoxItems}
                            loading={false}
                            hidePagination={true}
                            hideForceRefresh={true}
                            noPagination={true}
                            columns={props.columnsBoxItems}
                            hiddenColumns={[]}
                            globalFilter={true}
                            hasExportSKU
                            customButtonAdd={props.sendSelectedItems ? ElementFilter : undefined}
                            
                        />
                    ) : (
                        <Loading />
                    )
                }
            />
        </style.BoxItemsContent>
    );
};
