import React, { useEffect, useState } from 'react';
import { CreateInvitacionProps, CreateInvitationForm, InvitationSend } from './CreateInvitation.type';
import * as view from './CreateInvitation.view';
import Wrapper from '../../../helpers/wrapper';
import { Success } from '../../Popup/Popup';
import { TypeRegions } from '../../../models/ApiRegions.type';
import ApiRegions from '../../../api/ApiRegions';
import ApiTimeZone from '../../../api/ApiTimeZone';
import ApiSurvey from '../../../api/ApiSurvey';
import { OrderAlphabetically, getSortedTimezone } from '../../../helpers/converter';
import { ITimeZoneFormatted, TimeZoneType } from '../../../models/ApiTimeZone.type';
import { LocationsItems } from '../../../models/ApiItems.type';
import { useTranslation } from 'react-i18next';
import { DateTimeFormat } from '../../../helpers/Converters';
import { useRecoilValue } from 'recoil';
// import module

const apiRegions = new ApiRegions();
const apiTimeZone = new ApiTimeZone();
const apiSurvey = new ApiSurvey();

const CreateInvitation:React.FC<CreateInvitacionProps> = ({ closeModal, setRefresh }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [regions, setRegions] = useState<TypeRegions[]>([]);
    const [timeZones, setTimeZones] = useState<ITimeZoneFormatted[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [loadingDataStockLocation, setLoadingDataStockLocation] = useState<boolean>(false);
    const dateTimeFormat = useRecoilValue(DateTimeFormat);
    // eslint-disable-next-line max-len
    const emailOmboarding = process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.includes('https://webtrack-dev.atmstechnology.com')
    ? 'onboarding_test@bmst.bridgestone'
    : 'onboarding@bmst.bridgestone';

    const valuesInitForm: CreateInvitationForm = {
        email: '',
        name: '',
        customer: undefined,
        region: undefined,
        timezone: undefined,
        stockLocation: {} as LocationsItems
    };

    const handleSubmit = async (
        e: CreateInvitationForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting
    ): Promise<void> => {
        try {
            const dataSend: InvitationSend = {
                name: e.name,
                email: `${e.email},${emailOmboarding}`,
                stockLocation: e.stockLocation.id,
                region: e.region ?? 0
            };

            if(e.customer) {
                dataSend.customer = e.customer;
            }

            if(e.timezone) {
                dataSend.timezone = e.timezone;
            }

            const {
                data: { customerOnboardingInvitation }
            }: any = await apiSurvey.create(dataSend);

            if (!customerOnboardingInvitation) {
                throw customerOnboardingInvitation;
            }

            resetForm({});

            Success({
                text: `${translate('t.survey_created_successfully')}`
            });

            setRefresh(true);
            setSubmitting(false);
            closeModal();
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const getSelect = async () => {
        const { items }: TypeRegions = await apiRegions.get('?limit=200');
        // Eliminar
        // const customers: ModelCustomerItrack[] = await apiCustomer.getCustomersCodeBook();
        //
        const timeZones: TimeZoneType[] = await apiTimeZone.getTimeZoneCodeBook();

        items && setRegions(OrderAlphabetically(items, 'translationString'));
        // customers && setCustomers(OrderAlphabetically(customers, 'name'));
        timeZones && setTimeZones(getSortedTimezone(timeZones, dateTimeFormat) );
        setLoadingData(false);
    };

    const updateRegion = (value, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setLoadingDataStockLocation(true);

        setTimeout(() => {
            setFieldValue('stockLocation', '');
            setLoadingDataStockLocation(false);
        }, 500);
    };

    useEffect(() => {
        getSelect();
    }, []);

    return (
        <view.CreateInvitationContent 
            data-testid='CreateInvitacion-testid'
            valuesInitForm={valuesInitForm}
            regions={regions}
            // customers={customers}
            timeZones={timeZones}
            loadingData={loadingData}
            loadingDataStockLocation={loadingDataStockLocation}
            handleSubmit={handleSubmit}
            updateRegion={updateRegion}
        />
    );  
};

export default Wrapper(CreateInvitation);