import { Button, CircularProgress, IconButton, Paper, Tab, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import { Clear, PhotoCamera } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import * as style from './Profile.style';
import { IFormEdit, IFormKey, ProfilePropsView } from './Profile.type';
import logo from '../../assets/images/not_avatar.png';
import { AutocompleteElement, InputElement } from '../FormElements';
import { Loading } from '../../helpers/Loading';
import UiPhoneInputNumber from '../Ui/UiPhoneInputNumber/UiPhoneInputNumber';
import { useTranslation } from 'react-i18next';
// import module

export const ProfileContent: React.FC<ProfilePropsView> = (props) => {
    const { t: translate } = useTranslation();
    const [value, setValue] = useState('1');
    const handleChangeTab = (_, newValue: string) => {
        setValue(newValue);
    };
    const [selectedFile, setSelectedFile] = useState('');
    const [previewImg, setPreviewImg] = useState('');
    const [isValid, setIsValid] = useState<string | null>(null);

    const handleCapture = ({ target }): void => {
        setSelectedFile(target.files[0]);
        setPreviewImg(URL.createObjectURL(target.files[0]));
    };

    return (
        <style.ProfileContent data-testid='ProfileContent'>
            <Paper elevation={1}>
                <TabContext value={value}>
                    <AppBar position='static' className='appBarElement'>
                        <TabList
                            onChange={handleChangeTab}
                            onClick={(): void => console.warn('resetForm')}
                            aria-label='simple tabs example'
                            indicatorColor='primary'
                            textColor='primary'
                            centered
                        >
                            <Tab
                                style={{ fontWeight: 600 }}
                                label={translate('t.edit_account') as string}
                                value='1'
                                title={translate('t.edit_account') as string}
                            />
                            <Tab
                                style={{ fontWeight: 600 }}
                                label={translate('t.change_password') as string}
                                value='2'
                                title={translate('t.change_password') as string}
                            />
                        </TabList>
                    </AppBar>
                    <TabPanel value='1'>
                        {props.readyForm ? (
                            <div className='textBox_sensors' style={{ minHeight: '137px' }}>
                                {value === '1' && (
                                    <Formik
                                        initialValues={props.valuesInitFormEdit}
                                        onSubmit={(values: IFormEdit, actions) => {
                                            props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
                                        }}
                                        validationSchema={Yup.object().shape({
                                            username: Yup.string()
                                                .required(translate('p.this_field_is_required') as string)
                                                .min(3, 'Name is Too Short.')
                                                .trim(),
                                            email: Yup.string()
                                                .required(translate('p.this_field_is_required') as string)
                                                .email(),
                                            role: Yup.string()
                                            .required(translate('p.this_field_is_required') as string),
                                            phoneNumber: Yup.string().required(
                                                translate('p.this_field_is_required') as string
                                            ),
                                            firstname: Yup.string().required(
                                                translate('p.this_field_is_required') as string
                                            ),
                                            lastname: Yup.string()
                                            .required(translate('p.this_field_is_required') as string)
                                        })}
                                    >
                                        {(propsF: FormikProps<IFormEdit>) => {
                                            const {
                                                touched,
                                                errors,
                                                setFieldValue,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                isSubmitting
                                            } = propsF;

                                            values.avatar = selectedFile;
                                            return (
                                                <Form>
                                                    <Grid container justifyContent='flex-start' direction='row'>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <style.GridDiv textAlign='center'>
                                                                <div className='btnUpLoad'>
                                                                    <strong>
                                                                        {props.user.firstname} {props.user.lastname}
                                                                    </strong>
                                                                </div>
                                                                <style.ContainerImage
                                                                    style={{
                                                                        backgroundImage: `url(${
                                                                            previewImg ? previewImg : logo
                                                                        })`,
                                                                        backgroundSize: 'cover'
                                                                    }}
                                                                >
                                                                    <style.ContainerBtnImage
                                                                        style={{
                                                                            background: previewImg ? 'red' : '#2d863d'
                                                                        }}
                                                                    >
                                                                        {previewImg ? (
                                                                            <Tooltip title='Remove Image'>
                                                                                <IconButton
                                                                                    title='Change Avatar'
                                                                                    color='primary'
                                                                                    aria-label='upload Avatar'
                                                                                    component='label'
                                                                                    onClick={() => {
                                                                                        setPreviewImg('');
                                                                                    }}
                                                                                >
                                                                                    <Clear style={{ color: '#fff' }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title='Select Image'>
                                                                                <IconButton
                                                                                    title='Change Avatar'
                                                                                    aria-label='upload Avatar'
                                                                                    component='label'
                                                                                >
                                                                                    <PhotoCamera
                                                                                        style={{ color: '#fff' }}
                                                                                    />
                                                                                    <input
                                                                                        type='file'
                                                                                        hidden
                                                                                        accept='image/*'
                                                                                        onChange={handleCapture}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </style.ContainerBtnImage>
                                                                </style.ContainerImage>
                                                            </style.GridDiv>
                                                        </Grid>

                                                        <InputElement
                                                            autoFocus={true}
                                                            label={translate('t.username')}
                                                            name='username'
                                                            value={values.username}
                                                            error={errors.username}
                                                            touched={touched.username}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />

                                                        <InputElement
                                                            label={translate('t.email')}
                                                            name='email'
                                                            value={values.email}
                                                            error={errors.email}
                                                            touched={touched.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />

                                                        <AutocompleteElement
                                                            label={translate('t.role')}
                                                            name='role'
                                                            disabled={props.user.stockRole.id !== 5}
                                                            value={props.user.stockRole.name}
                                                            error={errors.role}
                                                            touched={touched.role}
                                                            onBlur={handleBlur}
                                                            setFieldValue={setFieldValue}
                                                            options={props.roles ? props.roles : []}
                                                            defaultValue={{
                                                                id: props.user.stockRole.id,
                                                                name: props.user.stockRole.name
                                                            }}
                                                            optionsPropertyLabel='name'
                                                            optionsPropertyValue='id'
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />

                                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                                            <UiPhoneInputNumber
                                                                value={values.phoneNumber}
                                                                setFieldValue={setFieldValue}
                                                                international={true}
                                                                className='PhoneInputCustom'
                                                                defaultCountry=''
                                                                placeholder={translate('t.phone_number')}
                                                                name='phoneNumber'
                                                                setIsValid={setIsValid}
                                                                isValid={isValid}
                                                                required={true}
                                                                error={errors.phoneNumber}
                                                            />
                                                        </Grid>

                                                        <InputElement
                                                            label={translate('t.first_name')}
                                                            name='firstname'
                                                            value={values.firstname}
                                                            error={errors.firstname}
                                                            touched={touched.firstname}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />

                                                        <InputElement
                                                            label={translate('t.last_name')}
                                                            name='lastname'
                                                            value={values.lastname}
                                                            error={errors.lastname}
                                                            touched={touched.lastname}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            lg={4}
                                                            md={4}
                                                            sm={4}
                                                        />

                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <style.GridDiv float='right'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    color='primary'
                                                                    type='submit'
                                                                    disabled={!!isValid || isSubmitting}
                                                                >
                                                                    {isSubmitting ? (
                                                                        <CircularProgress size={25} />
                                                                    ) : (
                                                                        translate('t.save')
                                                                    )}
                                                                </Button>
                                                            </style.GridDiv>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                )}
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </TabPanel>
                    <TabPanel value='2'>
                        <div className='textFieldFormik'>
                            {value === '2' && (
                                <Formik
                                    initialValues={props.valuesInitFormKey}
                                    onSubmit={(values: IFormKey, actions) => {
                                        props.handleSubmitPassword(values, actions.resetForm, actions.setSubmitting);
                                    }}
                                    validationSchema={Yup.object().shape({
                                        current_password: Yup.string()
                                            .required(translate('p.this_field_is_required') as string)
                                            .min(6, 'Password is Too Short.'),

                                        password: Yup.string()
                                            .required(translate('p.this_field_is_required') as string)
                                            .min(6, 'Password is Too Short.')
                                            .test(
                                                'passwords-match',
                                                translate(
                                                    'p.current_password_cannot_be_equal_to_new_password') as string,
                                                function (value) {
                                                    return this.parent.current_password !== value;
                                                }
                                            )
                                            .matches(
                                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                translate(
                                                    'p.mustcontain_8_characters' 
                                                ) as string
                                            ),
                                        confirm_password: Yup.string()
                                            .required(translate('p.this_field_is_required') as string)
                                            .oneOf(
                                                [Yup.ref('password'), null],
                                                translate('t.passwords_must_match') as string
                                            )
                                    })}
                                >
                                    {(propsF: FormikProps<IFormKey>) => {
                                        const { touched, errors, handleChange, handleBlur, values, isSubmitting } =
                                            propsF;
                                        return (
                                            <Form>
                                                <Grid container justifyContent='flex-start' direction='row'>
                                                    <InputElement
                                                        type='password'
                                                        autoFocus={true}
                                                        label={translate('t.current_password')}
                                                        name='current_password'
                                                        value={values.current_password}
                                                        error={errors.current_password}
                                                        touched={touched.current_password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                    />

                                                    <InputElement
                                                        type='password'
                                                        label={translate('t.new_password')}
                                                        name='password'
                                                        value={values.password}
                                                        error={errors.password}
                                                        touched={touched.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                    />

                                                    <InputElement
                                                        type='password'
                                                        label={translate('t.new_password_repeat')}
                                                        name='confirm_password'
                                                        value={values.confirm_password}
                                                        error={errors.confirm_password}
                                                        touched={touched.confirm_password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                    />

                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <style.GridDiv float='right'>
                                                            <Button
                                                                variant='contained'
                                                                color='primary'
                                                                size='small'
                                                                type='submit'
                                                                disabled={isSubmitting}
                                                            >
                                                                {isSubmitting ? (
                                                                    <CircularProgress size={25} />
                                                                ) : (
                                                                    translate('t.save')
                                                                )}
                                                            </Button>
                                                        </style.GridDiv>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            )}
                        </div>
                    </TabPanel>
                </TabContext>
            </Paper>
        </style.ProfileContent>
    );
};
