import { Popover } from '@mui/material';
import styled from 'styled-components';

export const UiLegendContent = styled.div`
    display: inline-block;
`;

export const Legend = styled(Popover)<{
    width?: number;
}>`
    &.MuiPopover-root {
        color: #3a3633 & .MuiPaper-root {
            min-width: 250px !important;
        }
        & .legend-header {
            padding: 8px 15px !important;
            line-height: 2;
            min-height: 25px;
            button {
                padding: 1px;
            }
        }
        & .legend-body {
            ${(props) => props.width && `width: ${props.width}px;`}
            padding: 8px 15px !important;
            font-size: 0.875rem;
            min-height: auto;
        }
    }
`;
