import React from 'react';
import { SimpleSelectAsyncPaginate } from '../FormElements';
import { LocationDropDownViewProps } from './LocationDropDown.type';
import {
    Box,
    Checkbox, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LocationDropDownContainer, LocationDropDownFilter } from './LocationDropDown.style';
import * as style from '../OrderList/OrderList.style';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../states/global/Settings';
import { StructThemeType } from '../../styled/theme.type';
import { useRecoilValue } from 'recoil';

// import module

export const LocationDropDownContent: React.FC<LocationDropDownViewProps> = ({
    value,
    error,
    locationList,
    onChange,
    required,
    name,
    label,
    loadMoreLocation,
    xl,
    lg,
    md,
    sm,
    setShowFilter,
    showFilter,
    locationType,
    selectedLocationType,
    handleSetSelectedLocationType
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const classes = style.useStyles();
    const theme = useRecoilValue<StructThemeType>(Theme);

    return (
        <Grid xl={xl} lg={lg} md={md} sm={sm} xs={12} className='textFieldFormik'>
            <LocationDropDownContainer>

                <Grid container>
                    <Grid item lg={11} md={11} sm={11} xs={11} sx={{ display: 'grid' }}>
                        {!showFilter ? <SimpleSelectAsyncPaginate
                            name={name}
                            label={label}
                            value={value}
                            error={error}
                            options={locationList}
                            onChange={onChange}
                            required={required}
                            loadOptions={loadMoreLocation}
                            textRequired={translate('p.this_field_is_required')}
                            xl={xl}
                            lg={lg}
                            md={md}
                            sm={sm}
                        /> : <></>}
                        {showFilter ? (<FormControl sx={{ m: 0, width: '100%' }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{
                                    backgroundColor: theme.name === 'Dark' ? '#32383f' : 'white', padding: '0 5px 0 0'
                                }}
                            >
                                {translate('t.filter')}
                            </InputLabel>
                            <Select
                                className={classes.inputFilterColumn}
                                multiple
                                value={selectedLocationType}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        handleSetSelectedLocationType(e.target.value as number[]);
                                    }
                                }}
                                input={<OutlinedInput label={translate('t.filter')} />}
                                renderValue={(selected: any) => {
                                    return locationType.filter((type) =>
                                        selected.includes(type.id))
                                        .map((status) => status.name).join(', ');
                                }}
                            >
                                {locationType.map((status) => (
                                    <MenuItem key={status.id} value={status.id} style={{ padding: '0px' }}>
                                        <Checkbox
                                            checked={
                                                selectedLocationType.filter((type) => type === status.id).length > 0
                                            }
                                        />
                                        <ListItemText primary={
                                            <Box>
                                                {status.name}
                                            </Box>
                                        } />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>) : <></>}
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <LocationDropDownFilter>
                            {<IconButton aria-label='filter-location' size='small' onClick={() => {
                                setShowFilter((current) => !current);
                            }}>
                                <FilterListIcon fontSize='small' />
                            </IconButton>}
                        </LocationDropDownFilter>
                    </Grid>
                </Grid>
            </LocationDropDownContainer>
        </Grid>
    );
};
