import React from 'react';
import * as style from './ShipmentAcceptanceDone.style';
import * as type from './ShipmentAcceptanceDone.type';
import Thank from '../../assets/images/check.gif';
import { useTranslation } from 'react-i18next';
// import module

const ShipmentAcceptanceDoneContent: React.FC<type.ShipmentAcceptanceDoneProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <style.ShipmentAcceptanceDoneContent data-testid='ShipmentAcceptanceDoneContent'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div className='gif_error404'>
                    <img src={Thank} alt=' Error 404' width='150px' />
                </div>
                <h3 className='text_thx'>{translate('p.thank_you_for_accepting_the_shipping_order')}</h3>
            </div>
            <style.ShippingOrderAcceptancebody />
        </style.ShipmentAcceptanceDoneContent>
    );
};

export default ShipmentAcceptanceDoneContent;
