import React, { useEffect, useState } from 'react';
import { FilterList } from '@mui/icons-material';
import { ApplySaveButton, FilterColumnCover, FilterColumnsContent } from './FilterColumns.style';
import * as type from './FilterColumns.type';
import Wrapper from '../../../../../helpers/wrapper';
import Legend from '../../../UiLegend';
import { Button, IconButton } from '@mui/material';
import { Errors } from '../../../../../components/Popup/Popup';
import { getLSUser, setToLSUser } from '../../../../../helpers/localStorage';
import { InitialStateType } from '../../UiTable.type';
import { useTranslation } from 'react-i18next';

const FilterColumns: React.FC<type.FilterColumnsProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [close, setClose] = useState<boolean>(false);
    const instanceTableState: InitialStateType | Record<string, unknown> =
        getLSUser<InitialStateType>(`table.${props?.table?.title}Table`) || ({} as Record<string, unknown>);

    useEffect(() => {
        if (close) {
            setClose(false);
        }
    }, [close]);

    const validateValues = (typeAction: 'apply' | 'apply_save' = 'apply') => {
        let isError = false;
        props.table.state.filters.map((inputFilterColumn) => {
            if (inputFilterColumn.value.value == 'Invalid date') {
                isError = true;
                props.table.setFilter(inputFilterColumn.id, undefined);
                Errors({ text: translate('t.the_date_format_is_invalid') as string });
            }
        });
        if (!isError) {
            if (typeAction === 'apply_save') {
                props.table?.title &&
                    setToLSUser(`table.${props.table?.title}Table`, {
                        ...instanceTableState,
                        filters: props.table.state.filters
                    });
            }
            props.setCurrentFilters(props.table.state.filters);
        }
    };

    const resetValues = () => {
        validateValues();
        props.table.setAllFilters([]);
        props.setCurrentFilters([]);
        props.table?.title &&
            setToLSUser(`table.${props.table?.title}Table`, {
                ...instanceTableState,
                filters: []
            });
        setClose(true);
    };

    return (
        <FilterColumnsContent data-testid='FilterColumnsContent'>
            <Legend
                icon={<FilterList />}
                root={
                    <IconButton>
                        <FilterList />
                    </IconButton>
                }
                width={300}
                close={close}
                headerElements={
                    <>
                        <Button
                            variant='contained'
                            color='primary'
                            type='button'
                            onClick={() => {
                                validateValues();
                                setClose(true);
                            }}
                        >
                            {translate('t.apply')}
                        </Button>{' '}
                        <ApplySaveButton
                            variant='contained'
                            color='primary'
                            type='button'
                            onClick={() => {
                                validateValues('apply_save');
                                setClose(true);
                            }}
                        >
                            {translate('t.apply_save')}
                        </ApplySaveButton>{' '}
                        <Button
                            size='small'
                            variant='contained'
                            onClick={() => {
                                resetValues();
                            }}
                            color='secondary'
                        >
                            {' '}
                            {translate('t.reset')}
                        </Button>
                    </>
                }
                onClose={() => {
                    props.table.setAllFilters(props.currentFilters);
                    setClose(false);
                }}
            >
                <div>
                    <FilterColumnCover>
                        {props.allColumns
                            .filter((column) => column.canFilter)
                            .map((column, index) => (
                                <React.Fragment key={index}>{column.render('Filter')}</React.Fragment>
                            ))}
                    </FilterColumnCover>
                </div>
            </Legend>
        </FilterColumnsContent>
    );
};

export default Wrapper(FilterColumns);
