/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { AccordionCustomer, UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import {
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { SiteSurveyStepFiveForm, StepFiveViewProps } from './StepFive.type';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AutocompleteElement, InputElement } from '../../FormElements';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { SurveyFieldRequiresState, SurveyShemaNameValidState } from '../../../states/component/Survey';
import { TimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { FieldsetCustom, StepFiveLegend } from './StepFive.style';
import ButtonsActionSiteSurvey from '../ButtonsActionSiteSurvey/ButtonsActionSiteSurvey';
// import module

export const StepFiveContent: React.FC<StepFiveViewProps> = ({
    surveyData,
    valuesInitForm,
    activeStep,
    optionsSelect,
    optionsSelectLanguage,
    publicMode,
    bottonHiddenUpdate,
    handleBack,
    handleNext,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyFieldRequires = useRecoilValue(SurveyFieldRequiresState);
    const [surveyShemaNameValidState, setSurveyShemaNameValidState] = useRecoilState(SurveyShemaNameValidState);

    const validationSchema = Yup.object().shape({
        language: Yup.string(),
        pressureUnit: Yup.string(),
        languageOther: Yup.string(),
        temperatureUnitLevel1: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        temperatureUnitLevel2: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        temperatureUnitLevel3: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        recommendedColdPressure: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        lowPressureLimitLevel1: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        lowPressureLimitLevel2: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        lowPressureLimitLevel3: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        highPressureLimitLevel1: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        highPressureLimitLevel2: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        highPressureLimitLevel3: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        temperatureLimits: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        maxSpeedLimit: Yup.number().positive(translate('p.positive_number') as string).nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            }),
        speedUnit: Yup.string(),
        shiftChangeStartDay: Yup.string()
            .nullable()
            .test(
                'is-yyyy-mm-dd-valid',
                translate('t.invalid_date') ?? '',
                (value) => {
                    if (!value) {
                        return true;
                    }
                    return moment(value, 'YYYY-MM-DD', true).isValid();
                }
            ),
        shiftStartTime: Yup.string(),
        shiftEndTime: Yup.string(),
        lunchtimeDayStart: Yup.string(),
        lunchtimeDayEnd: Yup.string(),
        lunchtimeNightStart: Yup.string(),
        lunchtimeNightEnd: Yup.string(),
        shiftName: Yup.string(),
        shiftStart: Yup.string()
            .nullable()
            .test(
                'is-yyyy-mm-dd-valid',
                translate('t.invalid_date') ?? '',
                (value) => {
                    if (!value) {
                        return true;
                    }
                    return moment(value, 'YYYY-MM-DD', true).isValid();
                }
            ),
        shiftEnd: Yup.string()
            .nullable()
            .test(
                'is-yyyy-mm-dd-valid',
                translate('t.invalid_date') ?? '',
                (value) => {
                    if (!value) {
                        return true;
                    }
                    return moment(value, 'YYYY-MM-DD', true).isValid();
                }
            ),
        // closestCity: Yup.string(),
        // dateOfIntalation: Yup.string(),
        altitude: Yup.string(),
        temperatureUnit: Yup.string(),
        schemaName: Yup.string()
    });

    const initialErrors = useMemo(() => {
        const validate = Object.keys(surveyFieldRequires.stepFive.fileds).reduce((acc, key) => {
            if (!surveyFieldRequires.stepFive.fileds[key].valid && !valuesInitForm[key]) {
                acc[key] = translate('p.this_field_is_required');
            }
            return acc;
        }, {});

        if(!surveyShemaNameValidState) {
            validate['schemaName'] = translate('p.this_field_is_required');
        }

        return validate;
    }, [surveyFieldRequires.stepFive.fileds]);

    const checkRadios = (values: SiteSurveyStepFiveForm, errors: FormikErrors<SiteSurveyStepFiveForm>) => {
        if (values.pressureUnit) {
            delete errors['pressureUnit'];
        }

        if (values.temperatureUnit) {
            delete errors['temperatureUnit'];
        }

        if (values.speedUnit) {
            delete errors['speedUnit'];
        }

        return errors;
    };

    return (
        <Formik
            initialValues={valuesInitForm}
            initialErrors={initialErrors}
            onSubmit={(values: SiteSurveyStepFiveForm, actions) => {
                handleSubmit({
                    ...values,
                    language: values.language == 'other' ? values.languageOther : values.language
                }, 5, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
            validate={() => {
                return initialErrors;
            }}
            validateOnBlur={true}
        >
            {(propsF: FormikProps<SiteSurveyStepFiveForm>) => {
                const {
                    touched,
                    errors,
                    setFieldError,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm,
                    resetForm,
                    setSubmitting,
                    setTouched
                } = propsF;
                const hasErrors = Object.keys(errors).filter(key => key !== 'schemaName').length > 0;
                if(!publicMode && !!values.schemaName && !surveyShemaNameValidState) {
                    setSurveyShemaNameValidState(true);
                }
                Object.keys(touched).forEach((key) => {
                    try {
                        validationSchema.validateSyncAt(key, values);
                        if (values[key]) {
                            if(key === 'language' || key === 'languageOther') {
                                if(values['language'] === 'other' && values['languageOther'] === '' ) {
                                    delete errors['language'];
                                    if(!surveyFieldRequires.stepFive.fileds['language'].valid) {
                                        errors['languageOther'] = translate('p.this_field_is_required') ?? '';
                                    }
                                }

                                if(values['language'] === 'other' && values['languageOther'] !== '' ) {
                                    delete errors['language'];
                                    delete errors['languageOther'];
                                }

                                if(values['language'] !== 'other' && values['language'] !== '') {
                                    delete errors['language'];
                                    delete errors['languageOther'];
                                }
                            }else{
                                delete errors[key];
                            }
                        }
                    } catch (validationError: any) {
                        errors[key] = validationError.errors[0];
                    }
                });
                checkRadios(values, errors);
                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                                {/* TIME DATA */}
                                <Grid item md={7}>
                                    <AccordionCustomer defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant='subtitle1'>
                                                {translate('t.time_data')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <InputElement
                                                    value={values.shiftName}
                                                    error={errors.shiftName}
                                                    touched={values.shiftName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    label={translate('t.shift_name')}
                                                    name='shiftName'
                                                    fullWidth
                                                    $noMinWidth
                                                    lg={4}
                                                    md={6}
                                                    sm={12}
                                                />
                                                <Grid item lg={4} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <DatePicker
                                                            label={
                                                                <span className='fieldRequired'>
                                                                    <i>*</i> {translate('t.shift_start')}
                                                                </span>
                                                            }
                                                            disableFuture={false}
                                                            value={values.shiftStart}
                                                            disablePast={true}
                                                            onChange={(newValue) => {
                                                                const newValueV = newValue ?
                                                                    moment(newValue, 'YYYY-MM-DD')
                                                                        .format('YYYY-MM-DD')
                                                                    : '';

                                                                setFieldValue(
                                                                    'shiftStart',
                                                                    newValueV === 'Invalid date'
                                                                        ? ''
                                                                        : newValueV
                                                                );

                                                                setTouched({ ...touched, 'shiftStart': true });
                                                            }}
                                                            mask='____-__-__'
                                                            inputFormat='YYYY-MM-DD'
                                                            views={['year', 'month', 'day']}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='shiftStart'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.shiftStart
                                                                                ? errors.shiftStart
                                                                                : errors.shiftStart
                                                                            : ''
                                                                    }
                                                                    error={!!errors.shiftStart}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item lg={4} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <DatePicker
                                                            label={
                                                                <span className='fieldRequired'>
                                                                    <i>*</i> {translate('t.shift_end')}
                                                                </span>
                                                            }
                                                            disableFuture={false}
                                                            value={values.shiftEnd}
                                                            disablePast={true}
                                                            onChange={(newValue) => {
                                                                const newValueV = newValue ?
                                                                    moment(newValue, 'YYYY-MM-DD')
                                                                        .format('YYYY-MM-DD')
                                                                    : '';

                                                                setFieldValue(
                                                                    'shiftEnd',
                                                                    newValueV === 'Invalid date'
                                                                        ? ''
                                                                        : newValueV
                                                                );

                                                                setTouched({ ...touched, 'shiftEnd': true });
                                                            }}
                                                            mask='____-__-__'
                                                            inputFormat='YYYY-MM-DD'
                                                            views={['year', 'month', 'day']}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='shiftEnd'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.shiftEnd
                                                                                ? errors.shiftEnd
                                                                                : errors.shiftEnd
                                                                            : ''
                                                                    }
                                                                    error={!!errors.shiftEnd}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <TimePicker
                                                            label={translate('t.shift_start_time')}
                                                            value={values.shiftStartTime
                                                                // eslint-disable-next-line max-len
                                                                ? moment(`${moment().format('YYYY-MM-DD')}T${values.shiftStartTime}`)
                                                                : values.shiftStartTime
                                                            }
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'shiftStartTime',
                                                                    newValue
                                                                        ? newValue.format('HH:mm')
                                                                        : ''
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='shiftStartTime'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.shiftStartTime
                                                                                ? errors.shiftStartTime
                                                                                : errors.shiftStartTime
                                                                            : ''
                                                                    }
                                                                    error={!!errors.shiftStartTime}
                                                                    onKeyDown={(event) => {
                                                                        if (
                                                                            event.key === 'Delete' ||
                                                                            event.key === 'Backspace'
                                                                        ) {
                                                                            setFieldValue('shiftStartTime', '');
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <TimePicker
                                                            label={translate('t.shift_end_time')}
                                                            value={values.shiftEndTime
                                                                // eslint-disable-next-line max-len
                                                                ? moment(`${moment().format('YYYY-MM-DD')}T${values.shiftEndTime}`)
                                                                : values.shiftEndTime
                                                            }
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'shiftEndTime',
                                                                    newValue
                                                                        ? newValue.format('HH:mm')
                                                                        : ''
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='shiftEndTime'
                                                                    fullWidth
                                                                    size='small'
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.shiftEndTime
                                                                                ? errors.shiftEndTime
                                                                                : errors.shiftEndTime
                                                                            : ''
                                                                    }
                                                                    error={!!errors.shiftEndTime}
                                                                    onKeyDown={(event) => {
                                                                        if (
                                                                            event.key === 'Delete' ||
                                                                            event.key === 'Backspace'
                                                                        ) {
                                                                            setFieldValue('shiftEndTime', '');
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <DatePicker
                                                            label={
                                                                <span className='fieldRequired'>
                                                                    {translate('t.shift_change_start_day')}
                                                                </span>
                                                            }
                                                            disableFuture={false}
                                                            value={values.shiftChangeStartDay}
                                                            disablePast={true}
                                                            onChange={(newValue) => {
                                                                const newValueV = newValue ?
                                                                    moment(newValue, 'YYYY-MM-DD')
                                                                        .format('YYYY-MM-DD')
                                                                    : '';

                                                                setFieldValue(
                                                                    'shiftChangeStartDay',
                                                                    newValueV === 'Invalid date'
                                                                        ? ''
                                                                        : newValueV
                                                                );

                                                                setTouched({ ...touched, 'shiftChangeStartDay': true });
                                                            }}
                                                            mask='____-__-__'
                                                            inputFormat='YYYY-MM-DD'
                                                            views={['year', 'month', 'day']}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='shiftChangeStartDay'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.shiftChangeStartDay
                                                                                ? errors.shiftChangeStartDay
                                                                                : errors.shiftChangeStartDay
                                                                            : ''
                                                                    }
                                                                    error={!!errors.shiftChangeStartDay}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <TimePicker
                                                            label={translate('t.lunchtime_day_start')}
                                                            value={values.lunchtimeDayStart
                                                                // eslint-disable-next-line max-len
                                                                ? moment(`${moment().format('YYYY-MM-DD')}T${values.lunchtimeDayStart}`)
                                                                : values.lunchtimeDayStart
                                                            }
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'lunchtimeDayStart',
                                                                    newValue
                                                                        ? newValue.format('HH:mm')
                                                                        : ''
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='lunchtimeDayStart'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.lunchtimeDayStart
                                                                                ? errors.lunchtimeDayStart
                                                                                : errors.lunchtimeDayStart
                                                                            : ''
                                                                    }
                                                                    error={!!errors.lunchtimeDayStart}
                                                                    onKeyDown={(event) => {
                                                                        if (
                                                                            event.key === 'Delete' ||
                                                                            event.key === 'Backspace'
                                                                        ) {
                                                                            setFieldValue('lunchtimeDayStart', '');
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <TimePicker
                                                            label={translate('t.lunchtime_day_end')}
                                                            value={values.lunchtimeDayEnd
                                                                // eslint-disable-next-line max-len
                                                                ? moment(`${moment().format('YYYY-MM-DD')}T${values.lunchtimeDayEnd}`)
                                                                : values.lunchtimeDayEnd
                                                            }
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'lunchtimeDayEnd',
                                                                    newValue
                                                                        ? newValue.format('HH:mm')
                                                                        : ''
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='lunchtimeDayEnd'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.lunchtimeDayEnd
                                                                                ? errors.lunchtimeDayEnd
                                                                                : errors.lunchtimeDayEnd
                                                                            : ''
                                                                    }
                                                                    error={!!errors.lunchtimeDayEnd}
                                                                    onKeyDown={(event) => {
                                                                        if (
                                                                            event.key === 'Delete' ||
                                                                            event.key === 'Backspace'
                                                                        ) {
                                                                            setFieldValue('lunchtimeDayEnd', '');
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <TimePicker
                                                            label={translate('t.lunchtime_night_start')}
                                                            value={values.lunchtimeNightStart
                                                                // eslint-disable-next-line max-len
                                                                ? moment(`${moment().format('YYYY-MM-DD')}T${values.lunchtimeNightStart}`)
                                                                : values.lunchtimeNightStart
                                                            }
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'lunchtimeNightStart',
                                                                    newValue
                                                                        ? newValue.format('HH:mm')
                                                                        : ''
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='lunchtimeNightStart'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.lunchtimeNightStart
                                                                                ? errors.lunchtimeNightStart
                                                                                : errors.lunchtimeNightStart
                                                                            : ''
                                                                    }
                                                                    error={!!errors.lunchtimeNightStart}
                                                                    onKeyDown={(event) => {
                                                                        if (
                                                                            event.key === 'Delete' ||
                                                                            event.key === 'Backspace'
                                                                        ) {
                                                                            setFieldValue('lunchtimeNightStart', '');
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xl={4} lg={6} md={6} sm={12} >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <TimePicker
                                                            label={translate('t.lunchtime_night_end')}
                                                            value={values.lunchtimeNightEnd
                                                                // eslint-disable-next-line max-len
                                                                ? moment(`${moment().format('YYYY-MM-DD')}T${values.lunchtimeNightEnd}`)
                                                                : values.lunchtimeNightEnd
                                                            }
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'lunchtimeNightEnd',
                                                                    newValue
                                                                        ? newValue.format('HH:mm')
                                                                        : ''
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='lunchtimeNightEnd'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.lunchtimeNightEnd
                                                                                ? errors.lunchtimeNightEnd
                                                                                : errors.lunchtimeNightEnd
                                                                            : ''
                                                                    }
                                                                    error={!!errors.lunchtimeNightEnd}
                                                                    onKeyDown={(event) => {
                                                                        if (
                                                                            event.key === 'Delete' ||
                                                                            event.key === 'Backspace'
                                                                        ) {
                                                                            setFieldValue('lunchtimeNightEnd', '');
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionCustomer>
                                </Grid>

                                {/* GENERAL INFO */}
                                <Grid item md={5}>
                                    <AccordionCustomer defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant='subtitle1'>
                                                {translate('t.general_info')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <AutocompleteElement
                                                    label={translate('t.language')}
                                                    name='language'
                                                    size='small'
                                                    options={optionsSelectLanguage ?? []}
                                                    value={values.language}
                                                    defaultValue={optionsSelectLanguage.find(
                                                        option => option.value === values.language
                                                    )}
                                                    error={errors.language}
                                                    touched={touched.language}
                                                    onBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    optionsPropertyLabel='label'
                                                    optionsPropertyValue='value'
                                                    required={true}
                                                    $noMinWidth
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                />
                                                <InputElement
                                                    label={translate('t.languageOther')}
                                                    name='languageOther'
                                                    value={values.language === 'other' ? values.languageOther : ''}
                                                    error={errors.languageOther}
                                                    touched={touched.languageOther}
                                                    disabled={values.language !== 'other'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={false}
                                                    fullWidth
                                                    $noMinWidth
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                />
                                                {!publicMode && (
                                                    <InputElement
                                                        label={translate('t.schema_name')}
                                                        name='schemaName'
                                                        value={values.schemaName}
                                                        error={errors.schemaName}
                                                        touched={touched.schemaName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={
                                                            !publicMode &&
                                                            (
                                                                surveyData?.status === 'in_acceptance' ||
                                                                surveyData?.status === 'accepted'
                                                            )
                                                        }
                                                        disabled={surveyData?.status === 'accepted'}
                                                        fullWidth
                                                        $noMinWidth
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    />
                                                )}
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionCustomer>
                                </Grid>

                                {/* UNIT DATA */}
                                <Grid item md={12}>
                                    <AccordionCustomer defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant='subtitle1'>
                                                {translate('t.unit_data')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <AutocompleteElement
                                                    label={translate('t.altitude')}
                                                    name='altitude'
                                                    size='small'
                                                    options={optionsSelect ?? []}
                                                    value={values.altitude}
                                                    defaultValue={optionsSelect.find(
                                                        option => option.value === values.altitude
                                                    )}
                                                    error={errors.altitude}
                                                    touched={touched.altitude}
                                                    onBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    optionsPropertyLabel='label'
                                                    optionsPropertyValue='value'
                                                    required={true}
                                                    lg={4}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                />
                                                <Grid container sx={{ mb: 2 }}>
                                                    <Grid item lg={5} md={6} >
                                                        <FieldsetCustom>
                                                            <StepFiveLegend className='modalLegend'>
                                                                {translate('t.pressure_unit')}
                                                            </StepFiveLegend>
                                                            <FormControl error={!!errors.pressureUnit} >
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                                                    name='pressureUnit'
                                                                    defaultChecked
                                                                    value={values.pressureUnit}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        ...(errors.pressureUnit && {
                                                                            '.MuiRadio-root': {
                                                                                color: '#d32f2f'
                                                                            },
                                                                            '.MuiTypography-root': {
                                                                                color: '#d32f2f'
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value='psi'
                                                                        control={<Radio />} label='PSI' />
                                                                    <FormControlLabel value='kpa'
                                                                        control={<Radio />} label='KPA' />
                                                                    <FormControlLabel value='bar'
                                                                        control={<Radio />} label='BAR' />
                                                                </RadioGroup>
                                                                {errors.pressureUnit && (
                                                                    <FormHelperText>
                                                                        {errors.pressureUnit}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                            <FieldsetCustom
                                                                style={{
                                                                    marginTop: '10px',
                                                                    marginBottom: '10px'
                                                                }}
                                                            >
                                                                <StepFiveLegend className='modalLegend'>
                                                                    {`${translate('p.recommended_low_cold_pressure')} (%)`}
                                                                </StepFiveLegend>
                                                                <Grid container >
                                                                    <InputElement
                                                                        value={values.lowPressureLimitLevel1}
                                                                        error={errors.lowPressureLimitLevel1}
                                                                        touched={values.lowPressureLimitLevel1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        label={`${translate('t.level')} 1`}
                                                                        name='lowPressureLimitLevel1'
                                                                        type='number'
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        $noMinWidth
                                                                    />
                                                                    <InputElement
                                                                        value={values.lowPressureLimitLevel2}
                                                                        error={errors.lowPressureLimitLevel2}
                                                                        touched={values.lowPressureLimitLevel2}
                                                                        onChange={handleChange}
                                                                        required={true}
                                                                        onBlur={handleBlur}
                                                                        label={`${translate('t.level')} 2`}
                                                                        name='lowPressureLimitLevel2'
                                                                        type='number'
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        $noMinWidth
                                                                    />
                                                                    <InputElement
                                                                        value={values.lowPressureLimitLevel3}
                                                                        error={errors.lowPressureLimitLevel3}
                                                                        touched={values.lowPressureLimitLevel3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        label={`${translate('t.level')} 3`}
                                                                        name='lowPressureLimitLevel3'
                                                                        type='number'
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        $noMinWidth
                                                                    />
                                                                </Grid>
                                                            </FieldsetCustom>
                                                            <FieldsetCustom>
                                                                <StepFiveLegend className='modalLegend'>
                                                                    {`${translate('p.recommended_high_cold_pressure')} (%)`}
                                                                </StepFiveLegend>
                                                                <Grid container >
                                                                    <InputElement
                                                                        value={values.highPressureLimitLevel1}
                                                                        error={errors.highPressureLimitLevel1}
                                                                        touched={values.highPressureLimitLevel1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        label={`${translate('t.level')} 1`}
                                                                        name='highPressureLimitLevel1'
                                                                        type='number'
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        $noMinWidth
                                                                    />
                                                                    <InputElement
                                                                        value={values.highPressureLimitLevel2}
                                                                        error={errors.highPressureLimitLevel2}
                                                                        touched={values.highPressureLimitLevel2}
                                                                        onChange={handleChange}
                                                                        required={true}
                                                                        onBlur={handleBlur}
                                                                        label={`${translate('t.level')} 2`}
                                                                        name='highPressureLimitLevel2'
                                                                        type='number'
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        $noMinWidth
                                                                    />
                                                                    <InputElement
                                                                        value={values.highPressureLimitLevel3}
                                                                        error={errors.highPressureLimitLevel3}
                                                                        touched={values.highPressureLimitLevel3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        label={`${translate('t.level')} 3`}
                                                                        name='highPressureLimitLevel3'
                                                                        type='number'
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={6}
                                                                        $noMinWidth
                                                                    />
                                                                </Grid>
                                                            </FieldsetCustom>
                                                        </FieldsetCustom>
                                                    </Grid>
                                                    <Grid item lg={4} md={6} >
                                                        <FieldsetCustom>
                                                            <StepFiveLegend className='modalLegend'>
                                                                {translate('t.temperature_unit')}
                                                            </StepFiveLegend>

                                                            <FormControl error={!!errors.temperatureUnit} >
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby='radio-buttons-group-label'
                                                                    name='temperatureUnit'
                                                                    defaultChecked
                                                                    value={values.temperatureUnit}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        ...(errors.temperatureUnit && {
                                                                            '.MuiRadio-root': {
                                                                                color: '#d32f2f'
                                                                            },
                                                                            '.MuiTypography-root': {
                                                                                color: '#d32f2f'
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value='°C'
                                                                        control={<Radio />} label='°C' />
                                                                    <FormControlLabel value='°F'
                                                                        control={<Radio />} label='°F' />
                                                                </RadioGroup>
                                                                {errors.temperatureUnit && (
                                                                    <FormHelperText>
                                                                        {errors.temperatureUnit}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                            <Grid container>
                                                                <InputElement
                                                                    value={values.temperatureUnitLevel1}
                                                                    error={errors.temperatureUnitLevel1}
                                                                    touched={values.temperatureUnitLevel1}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                    label={`${translate('t.level')} 1`}
                                                                    name='temperatureUnitLevel1'
                                                                    type='number'
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    $noMinWidth
                                                                />
                                                                <InputElement
                                                                    value={values.temperatureUnitLevel2}
                                                                    error={errors.temperatureUnitLevel2}
                                                                    touched={values.temperatureUnitLevel2}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                    label={`${translate('t.level')} 2`}
                                                                    name='temperatureUnitLevel2'
                                                                    type='number'
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    $noMinWidth
                                                                />
                                                                <InputElement
                                                                    value={values.temperatureUnitLevel3}
                                                                    error={errors.temperatureUnitLevel3}
                                                                    touched={values.temperatureUnitLevel3}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                    label={`${translate('t.level')} 3`}
                                                                    name='temperatureUnitLevel3'
                                                                    type='number'
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                    $noMinWidth
                                                                />
                                                                <InputElement
                                                                    value={values.temperatureLimits}
                                                                    error={errors.temperatureLimits}
                                                                    touched={values.temperatureLimits}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                    label={translate('t.temperature_limits')}
                                                                    name='temperatureLimits'
                                                                    type='number'
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={6}
                                                                />
                                                            </Grid>
                                                        </FieldsetCustom>
                                                    </Grid>
                                                    <Grid item lg={3} md={6} >
                                                        <FieldsetCustom>
                                                            <StepFiveLegend className='modalLegend'>
                                                                {translate('t.speed_unit')}
                                                            </StepFiveLegend>

                                                            <FormControl error={!!errors.speedUnit} >
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby='group-label'
                                                                    name='speedUnit'
                                                                    defaultChecked
                                                                    value={values.speedUnit}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        ...(errors.speedUnit && {
                                                                            '.MuiRadio-root': {
                                                                                color: '#d32f2f'
                                                                            },
                                                                            '.MuiTypography-root': {
                                                                                color: '#d32f2f'
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value='KPH'
                                                                        control={<Radio />} label='KPH' />
                                                                    <FormControlLabel value='MPH'
                                                                        control={<Radio />} label='MPH' />
                                                                </RadioGroup>
                                                                {errors.speedUnit && (
                                                                    <FormHelperText>
                                                                        {errors.speedUnit}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                            <Grid container>
                                                                <InputElement
                                                                    value={values.maxSpeedLimit}
                                                                    error={errors.maxSpeedLimit}
                                                                    touched={values.maxSpeedLimit}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                    label={translate('t.max_speed_limit')}
                                                                    name='maxSpeedLimit'
                                                                    type='number'
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={6}
                                                                />
                                                            </Grid>
                                                        </FieldsetCustom>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionCustomer>
                                </Grid>
                            </Grid>
                        </UiScrollbarsSiteSurvey>
                        <ButtonsActionSiteSurvey
                            activeStep={activeStep}
                            values={{
                                ...values,
                                language: values.language == 'other' ? values.languageOther : values.language
                            }}
                            publicMode={publicMode}
                            isSubmitting={isSubmitting}
                            errors={hasErrors}
                            disabledAcceptButton={Object.keys(errors).length > 0}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            submitForm={submitForm}
                            handleSubmit={handleSubmit}
                            resetForm={resetForm}
                            setSubmitting={setSubmitting}
                            setModalReturnToProgress={setModalReturnToProgress}
                            setFieldError={setFieldError}
                        />
                        <Button
                            onClick={() => updateSurveyTemporal({
                                ...values,
                                language: values.language == 'other' ? values.languageOther : values.language
                            })}
                            sx={{ display: 'none' }}
                            variant='contained'
                            size='small'
                            ref={bottonHiddenUpdate}
                        >
                        </Button>
                    </>
                );
            }}
        </Formik >
    );
};