import { ListItem } from '@mui/material';
import styled from 'styled-components';
import { ThemeType } from '../../../styled/theme.type';

export const SurveyValidationContainer = styled.div`
    padding: 10px 0px 0px 0px;
`;

export const ListItemValidationCustom = styled(ListItem)<{ isActive: boolean }>`
    border-radius: 4px !important;
    margin-top: 0px;
    background-color: ${({ theme }: ThemeType) => {
        return theme.name == 'Dark' ? theme.colors.card.background : theme.colors.cardLight;
    }} !important;
`;