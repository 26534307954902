import React from 'react';
import Wrapper from '../../../helpers/wrapper';
import { SiteSurveyStepFiveForm, StepFiveProps } from './StepFive.type';
import { StepFiveContent } from './StepFive.view';
import { checkSurvey } from '../../../helpers';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { OptionsSelectsType } from '../SiteSurvey.type';
import { findMatchingValue } from '../SurveyHelpers';
// import module

const StepFive: React.FC<StepFiveProps> = ({
    activeStep,
    publicMode,
    bottonHiddenUpdate,
    handleNext,
    handleBack,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const surveyData  = useRecoilValue(SurveyState);
    const language = checkSurvey(surveyData, 'language');
    const altitude = checkSurvey(surveyData, 'altitude');

    const optionsSelect: OptionsSelectsType[] = [
        { value: 'Meters', label: 'Meters' },
        { value: 'Feet', label: 'Feet' }
    ];

    const optionsSelectLanguage: OptionsSelectsType[] = [
        { value: 'english', label: 'English' },
        { value: 'spanish', label: 'Spanish' },
        { value: 'other', label: 'Other' }
    ];

    const dataStepFive: SiteSurveyStepFiveForm = {
        language: language ? findMatchingValue(language, optionsSelectLanguage) ?? 'other' : '',
        languageOther: language ? !findMatchingValue(language, optionsSelectLanguage) ? language : '' : '',
        pressureUnit: checkSurvey(surveyData, 'pressureUnit'),
        lowPressureLimitLevel1: checkSurvey(surveyData, 'lowPressureLimitLevel1'),
        lowPressureLimitLevel2: checkSurvey(surveyData, 'lowPressureLimitLevel2'),
        lowPressureLimitLevel3: checkSurvey(surveyData, 'lowPressureLimitLevel3'),
        highPressureLimitLevel1: checkSurvey(surveyData, 'highPressureLimitLevel1'),
        highPressureLimitLevel2: checkSurvey(surveyData, 'highPressureLimitLevel2'),
        highPressureLimitLevel3: checkSurvey(surveyData, 'highPressureLimitLevel3'),
        temperatureUnit: checkSurvey(surveyData, 'temperatureUnit'),
        temperatureUnitLevel1: checkSurvey(surveyData, 'temperatureUnitLevel1'),
        temperatureUnitLevel2: checkSurvey(surveyData, 'temperatureUnitLevel2'),
        temperatureUnitLevel3: checkSurvey(surveyData, 'temperatureUnitLevel3'),
        temperatureLimits: checkSurvey(surveyData, 'temperatureLimits'),
        speedUnit: checkSurvey(surveyData, 'speedUnit'),
        maxSpeedLimit: checkSurvey(surveyData, 'maxSpeedLimit'),
        shiftChangeStartDay: checkSurvey(surveyData, 'shiftChangeStartDay') ?? '',
        shiftStartTime: checkSurvey(surveyData, 'shiftStartTime') ?? '',
        shiftEndTime: checkSurvey(surveyData, 'shiftEndTime') ?? '',
        lunchtimeDayStart: checkSurvey(surveyData, 'lunchtimeDayStart') ?? '',
        lunchtimeDayEnd: checkSurvey(surveyData, 'lunchtimeDayEnd') ?? '',
        lunchtimeNightStart: checkSurvey(surveyData, 'lunchtimeNightStart') ?? '',
        lunchtimeNightEnd: checkSurvey(surveyData, 'lunchtimeNightEnd') ?? '',
        shiftName: checkSurvey(surveyData, 'shiftName'),
        shiftStart: checkSurvey(surveyData, 'shiftStart') ?? '',
        shiftEnd: checkSurvey(surveyData, 'shiftEnd') ?? '',
        altitude: altitude ? findMatchingValue(altitude, optionsSelect) ?? '' : '',
        schemaName: checkSurvey(surveyData, 'schemaName') ?? ''
    };

    return (
        <StepFiveContent 
            data-testid='StepFive-testid'
            activeStep={activeStep}
            publicMode={publicMode}
            surveyData={surveyData}
            valuesInitForm={dataStepFive}
            optionsSelect={optionsSelect}
            optionsSelectLanguage={optionsSelectLanguage}
            bottonHiddenUpdate={bottonHiddenUpdate}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            updateSurveyTemporal={updateSurveyTemporal}
            setModalReturnToProgress={setModalReturnToProgress}
        />
    );
};

export default Wrapper(StepFive);