import { ShippingOrderStatusIdsEnum } from '../components/CurrentOrdersOutbound/CurrentOrdersOutbound.type';
import { Model } from '../models/ApiShippingOrders.type';
import { SurveyType } from '../models/ApiSurvey.type';

export const resendOrderButtonCanBeDisplayed = (shippingOrder: Model): boolean => {
    let show = false;

    if (shippingOrder.locationTo && shippingOrder.locationTo.reshipmentEnabled) {
        if (
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.IN_DISPUTE ||
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.DELIVERED ||
            shippingOrder.shippingOrderStatus.id === ShippingOrderStatusIdsEnum.PARTIALLY_DELIVERED
        ) {
            show = true;
        }
    }
    return show;
};

export const checkSurvey = (surveyData: SurveyType | null, propertyPath: string): any => {
    if (!surveyData || !surveyData.customerOnboarding || !surveyData.customerOnboarding.data) {
        return undefined;
    }

    const pathParts = propertyPath.split('.');
    let currentValue: any = surveyData.customerOnboarding.data;

    for (const part of pathParts) {
        if (currentValue && typeof currentValue === 'object' && part in currentValue) {
            currentValue = currentValue[part];
        } else {
            return undefined;
        }
    }

    if (typeof currentValue === 'boolean') {
        return currentValue ? 'yes' : 'no';
    }

    return currentValue;
};

// export const compareObjects = <
//     T extends Record<string, any>,
//     K extends Record<string, any>
// >(obj1: T, obj2: K): Partial<Record<keyof T, boolean>> => {
//     const missingProperties: Partial<Record<keyof T, boolean>> = {};

//     for (const key in obj1) {
//         if (!(key in obj2)) {
//             missingProperties[key] = {
//                 ...obj1[key],
//                 valid: false
//             };
//         } else {
//             missingProperties[key] = {
//                 ...obj1[key],
//                 valid: true
//             };
//         }
//     }

//     return missingProperties;
// };

export const compareObjects = <
    T extends Record<string, any>,
    K extends Record<string, any>
>(obj1: T, obj2: K): Partial<Record<keyof T, { valid: boolean; value: any }>> => {
    const missingProperties: Partial<Record<keyof T, { valid: boolean; value: any }>> = {};

    const findPropertyRecursive = (obj: any, key: string): boolean => {
        if (typeof obj !== 'object' || obj === null) {
            return false;
        }
        if (key in obj) {
            return true;
        }
        for (const prop in obj) {
            if (findPropertyRecursive(obj[prop], key)) {
                return true;
            }
        }
        return false;
    };

    for (const key in obj1) {
        const found = findPropertyRecursive(obj2, key);
        missingProperties[key] = {
            ...obj1[key],
            valid: found
            // value: obj1[key]
        };
    }

    return missingProperties;
};

type ValidationObject = {
    [key: string]: {
        keyTrans: string;
        valid: boolean;
    };
}

export const verifyValidation = (obj: ValidationObject): boolean => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key].valid === false) {
                return false;
            }
        }
    }
    return true;
};

export const cleanObject = <T extends Record<string, any>>(object: T): Partial<T> => {
    return Object.keys(object).reduce((newObject, property) => {
        const value = object[property];

        if (typeof value === 'object' && value !== null) {
            const cleanedNestedObject = cleanObject(value);
            if (Object.keys(cleanedNestedObject).length > 0) {
                newObject[property as keyof T] = cleanedNestedObject as any;
            }
        } else if (value !== null && value !== undefined && value !== '') {
            newObject[property as keyof T] = value;
        }

        return newObject;
    }, {} as Partial<T>);
};