import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { verifyPermission } from '../../helpers/Auth';

import {
    CustomerAddressFormik as CustomerAddressFormikType,
    ISignUpForm
} from '../CustomerAddress/CustomerAddress.type';
import { InputElement } from '../FormElements';
import UiPhoneInputNumber from '../Ui/UiPhoneInputNumber/UiPhoneInputNumber';
import { useTranslation } from 'react-i18next';

export const CustomerAddressFormik: React.FC<CustomerAddressFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const canCreateAndEditCustomerAddress = !!verifyPermission(['create_and_edit_customer_address']);

    const Formschema = Yup.object({
        address1: Yup.string()
            .trim()
            .min(5, 'Address is Too Short.')
            .required(translate('p.this_field_is_required') as string),
        address2: Yup.string(),
        country: Yup.string().required(),
        postcode: Yup.string(),
        clientUnid: Yup.string().required(translate('p.this_field_is_required') as string),
        email: Yup.string().required(translate('p.this_field_is_required') as string).email(),
        phoneNumber: Yup.string().required(translate('p.this_field_is_required') as string) || props.isValid
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, handleChange, handleBlur, values, isSubmitting, setFieldValue, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='space-around' direction='row'>
                                <InputElement
                                    disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.email')}
                                    name='email'
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={canCreateAndEditCustomerAddress ? true : false}
                                />
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <UiPhoneInputNumber
                                        disabled={!canCreateAndEditCustomerAddress}
                                        value={values.phoneNumber}
                                        setFieldValue={setFieldValue}
                                        international={true}
                                        className='PhoneInputCustom'
                                        defaultCountry=''
                                        placeholder={translate('t.phone_number')}
                                        name='phoneNumber'
                                        setIsValid={props?.setIsValid || (() => false)}
                                        isValid={props.isValid}
                                        required={canCreateAndEditCustomerAddress ? true : false}
                                        error={errors.phoneNumber}
                                    />
                                </Grid>
                                <InputElement
                                    disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.address1')}
                                    name='address1'
                                    value={values.address1}
                                    error={errors.address1}
                                    touched={touched.address1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={canCreateAndEditCustomerAddress ? true : false}
                                />
                                <InputElement
                                    disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.address2')}
                                    name='address2'
                                    value={values.address2}
                                    error={errors.address2}
                                    touched={touched.address2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <InputElement
                                    disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.postcode')}
                                    name='postcode'
                                    value={values.postcode}
                                    error={errors.postcode}
                                    touched={touched.postcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <InputElement
                                    disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.province')}
                                    name='province'
                                    value={values.province}
                                    error={errors.province}
                                    touched={touched.province}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <InputElement
                                disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.country')}
                                    name='country'
                                    value={values.country}
                                    error={errors.country}
                                    touched={touched.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={canCreateAndEditCustomerAddress ? true : false}
                                />
                                <InputElement
                                    disabled={!canCreateAndEditCustomerAddress}
                                    label={translate('t.client_unid')}
                                    name='clientUnid'
                                    value={values.clientUnid}
                                    error={errors.clientUnid}
                                    touched={touched.clientUnid}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={canCreateAndEditCustomerAddress ? true : false}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
