import React from 'react';
import { SiteSurveyStepThreeForm, StepThreeViewProps } from './StepThree.type';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Button, Divider, Grid } from '@mui/material';
import { AutocompleteElement, InputElement } from '../../FormElements';
import { FleetInformationTable } from '../FleetInformation.table';
import { IUAlert } from '../../../styled/global.style';
import { SurveyFieldRequiresState } from '../../../states/component/Survey';
import { useTranslation } from 'react-i18next';
import ButtonsActionSiteSurvey from '../ButtonsActionSiteSurvey/ButtonsActionSiteSurvey';
// import module

export const StepThreeContent: React.FC<StepThreeViewProps> = ({
    valuesInitForm,
    optionsSelect,
    items,
    showMsg,
    publicMode,
    bottonHiddenUpdate,
    loadingForm,
    activeStep,
    handleBack,
    handleNext,
    handleSubmit,
    handleAdd,
    handleRemoveItems,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyFieldRequires = useRecoilValue(SurveyFieldRequiresState);

    const validationSchema = Yup.object().shape({
        totalQuantity: Yup.number()
        .integer(translate('p.must_integer') as string)
        .positive(translate('p.positive_number') as string).nullable()
        .transform((value, originalValue) => {
            if (originalValue === '') return null;
            return value;
        }),
        quantity: Yup.number()
        .integer(translate('p.must_integer') as string)
        .positive(translate('p.positive_number') as string).nullable()
        .transform((value, originalValue) => {
            if (originalValue === '') return null;
            return value;
        })
    });

    const initialErrors = Object.keys(surveyFieldRequires.stepThree.fileds).reduce((acc, key) => {
        if (!surveyFieldRequires.stepThree.fileds[key].valid && !valuesInitForm[key]) {
            acc[key] = translate('p.this_field_is_required');
        }
        return acc;
    }, {});

    return (
        <Formik
            initialValues={valuesInitForm}
            initialErrors={initialErrors}
            onSubmit={(values: SiteSurveyStepThreeForm, actions) => {
                handleSubmit({
                    vehicles: items,
                    totalQuantity: values.totalQuantity
                }, 3, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
            validate={() => {
                return initialErrors;
            }}
            validateOnBlur={true}
        >
            {(propsF: FormikProps<SiteSurveyStepThreeForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    setValues,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm,
                    resetForm,
                    setSubmitting
                } = propsF;
                Object.keys(touched).forEach((key) => {
                    try {
                        validationSchema.validateSyncAt(key, values);
                        if (values[key]) {
                            delete errors[key];
                        }
                    } catch (validationError: any) {
                        if(validationError.errors && validationError.errors.length) {
                            errors[key] = validationError.errors[0];
                        }
                    }
                });
                if(items.length > 0) {
                    delete errors['vehicles'];
                }
                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            {showMsg && (
                                <IUAlert variant='outlined' severity='warning'>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            )}
                            {items.length === 0 && (
                                <IUAlert sx={{ marginTop: '5px' }} variant='outlined' severity='warning'>
                                    {translate('p.vehicle_to_list')}
                                </IUAlert>
                            )}
                            <Grid container justifyContent='inicial' direction='row' spacing={2} sx={{ mt: '5px' }} >
                                <InputElement
                                    label={`
                                        ${translate('t.fleet_size')} / ${translate('t.number_of_vehicles')}
                                    `}
                                    name='totalQuantity'
                                    value={values.totalQuantity}
                                    error={errors.totalQuantity}
                                    touched={touched.totalQuantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    type='number'
                                    fullWidth
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                />
                                <Grid item md={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='inicial' direction='row' spacing={2} sx={{ mb: 2, mt: 2 }}>
                                <InputElement
                                    label={translate('t.model')}
                                    name='model'
                                    value={values.model}
                                    error={errors.model}
                                    touched={touched.model}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                {loadingForm ? (
                                    <InputElement
                                        label={translate('t.loading')}
                                        name='type'
                                        value=''
                                        error={errors.type}
                                        touched={touched.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled
                                        fullWidth
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                    />
                                ) : (
                                    <AutocompleteElement
                                    label={translate('t.type')}
                                    name='type'
                                    size='small'
                                    options={optionsSelect ?? []}
                                    value={values.type}
                                    defaultValue={optionsSelect.find(
                                        option => option.value === values.type
                                    )}
                                    error={errors.type}
                                    touched={touched.type}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    optionsPropertyLabel='label'
                                    optionsPropertyValue='value'
                                    required={true}
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />
                                )}

                                <InputElement
                                    label={translate('t.quantity')}
                                    name='quantity'
                                    type='number'
                                    value={values.quantity}
                                    error={errors.quantity}
                                    touched={touched.quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={`${translate('t.utilization')} (hrs/mth)`}
                                    name='utilization'
                                    type='number'
                                    value={values.utilization}
                                    error={errors.utilization}
                                    touched={touched.utilization}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={`${translate('t.max_speed')} (km/h)`}
                                    name='maxSpeed'
                                    value={values.maxSpeed}
                                    error={errors.maxSpeed}
                                    touched={touched.maxSpeed}
                                    type='number'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={`${translate('t.tyre_size')} (inch)`}
                                    name='tyreSize'
                                    value={values.tyreSize}
                                    error={errors.tyreSize}
                                    touched={touched.tyreSize}
                                    type='number'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={`${translate('t.front_life')} (hrs)`}
                                    name='frontLife'
                                    type='number'
                                    value={values.frontLife}
                                    error={errors.frontLife}
                                    touched={touched.frontLife}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <InputElement
                                    label={`${translate('t.rear_life')} (hrs)`}
                                    name='rearLife'
                                    type='number'
                                    value={values.rearLife}
                                    error={errors.rearLife}
                                    touched={touched.rearLife}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    fullWidth
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                />

                                <Grid item md={12} sx={{ mt: 1.3, mb: 1.3, ml: 1.5 }}>
                                    <Button 
                                        variant='contained'
                                        onClick={() => {
                                            handleAdd(values, setValues);
                                        }}
                                        disabled={!!errors.quantity}
                                    >
                                        {translate('t.add')}
                                    </Button>
                                </Grid>

                                <Grid item md={12} sx={{ borderBottom: 'thin solid #c0c0c0' }}>
                                    <FleetInformationTable items={items} handleRemoveItems={handleRemoveItems} />
                                </Grid>
                            </Grid>
                        </UiScrollbarsSiteSurvey>
                        <ButtonsActionSiteSurvey
                            activeStep={activeStep}
                            values={{
                                vehicles: items,
                                totalQuantity: values.totalQuantity
                            }}
                            publicMode={publicMode}
                            isSubmitting={isSubmitting}
                            errors={Object.keys(errors).length > 0}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            submitForm={submitForm}
                            handleSubmit={handleSubmit}
                            resetForm={resetForm}
                            setSubmitting={setSubmitting}
                            setModalReturnToProgress={setModalReturnToProgress}
                        />
                        <Button
                            onClick={() => updateSurveyTemporal({
                                totalQuantity: values.totalQuantity,
                                vehicles: items
                            })}
                            sx={{ display: 'none' }}
                            variant='contained'
                            size='small'
                            ref={bottonHiddenUpdate}
                        >
                        </Button>
                    </>
                );
            }}
        </Formik>
    );
};