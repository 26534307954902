import { Box } from '@mui/material';
import styled from 'styled-components';

export const LocationDropDownContainer = styled.span``;

export const LocationDropDownFilter = styled(Box)({
    display: 'table-row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginLeft: '10px'
});
