import { Paper } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './CurrentOrdersInbound.style';
import * as type from './CurrentOrdersInbound.type';
import * as CurrentOrdersState from '../../states/component/CurrentOrders';
import UiTable from '../Ui/UiTable/UiTable';
// import module

export const CurrentOrdersInboundContent: React.FC<type.CurrentOrdersInboundViewProps> = (props): JSX.Element => (
    <style.CurrentOrdersInboundContent data-testid='CurrentOrdersInboundContent'>
        <Paper className='cardPageContainer' elevation={1}>
            <UiTable
                title='currentOrdersInbound'
                data={useRecoilValue(CurrentOrdersState.CurrentOrdersInbound)}
                loading={props.isLoading}
                columns={props.columns}
                extraFiltering={props.extraFiltering}
                total={props.total}
                pageCount={props.pageCount}
                fetchData={({ page, limit, order, filter, extraFiltering }) => {
                    props.fetchData(page + 1, limit, order, filter, extraFiltering);
                }}
                fetchDataExport={props.fetchDataExport}
                defaultSortBy={{
                    column: 'createdAt',
                    desc: true
                }}
                hiddenColumns={['shippedAtFrom', 'shippedAtTo', 'createdAtFrom', 'createdAtTo']}
                forceRefresh={props.refresh}
            />
        </Paper>
    </style.CurrentOrdersInboundContent>
);
