import { Alert, Button, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import styled, { createGlobalStyle } from 'styled-components';
import logo from '../assets/images/isologo_stockcontrol.png';
import logoRedWhite from '../assets/images/logoRedWhite.png';
import { ThemeType } from './theme.type';
import { useTheme } from './useTheme';
import { FontSize } from '../components/Ui/UiTheme';

export const GlobalStyle = createGlobalStyle`
    body{
        background-color: ${({ theme }: ThemeType) => theme.colors.body};
        color: ${({ theme }: ThemeType) => theme.colors.text};
        overflow: hidden;
    }
    .text-center{
        text-align: center;
    }
    .text-right{
        text-align: right;
    }
    .successMessage{
        color: green;
    }
    .errorMessage{
        color: red;
        font-size: 12px;
    }
    .textFieldFormik{
        width: 100%;
        padding: 10px;
        display: inline-grid;
        min-width: 215px;
    }
    .containerFormik{
        display: block;
        margin: 0 auto;
        font-size: 16px !important;
    }
    .MuiIconButton-colorPrimary {
        color: #212121;
    }
    .MuiFormControl-fullWidth{
        width: 100% !important;
    }
    .MuiFormHelperText-root.Mui-error{
        font-size: 13px !important;
    }
    .MuiFormHelperText-root{
        font-size: 13px !important;
    }
    .link_general{
        text-decoration: none !important;
        color: ${({ theme }: ThemeType) => theme.colors.text} !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(11px,-6px) scale(0.74) !important;
    }
    label.Mui-focused, label.MuiFormLabel-filled{
        font-size: 0.959rem !important;
    }
    .no_data_children{
        display: inline-block;
    }
    .no_data_children_item{
        display: block;
        max-height: 5px;
    }
    .table_body_row_show_10 {
        max-height: 465px;
        overflow-y: auto;
        width: 100%;
        display: block;
    }
    .logoMainMenu{
        height: 55px;
        width: auto;
        text-align: -webkit-center;
    }
    .MuiPaper-elevation1{
        padding: 0 1%;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.06),
        0px 6px 10px 0px rgba(0, 0, 0, 0.042),
        0px 1px 18px 0px rgba(0, 0, 0, 0.036);
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 42px);
    }
    .c_loadingField{
        flex: 1;
    }
    .f_loadingField{
        border: thin solid #c0c0c0;
        border-radius: 5px;
        height: 35px;
    }
    .label_loadingField{
        padding: 0px 10px;
        margin-top: 5px;
        display: block;
        color: #868686;
    }
    .outlinedInputLabel {
        line-height: 16px !important;
        font-size: 13px !important;
        margin-top: -3px;
    }
    .capitalize{
        text-transform: capitalize !important;
    }
    .cardPageContainer{
        background-color: ${({ theme }: ThemeType) => theme.colors.card.background} !important;
        color: ${({ theme }: ThemeType) => theme.colors.card.text} !important;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
        box-shadow: ${({ theme }: ThemeType) => theme.colors.card.boxShadow} !important;
        border-radius: 16px !important;
        display: table;
        min-width: 98%;
    }
    .MuiChip-label{
        color: ${({ theme }: ThemeType) => theme.colors.card.text} !important;
    }
    .subContentMenu{
        background-color: ${({ theme }: ThemeType) => theme.colors.menu.subMenu.background} !important;
    }
    .MainLogo{
        width: 79px;
        height: 55px;
        background-image: url(${({ theme }: ThemeType) => (theme.name === 'Dark' ? logoRedWhite : logo)});
        background-size: cover;
        background-repeat: round;
    }
    .nav_Bar{
        max-width: 240px;
        width: 220px;
        overflow-y: hidden;
    }

    .scrollYcustom{
        border-radius: 10px;
        background-color: ${({ theme }: ThemeType) => theme.colors.scroll.background} !important;
        width: 9px !important;
        opacity: 0.5;
        height: auto;
        cursor: pointer;
    }

    .scrollXcustom{
        border-radius: 10px;
        background-color: ${({ theme }: ThemeType) => theme.colors.scroll.background} !important;
        opacity: 0.5;
        height: 9px !important;
        bottom: 3px;
        cursor: pointer;
    }

    .appBarElement{
        border-radius: 5px;
        background-color: ${({ theme }: ThemeType) => theme.colors.menu.background} !important;
    }
    .info{
        color: ${({ theme }: ThemeType) => theme.colors.info.background} !important;
    }
    .danger{
        color: ${({ theme }: ThemeType) => theme.colors.danger.background} !important;
    }
    .success{
        color: ${({ theme }: ThemeType) => theme.colors.success.background} !important;
    }
    .warning{
        color: ${({ theme }: ThemeType) => theme.colors.warning.background}  !important;
    }
    .default{
        color: ${({ theme }: ThemeType) => theme.colors.default.background} !important;
    }

    .MuiOutlinedInput-input:-webkit-autofill {
        caret-color: #fff;
        border-radius: inherit;
        -webkit-box-shadow: 0 0 0 100px ${({ theme }: ThemeType) => theme.colors.autofill.background} inset !important;;
        -webkit-text-fill-color: ${({ theme }: ThemeType) => theme.colors.autofill.text} !important;;
    }

    .MuiIconButton-root.Mui-disabled {
        color: ${({ theme }) => theme.colors.button.disabled.background} !important;
        background-color: transparent !important;
        padding-right: 20px;
    }

    .dropzone{
        width: 400px;
        height: 70px;
        background-color: ${({ theme }: ThemeType) => theme.colors.menu.background} !important;
    }
    
    .fieldRequired i{
        color: #FF1616;
        font-size: 20px;
    }

    .textRequired {
        font-size: ${FontSize} !important;
        color: #f44336;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin-top: 3px;
        margin-right: 14px;
        margin-bottom: 0;
        margin-left: 14px
    }

    .static_action_buttons .MuiDialog-paper{
        width: -webkit-fill-available;
    }

    .drawer_full_page .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 0px);
        max-width: calc(100% - 64px);

        & .css-ypiqx9-MuiDialogContent-root {
            padding: 5px !important;
        }
    }

    .drawerUiModal .MuiDialog-paperScrollPaper {
        & .css-ypiqx9-MuiDialogContent-root {
            max-height: calc(100% - 0px);
            padding: 5px !important;
        }
    }

    .containerButtonsFilterHeaderDataTable{
        margin-left: -5px !important;
        
        @media (min-width: 1480px){
            min-width: 820px;
        }
    }
    
    .backdrop{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
    }

    .modalLegend{
        padding: 0px 10px;
    }

    .disabled{
        color: #bdbdbd !important;
        cursor: no-drop !important;
    }

    .PrivatePickersSlideTransition-root{
        min-height: 230px !important;
    }

    .customStyleDatePickerFilter{
        margin: 5px 0px !important;
    }

    .stc_fontsize {
        font-size: ${FontSize} !important;
    }

    .datepikerFieldFormikAlert{
        padding: 0px 10px 0px 10px;
    }

    .textFieldFormikAlert{
        padding: 10px 10px 0px 10px;
    }
    .alertUserPageHeaderTable{
        padding: 0px 10px !important;
    }
    
    && .MuiInputBase-root fieldset legend {
        font-size: 11px !important;
    }

    .location-select__menu-portal {
        z-index: 2 !important;
    }

    @media (min-width: 600px){
        .containerTextFieldFormikFilterHeader .MuiToolbar-gutters{
            padding-left: 0px !important;
        }
    }
}
`;
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            margin: '0 auto',
            fontSize: FontSize + ' !important'
        },
        textField: {
            '& > *': {
                width: '100%'
            },
            padding: '12px 15px'
        },
        gridC: {
            padding: theme.spacing(2)
        },
        submitButton: {
            marginTop: '24px'
        },
        title: { textAlign: 'center' },
        successMessage: { color: 'green' },
        errorMessage: { color: 'red', fontSize: '12px' }
    })
);

export const ButtonSuccess = withStyles((theme) => ({
    root: {
        color: '#fff',
        padding: '1px',
        textTransform: 'capitalize',
        backgroundColor: theme?.colors.success.background,
        '&:hover': {
            backgroundColor: theme?.colors.success.backgroundHover
        }
    }
}))(Button);

export const ButtonDanger = withStyles((theme) => ({
    root: {
        color: '#fff',
        padding: '1px',
        textTransform: 'capitalize',
        backgroundColor: theme?.colors.danger.background,
        '&:hover': {
            backgroundColor: theme?.colors.danger.backgroundHover
        }
    }
}))(Button);

export const ButtonInfo = withStyles((theme) => ({
    root: {
        color: '#fff',
        padding: '1px',
        textTransform: 'capitalize',
        backgroundColor: theme?.colors.info.background,
        '&:hover': {
            backgroundColor: theme?.colors.info.backgroundHover
        }
    }
}))(Button);

export const CustomStylesSelectAsyncPaginate = (
    width: string | number = '100%',
    height: string | number = 38,
    error = false
) => {
    const { theme } = useTheme();

    const CustomStylesSelectAsyncPaginate = {
        menu: (provided) => ({
            ...provided,
            width: width,
            backgroundColor: theme.colors.select?.menuBackColor
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? theme.colors.autofill.text : theme.colors.autofill.text,
            backgroundColor: state.isSelected
                ? theme.colors.select.optionActive
                : state.isFocused
                ? theme.colors.select?.optionHover
                : theme.colors.select.menuColor,
            '&:hover': {
                backgroundColor: theme.colors.select?.optionHover
            }
        }),
        input: (provided) => ({
            ...provided,
            color: theme.colors.autofill.text
        }),
        control: (provided, state) => ({
            ...provided,
            width: width,
            height: 'auto',
            minHeight: height,
            overFlow: 'auto',
            borderColor: error
                ? '#f4403c'
                : state.isFocused
                ? theme.colors.select?.selectedValue
                : theme.colors.select?.colorBorderWithoutFocus,
            backgroundColor: theme.colors.select.inputColor,
            boxShadow: '0 !important',
            '&:hover': {
                borderColor: error ? '#f4403c' : theme.colors.select?.selectedValue
            }
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: theme.colors.select?.selectedValue
        }),
        placeholder: (provided) => ({
            ...provided,
            color: error ? '#f4403c' : theme.colors.text
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme.colors.select?.selectedValue
        }),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: theme.colors.select?.optionHover
            };
        },
        multiValueLabel: (provided, styles) => ({
            ...styles,
            ...provided,
            color: theme.colors.text
        }),
        multiValueRemove: (provided, styles) => ({
            ...provided,
            ...styles,
            color: theme.colors.text,
            ':hover': {
                backgroundColor: theme.colors.select.selectedValue,
                color: theme.colors.select.inputColor
            }
        })
    };

    return CustomStylesSelectAsyncPaginate;
};

export const TextEllipsis = styled.span`
    cursor: default;
    display: inline-block;
    width: -webkit-fill-available;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
`;

// eslint-disable-next-line max-len
export const GridFieldContainer = styled(Grid)<{ show?: string; visibility?: string; $minWidth?: string, $noMinWidth?: boolean }>`
    width: 100%;
    padding: 10px;
    display: ${({ show }) => show ?? 'inline-grid'};
    visibility: ${({ visibility }) => visibility ?? 'visible'};
    ${({ $minWidth, $noMinWidth }) => {
        if($noMinWidth) return '';
        return `min-width: ${$minWidth ?? ' 215px;'}`;
    }}
`;

export const LinkAncla = styled.a`
    color: ${({ theme }: ThemeType) => theme.colors.colorLink.text || '#f60'};
`;

export const ContainerSelectFilter = styled.div`
    padding-bottom: 7px;
    padding-top: 3px;
`;

export const IUAlert = styled(Alert)`
    padding: 0px 16px !important;
`;

export const LabelSwitchFilter = styled.span`
    padding: 0 5px !important;
    transform: translate(14px, -6px) scale(0.9) !important;
`;

export const TitleItemWithIcon = styled.div`
    display: inline-block;
    margin-top: -5px;
`;
