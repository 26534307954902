import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { ISensorForm, SensorFormik as SensorFormikType } from './Sensor.type';
import { InputElement } from '../FormElements';
import StockLocationDropdown from '../StockLocationDropdown/StockLocationDropdown';
import SkusDropDown from '../SkusDropDown/SkusDropDown';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { useTranslation } from 'react-i18next';

export const SensorFormik: React.FC<SensorFormikType> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const Formschema = Yup.object({
        serialNumber: Yup.string().trim(),
        printedSensorNumber: Yup.string().trim().required(translate('p.this_field_is_required') as string),
        serialNumberHex: Yup.string().trim(),
        version: Yup.string().trim().nullable(),
        locationSelect: Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string().required()
        }),
        skuSelect: Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string().required()
        })
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISensorForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISensorForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning' sx={{ marginBottom: 1 }}>
                                    {translate('p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}

                            {props.hexMalformation ? (
                                <IUAlert variant='outlined' severity='info'>
                                    {translate('p.incorrect_hex_format')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <div className='textBox_sensors' style={{ minHeight: '137px' }}>
                                <Grid container justifyContent='initial' direction='row'>
                                    <InputElement
                                        label={translate('t.printed_sensor_number')}
                                        name='printedSensorNumber'
                                        value={values.printedSensorNumber}
                                        error={errors.printedSensorNumber}
                                        touched={touched.printedSensorNumber}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            props.serialNumberCalculation(values, setFieldValue, e.target.value || '');
                                            handleBlur(e);
                                        }}
                                        required={true}
                                        disabled={!props.dataModal?.isAdd}
                                        autoFocus={true}
                                    />

                                    <SkusDropDown
                                        name='skuSelect'
                                        url='/type/sensor/all'
                                        paramsUrl={[{ id: 'type', value: 'active' }]}
                                        label={translate('t.sku')}
                                        value={props.dataModal?.isAdd ? values.skuSelect[0] : values.skuSelect}
                                        error={!!errors.skuSelect}
                                        onChange={(value) => {
                                            setFieldValue('skuSelect', value ? value : '');
                                            props.serialNumberCalculation(
                                                values,
                                                setFieldValue,
                                                '',
                                                value || undefined
                                            );
                                        }}
                                        required={true}
                                        textRequired={translate('p.this_field_is_required')}
                                    />

                                    <InputElement
                                        label={translate('t.serial_number')}
                                        name='serialNumber'
                                        value={values.serialNumber}
                                        error={errors.serialNumber}
                                        touched={touched.serialNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                        autoFocus={true}
                                    />

                                    <InputElement
                                        label={translate('t.serial_number') + ' HEX'}
                                        name='serialNumberHex'
                                        value={values.serialNumberHex}
                                        error={errors.serialNumberHex}
                                        touched={touched.serialNumberHex}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                        autoFocus={true}
                                    />

                                    <InputElement
                                        label={translate('t.version')}
                                        name='version'
                                        value={values.version ?? ''}
                                        error={errors.version}
                                        touched={touched.version}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <StockLocationDropdown
                                        name='locationSelect'
                                        label={translate('t.stock_location')}
                                        value={
                                            props.dataModal?.isAdd ? values.locationSelect[0] : values.locationSelect
                                        }
                                        error={!!errors.locationSelect}
                                        onChange={(value) => {
                                            setFieldValue('locationSelect', value ? value : '');
                                        }}
                                        required={true}
                                        textRequired={translate('p.this_field_is_required')}
                                    />
                                </Grid>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
