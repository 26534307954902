import React, { useRef } from 'react';
import { PublicSurveyContentStyle } from './PublicSurvey.style';
import { useStyles } from '../../styled/global.style';
import { Grid, Paper } from '@mui/material';
import ChangeLanguage from '../../components/ChangeLanguage/ChangeLanguage';
import onboarding_dark from '../../assets/images/onboarding_dark.png';
import onboarding_light from '../../assets/images/onboarding_light.png';
import SiteSurvey from '../../components/SiteSurvey/SiteSurvey';
// import ItrackConfigLog from '../../components/ItrackConfigLog/ItrackConfigLog';
import { useParams } from 'react-router';
import SurveyValidation from '../../components/SiteSurvey/SurveyValidation/SurveyValidation';
import { useRecoilValue } from 'recoil';
import { StructThemeType } from '../../styled/theme.type';
import * as Settings from '../../states/global/Settings';

// import module

export const PublicSurveyContent: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const { token }: any = useParams();
    const myTheme = useRecoilValue<StructThemeType>(Settings.Theme);
    const bottonHiddenUpdateProps = useRef<HTMLButtonElement>(null);
    
    return (
        <PublicSurveyContentStyle data-testid='PublicSurveyContent'>
            <div className={classes.root}>
                <Grid container justify-suppressContentEditableWarning='space-around' direction='row' padding={0} >
                    <Grid item md={12}>
                        <ChangeLanguage />
                    </Grid>
                    <div className='logo-stock'>
                        <img 
                            src={myTheme.name === 'Dark' ? onboarding_dark : onboarding_light} 
                            className='logo-survey'
                            alt='logo-onboarding' 
                        />
                    </div>
                </Grid>
                <Grid 
                    container 
                    justify-suppressContentEditableWarning='space-around' 
                    direction='row' 
                    padding={1} 
                    columnSpacing={1} 
                >
                    <Grid item md={10}>
                        <Paper elevation={0}>
                            <SiteSurvey 
                                publicMode={true} 
                                token={token} 
                                bottonHiddenUpdateProps={bottonHiddenUpdateProps} 
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={2}>
                        <Paper elevation={0}>
                            <SurveyValidation bottonHiddenUpdate={bottonHiddenUpdateProps} publicMode={true} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </PublicSurveyContentStyle>
    );
};