import { Button, CircularProgress, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './Roles.style';
import * as type from './Roles.type';
import * as RoleState from '../../states/component/Role';
import UiTable from '../Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
import { RolesFormik } from './Roles.formik';
import { RoleAssigningFormik } from './RoleAssigning.formik';
import { useTranslation } from 'react-i18next';
// import module

export const RolesContent: React.FC<type.RolesPropsView> = (props: type.RolesPropsView) => {
    const { t: translate } = useTranslation();
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    let submitFormAssigned: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmittingAssigned, setIsSubmittingAssigned] = useState<boolean>(false);

    const bindSubmitFormAssigned = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitFormAssigned = form;
    };

    const handleSubmitAssigned = (): void => {
        submitFormAssigned && submitFormAssigned();
    };

    return (
        <style.RolesContent data-testid='RolesContent'>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='roles'
                    data={useRecoilValue(RoleState.Roles)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    onAdd={() => props.openModal}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter }) => {
                        props.fetchData(page + 1, limit, order, filter);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'id',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                />
            </Paper>

            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                title={props.dataModal?.title}
                content={
                    !props.dataModal?.isAdd && props.valuesInitForm.name === '' ? (
                        <Loading />
                    ) : (
                        <RolesFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            dataModal={props.dataModal}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {translate('t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                        </Button>
                    </>
                }
            />

            <UiModal
                open={props.openAssignig}
                maxWidthModal='xl'
                maxHeight='calc(100vh - 90px)'
                closeModal={props.closeModal}
                title={translate('t.assigning_permissions_to_roles') + ' - #' + props.role?.id}
                content={
                    props.loadingModalPermissions ? (
                        <Loading />
                    ) : (
                        <RoleAssigningFormik
                            valuesInitForm={{ stock_permission: 0, role: 0 }}
                            handleSubmit={props.handleSubmitAssignedPermissions}
                            bindSubmitForm={bindSubmitFormAssigned}
                            setIsSubmitting={setIsSubmittingAssigned}
                            stock_permissions={props.stock_permissions}
                            openManuPermission={props.OpenManuPermission}
                            selectedIndex={props.selectedIndex}
                            enabledPermission={props.enabledPermission}
                            role={props.role}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {translate('t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmittingAssigned}
                            onClick={() => {
                                handleSubmitAssigned();
                            }}
                        >
                            {isSubmittingAssigned ? <CircularProgress size={25} /> : translate('t.save')}
                        </Button>
                    </>
                }
            />
            <style.RolesBody />
        </style.RolesContent>
    );
};
