import { selector } from 'recoil';
import { UserInfo } from '../states/global/User';
import moment from 'moment-timezone';
// import { DateTime } from 'moment';

export const FromUTCToTimezone = selector({
    key: 'FromUTCToTimezone',
    get: async ({ get }) => {
        const userTimezone: string = get(UserInfo)?.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        const dateTimeFormat: (displaySeconds: boolean) => string = get(DateTimeFormat);
        return (utc: Date | moment.Moment | string | null, displaySeconds: boolean): string => {
            if (!utc || !moment(utc).isValid()) return '---';
            return moment.utc(utc).tz(userTimezone).format(dateTimeFormat(displaySeconds));
        };
    }
});

export const FromUTCtoUserTimezone = selector({
    key: 'DateTimeFormatter',
    get: ({ get }) => {
        const userFormats: { dateFormat: string; timeFormat: string } = {
            dateFormat: get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD',
            timeFormat: get(UserInfo)?.user?.userSetting.timeFormat || 'HH:mm'
        };
        const userTimezone: string = get(UserInfo)?.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';

        return (date: Date, displaySeconds = true, format: 'datetime' | 'time' | 'date' = 'datetime'): string => {
            let timeFormat = userFormats.timeFormat;

            if (!date || !moment(date).isValid()) return '---';

            if (displaySeconds) {
                timeFormat = `${userFormats.timeFormat}:ss`;
            }
            if (userFormats.timeFormat === 'hh:mm') {
                timeFormat = `${userFormats.timeFormat} A`;
            }

            const formatInMoment =
                format === 'date'
                    ? userFormats.dateFormat
                    : format === 'time'
                    ? timeFormat
                    : `${userFormats.dateFormat} ${timeFormat}`;
            return moment.tz(date, userTimezone || 'Europe/Bratislava').format(formatInMoment);
        };
    }
});

export const FromTimezoneToUTC = selector({
    key: 'FromTimezoneToUTC',
    get: async ({ get }) => {
        const userTimezone: string = get(UserInfo)?.user?.userSetting.timezone.timezoneName || 'America/Bogota';
        const dateFormat: string = get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD';
        return (dateTime: Date | moment.Moment | number | string, useTime = true): string => {
            if (!dateTime || !moment(dateTime).isValid()) return '---';
            const format = `${dateFormat}${useTime ? ' HH:mm:ss' : ''}`;
            return moment.tz(dateTime, userTimezone).utc().format(format);
        };
    }
});

export const FromUserTimezoneToTimezone = selector({
    key: 'FromUserTimezoneToTimezone',
    get: async ({ get }) => {
        const userTimezone: string = get(UserInfo)?.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        return (dateTime: Date | moment.Moment | string): moment.Moment | string => {
            if (!dateTime || !moment(dateTime).isValid()) return '---';
            return moment.utc(moment.tz(dateTime, userTimezone).utc()).tz(userTimezone);
        };
    }
});

export const UserDateTimeFormat = selector({
    key: 'UserDataTimeFormat',
    get: async ({ get }) => {
        const userTimezone: string = get(UserInfo)?.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        const userFormats: { dateFormat: string; timeFormat: string } = {
            dateFormat: get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD',
            timeFormat: get(UserInfo)?.user?.userSetting.timeFormat || 'HH:mm:ss'
        };
        return (date: Date | string): string => {
            if (!date || !moment(date).isValid()) return '---';

            if (userFormats.timeFormat.indexOf('h') > -1) {
                userFormats.timeFormat += ' A';
            }
            return moment
                .tz(date, userTimezone || 'Europe/Bratislava')
                .format(`${userFormats.dateFormat} ${userFormats.timeFormat}`);
        };
    }
});

export const DateTimeFormat = selector({
    key: 'DateTimeFormat',
    get: async ({ get }) => {
        const dateFormat: string = get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD';
        const timeFormat: string = get(UserInfo)?.user?.userSetting.timeFormat || 'HH:mm';

        return (displaySeconds = true): string => {
            const userFormats: { dateFormat: string; timeFormat: string } = {
                dateFormat,
                timeFormat
            };
            if (displaySeconds) {
                userFormats.timeFormat = `${userFormats.timeFormat}:ss`;
            }
            if (userFormats.timeFormat.includes('hh:mm')) {
                userFormats.timeFormat = `${userFormats.timeFormat} A`;
            }
            return `${userFormats.dateFormat} ${userFormats.timeFormat}`;
        };
    }
});

export const Is12HrsFormat = selector<boolean>({
    key: 'Is12HrsFormat',
    get: async ({ get }) => {
        const timeFormat = get(UserInfo)?.user?.userSetting.timeFormat || 'hh:mm';
        return timeFormat === 'hh:mm';
    }
});

export const GetDateMask = selector<string>({
    key: 'GetDateMask',
    get: async ({ get }) => {
        const dateFormat = get(UserInfo)?.user?.userSetting.dateFormat || 'YYYY-MM-DD';
        return dateFormat.replaceAll('Y', '_').replaceAll('M', '_').replaceAll('D', '_');
    }
});

export const GetTimeMask = selector<string>({
    key: 'GetTimeMask',
    get: async ({ get }) => {
        const timeFormat = get(UserInfo)?.user?.userSetting.timeFormat || 'hh:mm';
        let mask: string = timeFormat;
        const is12HrsFormat: boolean = get(Is12HrsFormat);
        if (is12HrsFormat) {
            mask += ' _M';
        }
        return mask.replaceAll('H', '_').replaceAll('h', '_').replaceAll('m', '_');
    }
});

export const DateTimeMask = selector<string>({
    key: 'DateTimeMask',
    get: async ({ get }) => {
        const dateMask = get(GetDateMask);
        const timeMask = get(GetTimeMask);
        return `${dateMask} ${timeMask}`;
    }
});

export const getTimezoneOffsetFormatted = (timezoneName) => {
    const now = moment().tz(timezoneName);
    const offsetMinutes = now.utcOffset();

    const sign = offsetMinutes >= 0 ? '+' : '-';
    const absOffset = Math.abs(offsetMinutes);
    const hours = Math.floor(absOffset / 60)
        .toString()
        .padStart(2, '0');
    const minutes = (absOffset % 60).toString().padStart(2, '0');

    return `(GMT${sign}${hours}:${minutes})`;
};
