import React, { useCallback, useState } from 'react';
import { SurveyContent } from './Survey.view';
import Wrapper from '../../helpers/wrapper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ColumnsTable } from '../../types/common';
import { 
    Typography 
} from '@mui/material';
import ApiSurvey from '../../api/ApiSurvey';
import { Surveys } from '../../states/component/Survey';
import { SurveyType } from '../../models/ApiSurvey.type';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { CellProps } from 'react-table';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import EmailsList from './EmailsList/EmailsList';
import { ContentCopy, ListAlt, Send } from '@mui/icons-material';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { Success, Warning } from '../Popup/Popup';
import { useTranslation } from 'react-i18next';
// import module

const apiSurvey = new ApiSurvey();

const Survey: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [idDetail, setIdDetail] = useState<number | null>(null);
    const setdata = useSetRecoilState<SurveyType[]>(Surveys);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const getDateMask = useRecoilValue(GetDateMask);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);

    const copyUrlToClipboard = (path = '') => {
        // eslint-disable-next-line max-len
        const linkOnboarding = process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.includes('https://webtrack-dev.atmstechnology.com')
        ? 'http://onboarding-dev.itrack.bridgestone'
        : 'https://onboarding.itrack.bridgestone';

        const fullUrl = `${linkOnboarding}/${path}`.replace(/\/+/g, '/');

        navigator.clipboard.writeText(fullUrl)
        .then(() => {
            Success({
                text: `${translate('t.link_copied')}`
            });
        });
    };

    const getAll = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setdata([]);
            const data = await apiSurvey.getAll(page, limit, order, filter);
            setdata(data.items);
            setPageCount(data.paginator.pageCount);
            setTotal(data.paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const resendSurvey = async (id: number) => {
        Warning({
            text: `${translate('t.resending_survey')}`
        });
        await apiSurvey.resend(id).then(() => {
            Success({
                text: `${translate('t.survey_resent')}`
            });
        });
    };

    const showDetail = (Survey: SurveyType) => {
        setIdDetail(Survey.id);
        openModal();
    };

    const ActionsButtons = (original: SurveyType): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => showDetail(original)}>
                    <ListAlt />
                    <span>{translate('t.view_detail')}</span>
                </Typography>

                {original.status !== 'accepted' && (
                    <Typography 
                        className='dataTableActionsBtn'
                        onClick={() => resendSurvey(original.id)}
                    >
                        <Send />
                        <span>{translate('t.resend')}</span>
                    </Typography>
                )} 

                <Typography 
                    className='dataTableActionsBtn' 
                    onClick={() => copyUrlToClipboard(`public-survey/${original.token}`)}
                >
                    <ContentCopy />
                    <span>{translate('t.copy_public_link')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: translate('t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row: { original } }: CellProps) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        },
        {
            Header: translate('t.send_to'),
            accessor: 'email',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            disableFilters: true,
            disableSortBy: true,
            width: 75,
            Cell: ({ row }: CellProps) => {
                return (
                    <EmailsList
                        id={row.original.id}
                        emails={row.original.email}
                    />
                );
            }
        },
        {
            Header: translate('t.name'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: translate('t.send_date'),
            accessor: 'invitedAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original.invitedAt || '---', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.send_data_from')}
                            filterName='invitedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.send_data_to')}
                            filterName='invitedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'invitedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'invitedAtTo'
        },
        {
            Header: translate('t.filling_date'),
            accessor: 'acceptedAt',
            aggregate: 'count',
            canFilters: false,
            disableGroupBy: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original.acceptedAt || '---', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.filling_date_from')}
                            filterName='acceptedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.filling_date_to')}
                            filterName='acceptedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'acceptedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'acceptedAtTo'
        },
        {
            Header: translate('t.accepted_by'),
            accessor: 'confirmedBy.username',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => row.original.confirmedBy?.username ?? '---'
        },
        {
            Header: translate('t.accepted_at'),
            accessor: 'confirmedAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original.confirmedAt || '---', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.accepted_at_from')}
                            filterName='confirmedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.accepted_at_to')}
                            filterName='confirmedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'confirmedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'confirmedAtTo'
        },
        {
            Header: translate('t.status'),
            accessor: 'status',
            aggregate: 'count',
            canFilters: false,
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }: CellProps) =>
                            translate('t.' + row.original.status.replaceAll(' ', '_').toLowerCase())
        }
    ];

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setRefresh(true);
        setTimeout(() => {
            idDetail && setIdDetail(null);
        }, 500);
    };

    const closeModalConfirm = () => {
        closeModal();
        setOpenConfirm(false);
    };

    return (
        <SurveyContent
            data-testid='Survey-testid'
            openModal={openModal}
            closeModal={closeModal}
            fetchData={getAll}
            setRefresh={setRefresh}
            closeModalConfirm={closeModalConfirm}
            columnsSurvey={columns}
            open={open}
            loading={loading}
            pageCount={pageCount}
            total={total}
            refresh={refresh}
            idDetail={idDetail}
            openConfirm={openConfirm}
        />
    );
};

export default Wrapper(Survey);