import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ISignUpForm, SkuPricesProps, isValidDateRange } from './SkuPrices.type';
import * as view from './SkuPrices.view';
import Wrapper from '../../helpers/wrapper';
import { ColumnsTable, ModalType } from '../../types/common';
import ApiSkuPrices from '../../api/ApiSkuPrices';
import { ModelSkuPrice } from '../../models/ApiSkuPrice.type';
import { CellProps } from 'react-table';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { 
    FromTimezoneToUTC, FromUTCToTimezone, FromUTCtoUserTimezone, FromUserTimezoneToTimezone, GetDateMask
} from '../../helpers/Converters';
import { verifyPermission } from '../../helpers/Auth';
import { Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { Errors, Success } from '../Popup/Popup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import module

const apiSkuPrices = new ApiSkuPrices();

const SkuPrices: React.FC<SkuPricesProps> = (props) => {
    const [modalSkuPricesLoading, setModalSkuPricesLoading] = useState<boolean>(true);
    const [dataSkuPrices, setDataSkuPrices] = useState<ModelSkuPrice[]>([]);
    const { t: translate } = useTranslation();
    const getDateMask = useRecoilValue(GetDateMask);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromUTCtoUserTimezone = useRecoilValue(FromUTCtoUserTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const [pageCountItems, setPageCountItems] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(true);
    const [openAddModel, setOpenAddModel] = useState<boolean>(false);
    const [sendFrom, setSendFrom] = useState<boolean>(true);
    const fromUserTimezoneToTimezone = useRecoilValue(FromUserTimezoneToTimezone);
    const [currentPricePeriod, setCurrentPricePeriod] = useState<ModelSkuPrice | null>(null);

    const showBtnAdd: string | undefined = verifyPermission(['add_sku']);

    const [dataModal, setDataModal] = useState<ModalType>({
        isAdd: true,
        title: translate('t.add_new'),
        id: null
    });

    const [valuesInitForm, setValuesInitForm] = useState<ISignUpForm>({
        sku: props.Sku?.id || 0,
        shippingPriceUsd: 0,
        validFrom: '',
        validTo: ''
    });

    const getPrices = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setModalSkuPricesLoading(true);

            const data = await apiSkuPrices.getAll(`?skuId=${props.Sku?.id}`, page, limit, order, filter);

            setDataSkuPrices(data.items);

            if(data.paginator.totalCount){
                setCurrentPricePeriod(data.items[0]);
            }

            setPageCountItems(data.paginator.pageCount);
            setTotalItems(data.paginator.totalCount);

            setModalSkuPricesLoading(false);
        },
        [setDataSkuPrices, props.Sku]
    );

    const columns: ColumnsTable[] = [
        {
            Header: translate('t.price_in_usd'),
            accessor: 'shippingPriceUsd',
            disableGroupBy: true,
            aggregate: 'count',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }: CellProps) => row.original.shippingPriceUsd + '$'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'validDate'
        },
        {
            Header: translate('t.valid_from'),
            accessor: 'validFrom',
            disableGroupBy: true,
            // disableFilters: true,
            aggregate: 'count',
            Export: ({ row }: CellProps) => fromUTCtoUserTimezone(row.original?.validFrom || '', false, 'date'),
            Cell: ({ row }: CellProps) => fromUTCtoUserTimezone(row.original?.validFrom || '', false, 'date'),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.valid_date')}
                            filterName='validDate'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.valid_to'),
            accessor: 'validTo',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            Export: ({ row }: CellProps) => fromUTCtoUserTimezone(row.original?.validTo || '', false, 'date'),
            Cell: ({ row }: CellProps) =>
                row.original.validTo ? fromUTCtoUserTimezone(row.original?.validTo || '', false, 'date') : '---'
        },
        {
            Header: translate('t.created_by'),
            accessor: 'createdBy',
            disableGroupBy: true,
            aggregate: 'count',
            disableSortBy: true,
            disableFilters: true,
            Export: ({ row }: CellProps) =>
                row.original.createdBy ? row.original.createdBy.firstname + ' ' + row.original.createdBy.lastname : '',
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.createdBy ? (
                    <>
                        <span>{original.createdBy.firstname}</span> <span>{original.createdBy.lastname}</span>
                    </>
                ) : (
                    '---'
                )
        },
        {
            Header: translate('t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    const ActionsButtons = (original: ModelSkuPrice): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => editSkuPrice(original)}>
                    <Edit />
                    <span>{translate('t.edit')}</span>
                </Typography>
            </>
        );
    };

    const closeModalSkuPrices = () => {
        setDataSkuPrices([]);
        props.setModalSkuPrices(false);
        props.setSku(null);
        setCurrentPricePeriod(null);
    };

    const isValidDateRange = async (validFrom, validTo): Promise<void> => {
        try {
            const data: { validFrom?: string; validTo?: string; skuId: number } = { skuId: props.Sku?.id || -1 };

            if (validFrom) {
                data.validFrom = validFrom ? fromTimezoneToUTC(validFrom, false) : '';
            }

            if (validTo) {
                data.validTo = validTo ? fromTimezoneToUTC(validTo, false) : '';
            }

            const { isValid, datesRange }: isValidDateRange = await apiSkuPrices.getTemporal(
                `/validatedaterange/${dataModal.id ?? 0}`,
                'v1',
                data
            );
            if (isValid === false) {
                setSendFrom(false);
                const dateFrom = fromUTCToTimezone(datesRange[0]?.validFrom || '', false);
                const dateTo = fromUTCToTimezone(datesRange[0]?.validTo || '', false);
                const message = `${translate('p.validation_date_renges_overlap')} ${dateFrom} - ${dateTo}
                                ${translate('p.validation_date_linked')}`;
                Errors({
                    text: message
                });
            } else {
                setSendFrom(true);
            }
        } catch (error) {
            console.warn('Error in GET validatedaterange ', error);
        }
    };

    const openAddModal = async (
        actionType = 'create',
        id: number | null = null,
        skuPrice: ModelSkuPrice | null = null
    ): Promise<void> => {
        setLoadingForm(true);
        setSendFrom(true);
        actionType !== 'edit' && setOpenAddModel(true);

        updateValuesInitForm(skuPrice, actionType !== 'edit');

        setDataModal({
            isAdd: actionType !== 'edit',
            title: actionType !== 'edit' ? translate('t.add_new') : translate('t.edit'),
            id: actionType !== 'edit' ? null : id
        });

        setLoadingForm(false);
    };

    const updateValuesInitForm = (skuPrice: ModelSkuPrice | null = null, isAdd=false): void => {
        let valueFrom: string = totalItems <= 0 ? fromUserTimezoneToTimezone(moment()) as string : '';

        if(currentPricePeriod){
            if(!currentPricePeriod.validTo && totalItems ) {
                valueFrom = fromUserTimezoneToTimezone(moment().clone().add(1, 'days')) as string;
            }else {
                const currentDate = moment();
                const dateTo = moment(currentPricePeriod.validTo);
                const isBefore: boolean = dateTo.isBefore(currentDate);

                if(!isBefore) {
                    valueFrom = fromUserTimezoneToTimezone(
                        moment(currentPricePeriod.validTo).clone().add(1, 'days')
                    ) as string;
                } else {
                    valueFrom = fromUserTimezoneToTimezone(
                        moment()
                    ) as string;
                }
            }
        }

        setValuesInitForm({
            sku: props.Sku?.id || 0,
            shippingPriceUsd: skuPrice?.shippingPriceUsd || 0,
            validFrom: isAdd ? valueFrom : skuPrice?.validFrom || '',
            validTo: skuPrice?.validTo || ''
        });
    };

    const handleClose = (): void => {
        updateValuesInitForm();
        setOpenAddModel(false);
    };

    const handleSubmit = async (
        e: ISignUpForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting
    ): Promise<void> => {
        try {
            const { data }: ModelSkuPrice = dataModal.isAdd
                ? await apiSkuPrices.create(e)
                : await apiSkuPrices.patch(dataModal.id ?? 0, e);

            if (!data) {
                throw data;
            }

            setRefresh(true);
            resetForm({});

            Success({
                text: `${translate('t.sku_successfully_updated')}`
            });

            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editSkuPrice = async (row: ModelSkuPrice): Promise<void> => {
        setLoadingForm(true);
        setOpenAddModel(true);

        const { skuPrice }: ModelSkuPrice = await apiSkuPrices.getById(row.id);

        openAddModal('edit', row.id, skuPrice);
    };

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <view.SkuPricesContent
            data-testid='SkuPrices-testid'
            fetchDataItems={getPrices}
            columnsSkuPrices={columns}
            modalSkuPrices={props.modalSkuPrices}
            modalSkuPricesLoading={modalSkuPricesLoading}
            dataSkuPrices={dataSkuPrices}
            closeModalSkuPrices={closeModalSkuPrices}
            typeModal={props.typeModal ? props.typeModal : 'modal'}
            sku={props.Sku}
            pageCountSkuPrices={pageCountItems}
            totalSkuPrices={totalItems}
            showBtnAdd={showBtnAdd}
            openAddModel={openAddModel}
            openAddModal={openAddModal}
            dataModal={dataModal}
            loadingForm={loadingForm}
            valuesInitForm={valuesInitForm}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            refresh={refresh}
            isValidDateRange={isValidDateRange}
            sendFrom={sendFrom}
        />
    );
};

export default Wrapper(SkuPrices);
