import React, { useState } from 'react';
import { ButtonDownloadFileProps } from './ButtonDownloadFile.type';
import { Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import Loading from '../Loading/index';
import { useTranslation } from 'react-i18next';
// import module

const ButtonDownloadFile: React.FC<ButtonDownloadFileProps> = ({
    api,
    defaultFileName = 'file.xlsx',
    buttonName
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const getDowloadFileXlsx = async (): Promise<void> => {
        try {
            setLoading(true);

            const { response, fileName }: { response: Blob; fileName: string } = await api();

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName || defaultFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Typography className='dataTableActionsBtn' onClick={() => getDowloadFileXlsx()}>
            <Download />
            <span>{buttonName || translate('t.download_xls')}</span>
        </Typography>
    );
};

export default ButtonDownloadFile;
