import { MenuItem, Tooltip, Typography, Switch } from '@mui/material';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Download, ListAlt, Redo } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as style from './OrderHistoryOutbound.style';
import * as type from './OrderHistoryOutbound.type';
import * as view from './OrderHistoryOutbound.view';
import ApiShippingOrders from '../../api/ApiShippingOrders';
import ShippingOrderDetails from '../ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import ShippingOrderFiles from '../ShippingOrder/ShippingOrderFiles/ShippingOrderFiles';
import { ColumnsTable } from '../../types/common';
import Wrapper from '../../helpers/wrapper';
import * as OrderHistoryState from '../../states/component/OrderHistory';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { ContainerSelectFilter,
    CustomStylesSelectAsyncPaginate,
    LabelSwitchFilter,
    LinkAncla,
    TextEllipsis } from '../../styled/global.style';
import ButtonDownloadFile from '../ButtonDownloadFile/ButtonDownloadFile';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import {
    ShippingOrderStatusEnum,
    ShippingOrderStatusIdsEnum
} from '../CurrentOrdersOutbound/CurrentOrdersOutbound.type';
import EditShippingOrder from '../ShippingOrder/EditShippingOrder/EditShippingOrder';
import { Model } from '../../models/ApiShippingOrders.type';
import ApiStockLocation from '../../api/ApiStockLocation';
import { AsyncPaginate } from 'react-select-async-paginate';
import { resendOrderButtonCanBeDisplayed } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../states/global/Settings';
import { StructThemeType } from '../../styled/theme.type';
// import module

const api = new ApiShippingOrders();
const apiStockLocation = new ApiStockLocation();

const OrderHistoryOutbound: React.FC<type.OrderHistoryOutboundProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const getDateMask = useRecoilValue(GetDateMask);
    const classes = style.useStyles();
    const setdata = useSetRecoilState<any>(OrderHistoryState.OrderHistoryOutbound);
    const [loading, setLoading] = useState<boolean>(true);
    const [shippingOrderId, setshippingOrderId] = useState<number | string | null>(null);
    const [openOpenShippingOrder, setOpenShippingOrder] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const stockLocationState = useRecoilValue<any>(StockLocation);
    const stockLocationRegionState = useRecoilValue<any>(StockLocationRegion);
    const [editOrderOpen, setEditOrderOpen] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const [total, setTotal] = useState<number>(0);
    const [locationFilter, setLocationFilter] = React.useState<{ locationFilter: string } | Record<string, unknown>>(
        {}
    );
    const [refresh, seRefresh] = useState<boolean>(false);
    const theme = useRecoilValue<StructThemeType>(Theme);
    const customStyles = CustomStylesSelectAsyncPaginate();
    const openModal = (type = 'D'): void => {
        type === 'D' && setOpen(true);
    };

    const defaultStatuses = [
        ShippingOrderStatusIdsEnum.DELIVERED,
        ShippingOrderStatusIdsEnum.PARTIALLY_DELIVERED,
        ShippingOrderStatusIdsEnum.CANCELED,
        ShippingOrderStatusIdsEnum.DISPUT_RESOLVED
    ];

    async function getData(page, limit, order, filter, extraFiltering) {
        let params: string[] = [];

        if (filter.filter((f) => f.id === 'status').length == 0) {
            params = defaultStatuses.map(function (status) {
                return `status[]=${status}`;
            });
        }

        if (filter.filter((f) => f.id === 'status').length == 0) {
            params = defaultStatuses.map(function (status) {
                return `status[]=${status}`;
            });
        }

        params.push(extraFiltering.locationFilter.substring(1));

        const uri = 'stock/shipping-order/all/outbound?' + params.filter((i) => i).join('&');

        return await api.getAll(`${uri}`, page, limit, order, filter);
    }

    const getAllShippingOrder = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator } = await getData(page, limit, order, filter, extraFiltering);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
        },
        [setdata]
    );

    async function loadMoreStockLocation(search, loadedOptions) {
        const perPage = 10;
        const page = Math.trunc(loadedOptions.length / perPage);

        const { items }: any = await apiStockLocation.getWithPagination(
            page + 1,
            perPage,
            '',
            '',
            search,
            [{ id: 'type', value: 'active' }],
            '?order=name:asc'
        );

        return {
            options: items,
            hasMore: items.length == perPage
        };
    }

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 100, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const detailsShippingOrder = async (shippingOrder: Model): Promise<void> => {
        setshippingOrderId(shippingOrder.id);
        openModal();
    };

    const handleClose = (): void => {
        setOpen(false);
        setEditOrderOpen(false);
        setOpenShippingOrder(false);
    };

    const openModalShippingOrderFiles = (row) => {
        setOpenShippingOrder(true);
        setshippingOrderId(row.id);
    };

    const shippingOrderItemsCount = (shippingOrderItems) => {
        let ctn = 0;
        for (let i = 0; i < shippingOrderItems.length; i++) {
            if (shippingOrderItems[i].item.boxItemsQuantity) {
                ctn += shippingOrderItems[i].item.boxItemsQuantity;
            } else {
                ctn += shippingOrderItems[i].quantity;
            }
        }
        return ctn;
    };

    const ActionsButtons = (shippingOrder: Model): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => detailsShippingOrder(shippingOrder)}>
                    <ListAlt />
                    <span>{translate('t.shipping_order_details')}</span>
                </Typography>

                {resendOrderButtonCanBeDisplayed(shippingOrder) ? (
                    <Typography
                        className='dataTableActionsBtn'
                        onClick={() => {
                            setshippingOrderId(shippingOrder.id);
                            setEditOrderOpen(true);
                        }}
                    >
                        <Redo />
                        <span>{translate('t.resend_order')}</span>
                    </Typography>
                ) : (
                    ''
                )}

                <Typography className='dataTableActionsBtn' onClick={() => openModalShippingOrderFiles(shippingOrder)}>
                    <Download />
                    <span>{translate('t.show_uploaded_files')}</span>
                </Typography>

                <ButtonDownloadFile
                    defaultFileName='sales-orders.xlsx'
                    api={() => api.getXlsx(`${shippingOrder.id}/export/xlsx?customer=0`)}
                />

                <ButtonDownloadFile
                    defaultFileName='sales-orders.xlsx'
                    buttonName={translate('t.download_xls_for_customer') as string}
                    api={() => api.getXlsx(`${shippingOrder.id}/export/xlsx?customer=1`)}
                />

                <ButtonDownloadFile
                    defaultFileName={'Invoice ' + shippingOrder.saleOrderId + '.pdf'}
                    buttonName={translate('t.download_invoice') as string}
                    api={() => api.getXlsx(`${shippingOrder.id}/export/invoice?customer=1`)}
                />
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: translate('t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            width: 60,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        },
        {
            width: 0,
            Header: '',
            isVisible: false,
            disableSortBy: true,
            disableGroupBy: true,
            accessor: 'reshipped',
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <div className='ContainerChecboxFilter'>
                        <LabelSwitchFilter>{translate('t.order_reshiped')}</LabelSwitchFilter>
                        <Switch
                            checked={column.filterValue?.value === '1'}
                            onChange={(e) => {
                                let value: { name: string; value: string; humanValue: string } | undefined = undefined;
                                if (e.target.checked) {
                                    value = {
                                        name: translate('t.order_reshiped'),
                                        value: '1',
                                        humanValue: translate('t.yes')
                                    };
                                }
                                setFilter(column.id, value);
                            }}
                            color='primary'
                            name='_checkboxInactive'
                            inputProps={{ 'aria-label': 'secondary checkbox', color: '#ff1616' }}
                        />
                    </div>
                );
            }
        },
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50,
            Cell: ({ row: { original } }: CellProps<object>) => (
                <Tooltip
                    title={translate('t.shipping_order_details') as string}
                    aria-label={translate('t.shipping_order_details') as string}
                >
                    <LinkAncla href='javascript:void(0);' onClick={() => detailsShippingOrder(original)}>
                        {original.id}
                    </LinkAncla>
                </Tooltip>
            )
        },
        {
            Header: translate('t.sale_order_id'),
            accessor: 'originalImportedTrentInvoiceNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<string>) => {
                return original.originalImportedTrentInvoiceNumber || '---';
            }
        },
        {
            Header: translate('t.invoice_number'),
            accessor: 'saleOrderId',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.saleOrderId}> {row.original.saleOrderId} </TextEllipsis>
            )
        },
        {
            Header: translate('t.from'),
            accessor: 'locationFrom.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.locationFrom.name}> {row.original.locationFrom.name} </TextEllipsis>
            )
        },
        {
            Header: translate('t.to'),
            accessor: 'locationTo.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.locationTo.name}> {row.original.locationTo.name} </TextEllipsis>
            )
        },
        {
            Header: translate('t.status'),
            accessor: 'status',
            disableGroupBy: true,
            width: 130,
            aggregate: 'count',
            canFilters: true,
            Export: ({ row }: CellProps) =>
                translate('t.' + row.original.shippingOrderStatus.status.replaceAll(' ', '_').toLowerCase()),
            Cell: ({ row }: CellProps) =>
                translate('t.' + row.original.shippingOrderStatus.status.replaceAll(' ', '_').toLowerCase()),
            Filter: ({ column, setFilter }: CellProps) => {
                const listStatuses: { id: number; name: string }[] = [];

                for (const i in ShippingOrderStatusEnum) {
                    if (
                        ![
                            ShippingOrderStatusEnum.NEW,
                            ShippingOrderStatusEnum.IN_TRANSIT,
                            ShippingOrderStatusEnum.IN_DISPUTE,
                            ShippingOrderStatusEnum.WAITING_CUSTOMER_ACK
                        ].includes(ShippingOrderStatusEnum[i])
                    ) {
                        listStatuses.push({
                            id: ShippingOrderStatusIdsEnum[i],
                            name: translate('t.' + ShippingOrderStatusEnum[i].replaceAll(' ', '_').toLowerCase())
                        });
                    }
                }

                if (!column.filterValue?.value) {
                    setFilter(column.id, {
                        stats: column.Header,
                        name: column.Header,
                        humanValue: listStatuses
                            .filter((status) => defaultStatuses.includes(status.id))
                            .map((status) => status.name),
                        value: defaultStatuses
                    });
                }

                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ 
                                    backgroundColor: theme.name === 'Dark' ? '#32383f' : 'white', padding: '0 5px 0 0'
                                }}
                            >
                                {column.Header}
                            </InputLabel>
                            <Select
                                className={classes.inputFilterColumn}
                                multiple
                                value={column.filterValue?.value || []}
                                onChange={(e) => {
                                    const value =
                                        !e.target.value && !e.target.value.length
                                            ? undefined
                                            : {
                                                  stats: column.Header,
                                                  name: column.Header,
                                                  humanValue: listStatuses
                                                      .filter((status) => e.target.value.includes(status.id))
                                                      .map((status) => status.name),
                                                  value: e.target.value
                                              };

                                    setFilter(column.id, value);
                                }}
                                input={<OutlinedInput label='Tag' />}
                                renderValue={(selected) =>
                                    listStatuses
                                        .filter((status) => selected.includes(status.id))
                                        .map((status) => status.name)
                                        .join(', ')
                                }
                            >
                                {listStatuses.map((status) => (
                                    <MenuItem key={status.id} value={status.id} style={{ padding: '0px' }}>
                                        <Checkbox
                                            checked={
                                                column.filterValue && column.filterValue.value
                                                    ? column.filterValue.value.includes(status.id)
                                                    : false
                                            }
                                        />
                                        <ListItemText primary={status.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: translate('t.items'),
            accessor: 'shippingOrderItem',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Export: ({ row }: CellProps) => shippingOrderItemsCount(row.original.shippingOrderItem),
            Cell: ({ row }: CellProps<string>) => shippingOrderItemsCount(row.original.shippingOrderItem)
        },
        {
            Header: translate('t.order_for_customer'),
            accessor: 'customerName',
            disableGroupBy: true,
            aggregate: 'count',
            width: 200,
            Export: ({ row }: CellProps) => (row.original.itrackCustomerId?.name || ''),
            Cell: ({ row: { original } }: CellProps) =>
                original.itrackCustomerId?.name || '---',
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ 
                                    backgroundColor: theme.name === 'Dark' ? '#32383f' : 'white', padding: '0 5px 0 0'
                                }}
                            >
                                {column.Header}
                            </InputLabel>
                            <AsyncPaginate
                                isMulti={true}
                                closeMenuOnSelect={false}
                                isClearable={true}
                                styles={customStyles}
                                options={[]}
                                classNamePrefix='location-select'
                                value={column.filterValue?.selected}
                                loadOptions={loadMoreStockLocation}
                                onChange={(value) => {
                                    const humanValues = value ? value.map((v) => v.name) : [];

                                    setFilter(column.id, {
                                        stats: column.Header,
                                        name: column.Header,
                                        humanValue: humanValues,
                                        value: humanValues,
                                        selected: value
                                    });
                                }}
                                getOptionValue={(option) => option['id']}
                                getOptionLabel={(option) => option['name']}
                                menuPosition='fixed'
                            />
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: translate('t.shipped_by'),
            accessor: 'shippedBy',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            Export: ({ row }: CellProps) =>
                row.original.shippedBy ? row.original.shippedBy.firstname + ' ' + row.original.shippedBy.lastname : '',
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.shippedBy ? (
                    <>
                        <span>{original.shippedBy.firstname}</span> <span>{original.shippedBy.lastname}</span>
                    </>
                ) : (
                    '---'
                )
        },
        {
            Header: translate('t.tracking_number'),
            accessor: 'trackingId',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.trackingId ? (
                    <TextEllipsis title={original.trackingId}> {original.trackingId} </TextEllipsis>
                ) : (
                    '---'
                )
        },
        {
            Header: translate('t.shipper'),
            accessor: 'shipper.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.shipper ? (
                    <TextEllipsis title={original.shipper.name}> {original.shipper.name} </TextEllipsis>
                ) : (
                    '---'
                )
        },
        {
            Header: translate('t.shipped_at'),
            accessor: 'shippedAt',
            disableGroupBy: true,
            aggregate: 'count',
            width: 150,
            disableSortBy: false,
            Cell: ({ row }: CellProps<string>) => fromUTCToTimezone(row.original?.shippedAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.shipped_at_from')}
                            filterName='shippedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.shipped_at_to')}
                            filterName='shippedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            width: 150,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.created_at_from')}
                            filterName='createdAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.created_at_to')}
                            filterName='createdAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'shippedAtFrom',
            isVisible: false
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'shippedAtTo',
            isVisible: false
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'createdAtFrom',
            isVisible: false
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtTo',
            isVisible: false
        },
        {
            Header: translate('t.status_date'),
            accessor: 'statusAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row: { original } }: CellProps<string>) => {
                const history = original.shippingOrderStatusHistory[original.shippingOrderStatusHistory.length - 1];

                return history?.statusAt ? fromUTCToTimezone(history.statusAt, false) : '---';
            }
        }
    ];

    React.useEffect(() => {
        if (stockLocationState && !!Object.keys(stockLocationState).length) {
            setLocationFilter({ locationFilter: `?locationFromName=${stockLocationState.name}` });
        } else if (stockLocationRegionState && !!Object.keys(stockLocationRegionState).length) {
            setLocationFilter({ locationFilter: `?fromRegionId=${stockLocationRegionState.id}` });
        } else {
            setLocationFilter({ locationFilter: '' });
        }
    }, [stockLocationState, stockLocationRegionState]);

    return (
        <>
            <view.OrderHistoryOutboundContent
                data-testid={'OrderHistoryOutbound-testid'}
                columns={columns}
                isLoading={loading}
                detailsShippingOrder={detailsShippingOrder}
                fetchData={getAllShippingOrder}
                fetchDataExport={getForExport}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                extraFiltering={locationFilter}
            />
            {open && <ShippingOrderDetails open={open} closeModal={handleClose} shippingOrderId={shippingOrderId} />}

            {openOpenShippingOrder && (
                <ShippingOrderFiles
                    open={openOpenShippingOrder}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}

            {editOrderOpen && (
                <EditShippingOrder
                    open={editOrderOpen}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                    setRefresh={seRefresh}
                    resendOrder={true}
                />
            )}
        </>
    );
};

export default Wrapper(OrderHistoryOutbound);
