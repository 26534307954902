import React from 'react';
import { ButtonsActionSiteSurveyProps } from './ButtonsActionSiteSurvey.type';
import { Alert, Button, Grid } from '@mui/material';
import NotificationMessage from '../NotificationMessage/NotificationMessage';
import { useTranslation } from 'react-i18next';
import { SurveyFieldRequiresState, SurveyState } from '../../../states/component/Survey';
import { useRecoilValue } from 'recoil';
import { showActionApprove, showActionReturnProgress, showActionSave, showActionSubmit } from '../SurveyHelpers';
import { FromUTCToTimezone } from '../../../helpers/Converters';
import { SiteSurveySteps } from '../../../models/ApiSurvey.type';
// import module

const keyStepValidation = {
    0: 'stepOne',
    1: 'stepTwo',
    2: 'stepThree',
    3: 'stepFour',
    4: 'stepFive'
};

const verifyAllStepsExcept = (stepsObject: SiteSurveySteps, excludedStep: keyof SiteSurveySteps): boolean => {
    for (const step in stepsObject) {
        if (step !== excludedStep) {
            if (!stepsObject[step].validStep) {
                return true;
            }
        }
    }
    return false;
};

const ButtonsActionSiteSurvey: React.FC<ButtonsActionSiteSurveyProps> = ({
    values,
    activeStep,
    publicMode,
    isSubmitting,
    disabledAcceptButton,
    errors,
    handleBack,
    handleNext,
    submitForm,
    handleSubmit,
    resetForm,
    setSubmitting,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyData = useRecoilValue(SurveyState);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const surveyShemaNameValidState = useRecoilValue(SurveyFieldRequiresState);

    return <Grid container>
        <Grid sm={12} md={publicMode ? 5 : 4}>
            {surveyData?.acceptedAt && (
                <Alert
                    severity='info'
                    sx={{
                        padding: '0px 15px',
                        marginRight: '10px',
                        marginTop: '10px'
                    }}
                >
                    <span style={{ marginRight: '5px' }} >
                        {translate(publicMode ? 'p.your_survey_has_submitted' : 'p.survey_has_submitted')}:
                    </span>
                    {fromUTCToTimezone(surveyData?.acceptedAt || '---', false)}
                </Alert>
            )}
        </Grid>
        <Grid sm={12} md={publicMode ? 7 : 8} pt={1} pb={1} justifyContent='flex-end' >
            <Grid container justifyContent='flex-end' >
                <Grid md='auto'>
                    <NotificationMessage />
                </Grid>
                <Grid md='auto'>
                    {activeStep !== 0 && (
                        <Button
                            onClick={() => handleBack(values)}
                            variant='contained'
                            size='small'
                            color='secondary'
                            sx={{ mr: 1 }}
                        >
                            {translate('t.back')}
                        </Button>
                    )}
                    {activeStep < 4 && (
                        <Button
                            onClick={() => handleNext(values)}
                            sx={{ mr: 1 }}
                            variant='contained'
                            color='secondary'
                            size='small'
                        >
                            {translate('t.next')}
                        </Button>
                    )}
                    {showActionSave(surveyData, publicMode) && (
                        <Button
                            onClick={() => {
                                submitForm();
                            }}
                            sx={{ mr: 1 }}
                            variant='contained'
                            size='small'
                            disabled={isSubmitting}
                        >
                            {translate('t.save')}
                        </Button>
                    )}
                    {publicMode && showActionSubmit(surveyData) && (
                        <Button
                            onClick={() => {
                                handleSubmit(values, activeStep + 1, resetForm, setSubmitting, true);
                            }}
                            sx={{ mr: 1 }}
                            variant='contained'
                            size='small'
                            disabled={
                                isSubmitting || 
                                (
                                    errors || 
                                    verifyAllStepsExcept(surveyShemaNameValidState, keyStepValidation[activeStep])
                                )
                            }
                        >
                            {translate('t.submit')}
                        </Button>
                    )}
                    {!publicMode && showActionReturnProgress(surveyData) && (
                        <Button
                            onClick={() => {
                                setModalReturnToProgress(true);
                            }}
                            sx={{ mr: 1 }}
                            variant='contained'
                            size='small'
                            disabled={isSubmitting}
                        >
                            {translate('t.return_to_progress')}
                        </Button>
                    )}
                    {!publicMode && activeStep === 4 && showActionApprove(surveyData) && (
                        <Button
                            onClick={() => {
                                handleSubmit(values, 5, resetForm, setSubmitting, true);
                            }}
                            sx={{ mr: 1 }}
                            variant='contained'
                            size='small'
                            disabled={
                                isSubmitting ||
                                (
                                    disabledAcceptButton ||
                                    verifyAllStepsExcept(surveyShemaNameValidState, 'stepFive')
                                )
                            }
                        >
                            {translate('t.accept')}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
};

export default ButtonsActionSiteSurvey;