import React, { useEffect, useMemo, useState } from 'react';
import * as view from './LocationDropDown.view';
import * as type from './LocationDropDown.type';
import Wrapper from '../../helpers/wrapper';
import { IStockLocation, LocationWithPagnator } from '../../models/ApiStockLocation.type';
import ApiStockLocation from '../../api/ApiStockLocation';
import { LoadMore } from './LocationDropDown.type';
import { OrderAlphabetically } from '../../helpers/converter';
import ApiLocationType from '../../api/ApiLocationType';
import { LocationType } from '../../models/ApiLocationType.type';
// import module

const apiStockLocation = new ApiStockLocation();
const apiStockLocationType = new ApiLocationType();

const LocationDropDown: React.FC<type.LocationDropDownProps> = (props) => {
    const [dataLocations, setDataLocations] = useState<IStockLocation[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [locationType, setLocationType] = useState<LocationType[]>([]);
    const [selectedLocationType, setSelectedLocationType] = useState<number[]>([]);
    const [resetPage, setResetPage] = useState<boolean>(false);

    const getNotLocationUrl = useMemo(() => {
        const getNotSelected = locationType.filter(
            (type) => !selectedLocationType.includes(type.id)).map(type => type.id
            );
        return getNotSelected.length ? `&notLocationType[]=${getNotSelected.join('&notLocationType[]=')}` : '';
    }, [selectedLocationType, locationType]);

    const loadMoreLocation = async (search, _, { page }): Promise<LoadMore> => {
        const hasResetFlag = resetPage;

        let url = (props.extendUrl ?? '');
        if (!url.includes('?')) {
            url = url + '?';
        }

        const { items }: LocationWithPagnator = await apiStockLocation.getWithPagination(
            hasResetFlag ? 1 : page,
            10,
            '',
            '',
            search,
            {},
            `${url}${getNotLocationUrl}`
        );

        let newItemsList: IStockLocation[] = items;
        setDataLocations([]);

        if (newItemsList && !!newItemsList.length) {
            newItemsList = OrderAlphabetically<IStockLocation>(items, 'name');
            if (props.hideOption) {
                newItemsList = newItemsList.filter((locationFrom) => locationFrom.id !== props.hideOption);
            }

            for (let i = 0; i < newItemsList.length; i++) {
                if (newItemsList[i].locationType?.name) {
                    newItemsList[i].name = newItemsList[i].name + ' [' + newItemsList[i].locationType?.name + ']';
                }
            }

            setDataLocations(newItemsList);
        }

        setResetPage(false);

        return {
            options: newItemsList,
            hasMore: newItemsList.length >= 1,
            additional: { page: hasResetFlag ? 2 : page + 1 }
        };
    };

    const handleSetSelectedLocationType = (locations: number[]): void => {
        setSelectedLocationType(locations as number[]);
        setDataLocations([]);
        setResetPage(true);
    };

    const getLocationType = async () => {
        const locationTypeData = await apiStockLocationType.getLocationTypeCodeBook();
        setLocationType(locationTypeData);
        setSelectedLocationType(locationTypeData.filter(
            (type) => type.id === 3 || type.id === 4).map((type) => type.id)
        );
    };

    useEffect(() => {
        getLocationType();
    }, []);

    return (
        <view.LocationDropDownContent
            data-testid='LocationDropDown-testid'
            loadMoreLocation={loadMoreLocation}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            locationType={locationType}
            selectedLocationType={selectedLocationType}
            handleSetSelectedLocationType={handleSetSelectedLocationType}
            {...props}
            locationList={
                props.hideOption
                    ? dataLocations.filter((locationFrom) => locationFrom.id !== props.hideOption)
                    : dataLocations
            }
        />
    );
};

export default Wrapper(LocationDropDown);
