import { Button, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import * as style from './ShipmentAcceptancePublic.style';
import * as type from './ShipmentAcceptancePublic.type';
import ChangeLanguage from '../../components/ChangeLanguage/ChangeLanguage';
import UiTable from '../../components/Ui/UiTable/UiTable';
import { useStyles } from '../../styled/global.style';
import { Loading } from '../../helpers/Loading';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
// import module

export const ShipmentAcceptancePublicContent: React.FC<type.ShipmentAcceptancePublicViewProps> = (props): any => {
    const classes = useStyles();
    const { t: translate } = useTranslation();

    return (
        <style.ShipmentAcceptancePublicContent data-testid='ShipmentAcceptancePublicContent'>
            {props.loadingPage ? (
                <Loading />
            ) : (
                <div className={classes.root}>
                    <Grid container justify-suppressContentEditableWarning='space-around' direction='row'>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px' }}>
                            <div className='titleShip'>
                                <Grid container justifyContent='space-around' direction='row'>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <ChangeLanguage />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <h3> {translate('t.shipment_acceptance')}</h3>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <style.ContainerHeaderLocation>
                                            <Grid container justifyContent='space-around' direction='row'>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <div className='text-left' style={{ fontSize: '13px' }}>
                                                        <strong style={{ fontSize: '16px' }}>
                                                            {translate('t.issued_by')}:{' '}
                                                        </strong>
                                                        {props.shippingOrder?.createdBy?.firstname}{' '}
                                                        {props.shippingOrder?.createdBy?.lastname}
                                                    </div>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                    <style.TitleLocation>
                                                        <strong style={{ fontSize: '14px' }}>
                                                            {translate('t.from')}:{' '}
                                                        </strong>{' '}
                                                        {props.shippingOrder?.locationFrom?.name}
                                                    </style.TitleLocation>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                    <style.TitleLocation>
                                                        <strong style={{ fontSize: '14px' }}>
                                                            {translate('t.to')}:{' '}
                                                        </strong>{' '}
                                                        {props.shippingOrder?.locationTo?.name}{' '}
                                                    </style.TitleLocation>
                                                </Grid>
                                            </Grid>
                                        </style.ContainerHeaderLocation>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent='flex-start' direction='row'>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className='dataHeaderAcceptance'>
                                        <Grid lg={3} md={3} sm={6} xs={12} className={classes.textField}>
                                            <h3>{translate('t.tracking_number')}: </h3>
                                            <span className='value_form_shippingorder_details'>
                                                {props.shippingOrder?.trackingId ?? '---'}
                                            </span>
                                        </Grid>
                                        <Grid lg={3} md={3} sm={6} xs={12} className={classes.textField}>
                                            <h3>{translate('t.shipper')}: </h3>
                                            <span className='value_form_shippingorder_details'>
                                                {props.shippingOrder?.shipper?.name ?? '---'}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <Paper elevation={1}>
                                <Scrollbars
                                    autoHeight
                                    autoHide
                                    autoHeightMin={'calc(100vh - 270px)'}
                                    renderThumbVertical={({ style, ...props }) => (
                                        <div {...props} className='scrollYcustom' style={{ ...style }} />
                                    )}
                                >
                                    <UiTable
                                        title='Shipping Acceptance Items List'
                                        classesTable='containerDataTable'
                                        hidePagination={true}
                                        hideForceRefresh={true}
                                        loading={props.isLoading}
                                        noPagination={true}
                                        columns={props.columns}
                                        data={props.itemsLists}
                                        hiddenColumns={[]}
                                        globalFilter={true}
                                        customButtonAdd={
                                            <>
                                                <strong>
                                                    {translate('t.shipping_order')}: {props.shippingOrderId}
                                                </strong>
                                                <style.TitleHeaderTable>
                                                    {`${translate('t.invoice_number')}: `}
                                                    {props.shippingOrder?.saleOrderId}
                                                </style.TitleHeaderTable>
                                            </>
                                        }
                                    />
                                </Scrollbars>

                                <div className='idOrder text-right' style={{ paddingBottom: '10px' }}>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        onClick={() => props.handleAcceptance()}
                                        disabled={props.loadingButton}
                                    >
                                        {props.loadingButton ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            translate('t.save')
                                        )}
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            )}
            <style.ShippingOrderAcceptanPubliccebody />
        </style.ShipmentAcceptancePublicContent>
    );
};
