import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { Delete, Edit, VpnKey } from '@mui/icons-material';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as type from './Roles.type';
import * as view from './Roles.view';
import ApiRoles from '../../api/ApiRoles';
import { Success } from '../Popup/Popup';
import { ColumnsTable, ModalType, ModelStockRole, UserPermission } from '../../types/common';
import Wrapper from '../../helpers/wrapper';
import { Roles as RolesData } from '../../states/component/Role';
import { Permissions, User } from '../../states/global/User';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { removeSpaces } from '../../helpers/converter';
import { ModelRole, StockRoleResponseAllData } from '../../models/ApiRoles.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { FullDataUser } from '../User/User.type';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { useTranslation } from 'react-i18next';
// import module

const api = new ApiRoles();

const Roles: React.FC<type.RolesProps> = () => {
    const setStockData = useSetRecoilState<ModelRole[]>(RolesData);
    const setdataPermissions = useSetRecoilState<string[]>(Permissions);
    const user = useRecoilValue<FullDataUser | null>(User);
    const { t: translate } = useTranslation();
    const getDateMask = useRecoilValue(GetDateMask);
    const [open, setOpen] = useState<boolean>(false);
    const [openAssignig, setOpenAssignig] = useState<boolean>(false);
    const [role, setRole] = useState<ModelStockRole>();
    const [permissions, setPermissions] = useState<type.IUserPermissions[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [valuesInitForm, setvaluesInitForm] = useState({ name: '' });
    const [permissionsIDs, setPermissionsIDs] = useState<number[]>([]);
    const [valuesInitAsig, setValuesInitAsig] = useState<number[]>([]);
    const [rolesAsign, setRolesAsign] = useState<type.IUserPermissions[]>([]);
    const [isPermissosByRole, setIsPermissosByRole] = useState(false);
    const [dataModal, setdataModal] = useState<ModalType>({ isAdd: true, title: '', id: null });
    const [selectedIndex, setSelectedIndex] = React.useState('');
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loadingModalPermissions, setLoadingModalPermissions] = useState<boolean>(false);

    const getAllRoles = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setStockData([]);
            const { items, paginator }: StockRoleResponseAllData = await api.getAll(page, limit, order, filter);

            !!items.length && setStockData(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setStockData]
    );

    const getForExport = async (order, filter) => {
        let pages = 1;
        let items: ModelRole[] = [];

        for (let i = 1; i <= pages; i++) {
            const data = await api.getAll(i, 200, order, filter);

            pages = data.paginator.pageCount;
            items = items.concat(data.items);
        }

        return items;
    };

    const enabledPermission = (
        userPermissions: type.IUserPermissions,
        permission: UserPermission,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let newList: type.IUserPermissions[] | [] = [];
        if (e.target.checked) setPermissionsIDs([...permissionsIDs, permission.id]);
        else setPermissionsIDs(permissionsIDs.filter((i) => i !== permission.id));

        if (permissions.length) {
            newList = permissions.map((item) => {
                if (userPermissions.id === item.id && !!item.userPermission.length) {
                    item.userPermission = item.userPermission.map((perm) => {
                        if (permission.id === perm.id) {
                            perm.enabled = !perm.enabled;
                            return perm;
                        } else {
                            return perm;
                        }
                    });
                    return item;
                } else {
                    return item;
                }
            });
        }
        setPermissions(newList);
    };

    const openModal = (ActionType = 'create', id: number | null = null): void => {
        setdataModal({
            isAdd: ActionType !== 'edit',
            title:
                ActionType !== 'edit'
                    ? translate('t.add_new_role')
                    : translate('t.role_update') + ' - #' + id,
            id: ActionType !== 'edit' ? null : id
        });
        setTimeout(() => setOpen(true), 100);
    };

    const handleClose = (): void => {
        setvaluesInitForm({ name: '' });
        setdataModal({ isAdd: true, title: '', id: null });
        setValuesInitAsig([]);
        setPermissionsIDs([]);
        setRolesAsign([]);
        setIsPermissosByRole(false);
        setOpen(false);
        setOpenAssignig(false);
        setLoadingModalPermissions(false);
    };

    const handleSubmit = async (
        e: { createdBy: number | null | string; name: string },
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting
    ): Promise<void> => {
        // e.createdBy = user && user.id;
        e.name = removeSpaces(e.name);

        try {
            let idRol = 0;
            if (dataModal.id) {
                if (typeof dataModal.id === 'string') {
                    idRol = parseInt(dataModal.id);
                }
                idRol = dataModal.id;
            } else {
                idRol = 0;
            }
            const { data }: type.ModelStockRole = dataModal.isAdd ? await api.create(e) : await api.patch(idRol, e);

            if (data && !data.role) {
                throw data.role;
            }
            setRefresh(true);
            resetForm({});

            Success({
                text: dataModal.isAdd
                    ? `${translate('t.role_created_successfully')} - ${data && data.role.name}`
                    : `${translate('t.role_successfully_updated')} - ${data && data.role.name}`
            });

            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editRole = async (row: ModelStockRole) => {
        openModal('edit', row.id);

        const { role }: type.ModelStockRole = await api.getById(row.id);

        if (!role) {
            throw role;
        }
        setvaluesInitForm({ name: role.name });
    };

    const assigning_permissions = async (row: ModelStockRole) => {
        setLoadingModalPermissions(true);
        setOpenAssignig(true);
        setRole(row);
        setdataModal({ isAdd: false, title: '', id: row.id });
        const {
            data: { items }
        }: type.IUserPermissions = await api.getGeneral('stock/user-permission?limit=200');

        const { data }: type.IUserPermissions = await api.getGeneral(`stock/user-permission/role/${row.id}?limit=200`);

        const {
            data: { codeBook: permissionCategoryData }
        }: type.IUserPermissionsCodeBook = await api.getGeneral('stock/user-permission-category');

        if (!data.items) {
            throw data.items;
        }
        if (!items) {
            throw items;
        }
        if (!permissionCategoryData) {
            throw permissionCategoryData;
        }

        let PermissionsDataIds: number[] = [];
        if (data.items.length) {
            setRolesAsign(data.items);
            PermissionsDataIds = data.items.map((i) => i.id);
            setValuesInitAsig(PermissionsDataIds);
            setPermissionsIDs(PermissionsDataIds);
            setIsPermissosByRole(true);
        }
        let newLists: type.IUserPermissions[] = [];

        if (permissionCategoryData.length) {
            newLists = permissionCategoryData.map((permissionCategoryItem) => {
                if (permissionCategoryItem.userPermission.length) {
                    permissionCategoryItem.userPermission = permissionCategoryItem.userPermission.map((perm) => {
                        if (PermissionsDataIds.find((PermissionsDataId) => PermissionsDataId === perm.id)) {
                            perm.enabled = true;
                            return perm;
                        } else {
                            perm.enabled = false;
                            return perm;
                        }
                    });
                    return permissionCategoryItem;
                } else {
                    return permissionCategoryItem;
                }
            });
        }
        setPermissions(newLists);
        setLoadingModalPermissions(false);
    };

    const handleSubmitAssignedPermissions = async (
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting: (submitting: boolean) => void
    ): Promise<void> => {
        if (!role) {
            throw role;
        }

        try {
            const { data: userPermissionRoleData }: type.ModelStockRoleData = isPermissosByRole
                ? await api.patchGeneral('stock/user-permission-role/role', role.id, { permission: permissionsIDs })
                : await api.createGeneral(`stock/user-permission-role/role/${role.id}`, {
                      permission: permissionsIDs
                  });

            if (!userPermissionRoleData) {
                throw userPermissionRoleData;
            }

            if (userPermissionRoleData && !!userPermissionRoleData.length) {
                let currentPermissions: string[] = [];
                currentPermissions = userPermissionRoleData.map((permission) => permission.userPermission.name);

                if (dataModal.id === user?.role?.id)
                    !!currentPermissions.length && setdataPermissions(currentPermissions);
            }
            setRefresh(true);
            setPermissionsIDs([]);

            Success({ text: `${translate('t.permission_assigned_successfully')} [ID ${role.id}]` });

            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const confirmDeleteRow = (row) =>
        popUpConfirm(
            translate('t.role') + `: ${row.name}`,
            translate('p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: translate('t.yes'),
                no: translate('t.no')
            }
        );

    const deleteRow = async (onClose, row: type.ModelStockRole) => {
        try {
            const stockRole: string = await api.remove(row.id);

            if (!stockRole) {
                throw stockRole;
            }
            setRefresh(true);
            onClose();
            Success({ text: `${translate('t.role_successfully_removed')} - ${row.name}` });
        } catch (error) {
            onClose();
            console.warn('Error to send data ', error);
        }
    };

    const OpenManuPermission = (index): void =>
        selectedIndex === index ? setSelectedIndex('') : setSelectedIndex(index);

    const ActionsButtons = (original: ModelStockRole): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => editRole(original)}>
                    <Edit />
                    <span>{translate('t.edit')}</span>
                </Typography>

                <Typography className='dataTableActionsBtn' onClick={() => assigning_permissions(original)}>
                    <VpnKey />
                    <span>{translate('t.assigning_permissions')}</span>
                </Typography>

                <Typography className={'dataTableActionsBtn '} onClick={() => confirmDeleteRow(original)}>
                    <Delete />
                    <span>{translate('t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: translate('t.name'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtTo'
        },
        {
            Header: translate('t.users'),
            accessor: 'usersCount',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps<ModelStockRole>) => fromUTCToTimezone(row.original?.createdAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.created_at_from')}
                            filterName='createdAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={translate('t.created_at_to')}
                            filterName='createdAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    return (
        <view.RolesContent
            data-testid={'Roles-testid'}
            openModal={openModal}
            open={open}
            isLoading={loading}
            closeModal={handleClose}
            handleSubmit={handleSubmit}
            columns={columns}
            valuesInitForm={valuesInitForm}
            dataModal={dataModal}
            fullScreenModal={getFullScreenModal()}
            openAssignig={openAssignig}
            role={role}
            stock_permissions={permissions}
            handleSubmitAssignedPermissions={handleSubmitAssignedPermissions}
            valuesInitAsig={valuesInitAsig}
            rolesAsign={rolesAsign}
            OpenManuPermission={OpenManuPermission}
            selectedIndex={selectedIndex}
            enabledPermission={enabledPermission}
            fetchData={getAllRoles}
            fetchDataExport={getForExport}
            total={total}
            pageCount={pageCount}
            refresh={refresh}
            loadingModalPermissions={loadingModalPermissions}
        />
    );
};

export default Wrapper(Roles);
