import React, { useState } from 'react';
import { SiteSurveyStepOneForm, StepOneViewProps } from './StepOne.type';
import { AccordionCustomer, UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChipsAutocompleteElement, InputElement } from '../../FormElements';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { SurveyFieldRequiresState } from '../../../states/component/Survey';
import { Loading } from '../../../helpers/Loading';
import { LoadingButton } from '@mui/lab';
import UiPhoneInputNumber from '../../Ui/UiPhoneInputNumber/UiPhoneInputNumber';
import { useTranslation } from 'react-i18next';
import ButtonsActionSiteSurvey from '../ButtonsActionSiteSurvey/ButtonsActionSiteSurvey';
import { getTimezoneOffsetFormatted } from '../../../helpers/Converters';
// import module

export const StepOneContent: React.FC<StepOneViewProps> = ({
    valuesInitForm,
    timeZones,
    activeStep,
    publicMode,
    bottonHiddenUpdate,
    emails,
    loadingEmails,
    handleBack,
    handleNext,
    handleSubmit,
    updateSurveyTemporal,
    setEmails,
    resendSurvey,
    updateEmails,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyFieldRequires = useRecoilValue(SurveyFieldRequiresState);
    const [isValid, setIsValid] = useState<string | null>(null);
    const [isValidCoordinatorTelephone, setIsValidCoordinatorTelephone] = useState<string | null>(null);
    const [isValidMineITTelephone, setIsValidMineITTelephone] = useState<string | null>(null);
    const [isValidMineDispatchTelephone, setIsValidMineDispatchTelephone] = useState<string | null>(null);
    const [isValidTyreProviderTelephone, setIsValidTyreProviderTelephone] = useState<string | null>(null);

    const getStateValidPhone = (input: string) => {
        switch (input) {
            case 'projectCoordinatorTelephone':
                return isValidCoordinatorTelephone;
                break;
            case 'mineITTelephone':
                return isValidMineITTelephone;
                break;
            case 'mineDispatchTelephone':
                return isValidMineDispatchTelephone;
                break;
            case 'tyreProviderTelephone':
                return isValidTyreProviderTelephone;
                break;
        }
    };

    const getSetStateValidPhone = (input: string) => {
        switch (input) {
            case 'projectCoordinatorTelephone':
                return setIsValidCoordinatorTelephone;
                break;
            case 'mineITTelephone':
                return setIsValidMineITTelephone;
                break;
            case 'mineDispatchTelephone':
                return setIsValidMineDispatchTelephone;
                break;
            case 'tyreProviderTelephone':
                return setIsValidTyreProviderTelephone;
                break;
        }
    };

    const validationSchema = Yup.object().shape({
        mineOwner: Yup.string(),
        location: Yup.string(),
        startDate: Yup.string()
        .nullable()
        .test(
          'is-yyyy-mm-dd-valid',
          translate('t.invalid_date') ?? '',
          (value) => {
            if (!value) {
              return true;
            }
            return moment(value, 'YYYY-MM-DD', true).isValid();
          }
        ),
        siteName: Yup.string(),
        timeZone: Yup.string().nullable(),
        surveyDate: Yup.string()
        .nullable()
        .test(
          'is-yyyy-mm-dd-valid',
          translate('t.invalid_date') ?? '',
          (value) => {
            if (!value) {
              return true;
            }
            return moment(value, 'YYYY-MM-DD', true).isValid();
          }
        ),
        // shippingAttention: Yup.string(),
        // shippingEmail: Yup.string().email(translate('t.invalid_email') ?? ''),
        // shippingTelephone: Yup.string(),
        // shippingAddress: Yup.string(),
        customerAttention: Yup.string(),
        customerEmail: Yup.string().email(translate('t.invalid_email') ?? ''),
        customerTelephone: Yup.string(),
        customerAddress: Yup.string(),
        projectCoordinatorName: Yup.string(),
        projectCoordinatorEmail: Yup.string().email(translate('t.invalid_email') ?? ''),
        projectCoordinatorTelephone: Yup.string(),
        mineITName: Yup.string(),
        mineITEmail: Yup.string().email(translate('t.invalid_email') ?? ''),
        mineITTelephone: Yup.string(),
        mineDispatchName: Yup.string(),
        mineDispatchEmail: Yup.string().email(translate('t.invalid_email') ?? ''),
        mineDispatchTelephone: Yup.string(),
        tyreProviderName: Yup.string(),
        tyreProviderEmail: Yup.string().email(translate('t.invalid_email') ?? ''),
        tyreProviderTelephone: Yup.string()
    });

    const initialErrors = Object.keys(surveyFieldRequires.stepOne.fileds).reduce((acc, key) => {
        if (!surveyFieldRequires.stepOne.fileds[key].valid && !valuesInitForm[key]) {
            acc[key] = translate('p.this_field_is_required');
        }
        return acc;
    }, {});

    if (timeZones.length === 0) return <Loading />;

    return (
        <Formik
            initialValues={valuesInitForm}
            initialErrors={initialErrors}
            onSubmit={(values: SiteSurveyStepOneForm, actions) => {
                handleSubmit(values, 1, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
            validate={() => {
                return initialErrors;
            }}
            validateOnBlur={true}
        >
            {(propsF: FormikProps<SiteSurveyStepOneForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm,
                    resetForm,
                    setSubmitting,
                    setTouched
                } = propsF;
                Object.keys(touched).forEach((key) => {
                    try {
                        validationSchema.validateSyncAt(key, values);
                        if (values[key]) {
                            delete errors[key];
                        }
                    } catch (validationError: any) {
                        errors[key] = validationError.errors[0];
                    }
                });
                if(values.customerTelephone) {
                    delete errors['customerTelephone'];
                }
                if(values.timeZone) {
                    delete errors['timeZone'];
                }
                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                                {/* SITE INFORMATION && SHIPPING ADDRESS (FOR STOCK LOCATION) */}
                                <Grid item md={6}>
                                    {/* SITE INFORMATION */}
                                    <AccordionCustomer defaultExpanded >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography sx={{ textTransform: 'uppercase' }} variant='subtitle1'>
                                                {translate('t.site_information')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <InputElement
                                                    label={translate('t.mine_owner')}
                                                    name='mineOwner'
                                                    value={values.mineOwner}
                                                    error={errors.mineOwner}
                                                    touched={touched.mineOwner}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    fullWidth
                                                />
                                                <InputElement
                                                    label={translate('t.location')}
                                                    name='location'
                                                    value={values.location}
                                                    error={errors.location}
                                                    touched={touched.location}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    fullWidth
                                                />
                                                <Grid item xs={12} md={6}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <DatePicker
                                                            label={
                                                                <span className='fieldRequired'>
                                                                    <i>*</i> {translate('t.start_date')}
                                                                </span>
                                                            }
                                                            disableFuture={false}
                                                            value={values.startDate}
                                                            disablePast={true}
                                                            onChange={(newValue) => {
                                                                const newValueV = newValue ?
                                                                moment(newValue, 'YYYY-MM-DD')
                                                                    .format('YYYY-MM-DD')
                                                                : '';

                                                                setFieldValue(
                                                                    'startDate',
                                                                    newValueV === 'Invalid date' 
                                                                        ? ''
                                                                        : newValueV
                                                                );
                                                                
                                                                setTouched({ ...touched, 'startDate': true });
                                                            }}
                                                            mask='____-__-__'
                                                            inputFormat='YYYY-MM-DD'
                                                            views={['year', 'month', 'day']}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='startDate'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.startDate
                                                                                ? errors.startDate
                                                                                : errors.startDate
                                                                            : ''
                                                                    }
                                                                    error={!!errors.startDate}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <InputElement
                                                    label={translate('t.site_name')}
                                                    name='siteName'
                                                    value={values.siteName}
                                                    error={errors.siteName}
                                                    touched={touched.siteName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    fullWidth
                                                />
                                                <Grid item xs={12} md={6} paddingRight='10px' >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <DatePicker
                                                            label={
                                                                <span className='fieldRequired'>
                                                                    <i>*</i> {translate('t.survey_date')}
                                                                </span>
                                                            }
                                                            disableFuture={false}
                                                            value={values.surveyDate}
                                                            disablePast={true}
                                                            onChange={(newValue) => {
                                                                setFieldValue(
                                                                    'surveyDate',
                                                                    newValue ?
                                                                        moment(newValue, 'YYYY-MM-DD')
                                                                            .format('YYYY-MM-DD')
                                                                        : ''
                                                                );
                                                                setTouched({ ...touched, 'surveyDate': true });
                                                            }}
                                                            mask='____-__-__'
                                                            inputFormat='YYYY-MM-DD'
                                                            views={['year', 'month', 'day']}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    name='surveyDate'
                                                                    size='small'
                                                                    fullWidth
                                                                    helperText={
                                                                        params.error
                                                                            ? errors.surveyDate
                                                                                ? errors.surveyDate
                                                                                : errors.surveyDate
                                                                            : ''
                                                                    }
                                                                    error={!!errors.surveyDate}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12}>
                                                    <Autocomplete
                                                        options={timeZones}
                                                        getOptionLabel={(timeZone) => {
                                                            // eslint-disable-next-line max-len
                                                            const offset = getTimezoneOffsetFormatted(timeZone.timezoneName);
                                                            return `${offset} ${timeZone.timezoneFormatted}`;
                                                        }}
                                                        onChange={(e, timeZone) => {
                                                            setFieldValue('timeZone', timeZone ? timeZone?.id : null);
                                                        }}
                                                        defaultValue={
                                                            timeZones && !!timeZones.length
                                                                // eslint-disable-next-line max-len
                                                                ? timeZones.find((timeZone) => timeZone?.id === values.timeZone)
                                                                : null
                                                        }
                                                        size='small'
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={
                                                                    <span className='fieldRequired'>
                                                                        <i>*</i> {translate('t.timezone')}
                                                                    </span>
                                                                }
                                                                variant='outlined'
                                                                name='timezone'
                                                                value={values.timeZone}
                                                                error={!!errors.timeZone}
                                                                data-testid='Survey-LocalLimezone-Autocomplete'
                                                            />
                                                        )}
                                                    />
                                                    {!!errors.timeZone && (
                                                        <FormHelperText error id='error-timezone'>
                                                            {errors.timeZone}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                                {!publicMode && (
                                                    <>
                                                        <ChipsAutocompleteElement
                                                            label={translate('t.email')}
                                                            name='email'
                                                            value={emails
                                                                .split(',')
                                                                .filter((email) => email !== '')}
                                                            onChange={(e, value: any) => {
                                                                setEmails(value.join(','));
                                                            }}
                                                            disabled={loadingEmails}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            required={true}
                                                        />
                                                        <Grid item md={12}>
                                                            <LoadingButton
                                                                onClick={() => updateEmails()}
                                                                variant='contained'
                                                                size='small'
                                                                sx={{ mr: 1 }}
                                                                disabled={loadingEmails || Boolean(emails) === false}
                                                            >
                                                                {`${translate('t.save')} 
                                                                ${translate('t.email')}`}
                                                            </LoadingButton>
                                                            <LoadingButton
                                                                onClick={() => resendSurvey()}
                                                                variant='contained'
                                                                size='small'
                                                                disabled={loadingEmails || Boolean(emails) === false}
                                                            >
                                                                {translate('t.resend')}
                                                            </LoadingButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionCustomer>

                                    {/* SHIPPING ADDRESS (FOR STOCK LOCATION) */}
                                    {/* <AccordionCustomer defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography sx={{ textTransform: 'uppercase' }} variant='subtitle1'>
                                                {translate('t.shipping_address_stock')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <InputElement
                                                    label={translate('t.shipping_attention')}
                                                    name='shippingAttention'
                                                    value={values.shippingAttention}
                                                    error={errors.shippingAttention}
                                                    touched={touched.shippingAttention}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={false}
                                                    fullWidth
                                                />
                                                <InputElement
                                                    label={translate('t.shipping_email')}
                                                    name='shippingEmail'
                                                    value={values.shippingEmail}
                                                    error={errors.shippingEmail}
                                                    touched={touched.shippingEmail}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={false}
                                                    fullWidth
                                                />
                                                <InputElement
                                                    label={translate('t.shipping_telephone')}
                                                    name='shippingTelephone'
                                                    value={values.shippingTelephone}
                                                    error={errors.shippingTelephone}
                                                    touched={touched.shippingTelephone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={false}
                                                    fullWidth
                                                />
                                                <InputElement
                                                    label={translate('t.shipping_address')}
                                                    name='shippingAddress'
                                                    value={values.shippingAddress}
                                                    error={errors.shippingAddress}
                                                    touched={touched.shippingAddress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                />
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionCustomer> */}
                                </Grid>

                                {/* CUSTOMER ADDRESS (FOR CUSTOMS CLEARANCE) */}
                                <Grid item md={6}>
                                    <AccordionCustomer defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography sx={{ textTransform: 'uppercase' }} variant='subtitle1'>
                                                {translate('t.customer_address_stock')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <InputElement
                                                    label={translate('t.customer_name')}
                                                    name='customerAttention'
                                                    value={values.customerAttention}
                                                    error={errors.customerAttention}
                                                    touched={touched.customerAttention}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    fullWidth
                                                />
                                                <InputElement
                                                    label={translate('t.customer_email')}
                                                    name='customerEmail'
                                                    value={values.customerEmail}
                                                    error={errors.customerEmail}
                                                    touched={touched.customerEmail}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    fullWidth
                                                />
                                                <Grid item md={6} xs={12}>
                                                    <UiPhoneInputNumber
                                                        value={values.customerTelephone}
                                                        setFieldValue={setFieldValue}
                                                        international={true}
                                                        className='PhoneInputCustom'
                                                        defaultCountry=''
                                                        placeholder={`* ${translate('t.customer_telephone')}`}
                                                        name='customerTelephone'
                                                        setIsValid={setIsValid}
                                                        isValid={isValid}
                                                        required={true}
                                                        error={errors.customerTelephone}
                                                    />
                                                </Grid>
                                                <InputElement
                                                    label={translate('t.customer_address')}
                                                    name='customerAddress'
                                                    value={values.customerAddress}
                                                    error={errors.customerAddress}
                                                    touched={touched.customerAddress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    required={true}
                                                />
                                            </Grid>
                                        </AccordionDetails>
                                    </AccordionCustomer>
                                </Grid>

                                {/* CONTACT INFORMATION */}
                                <Grid item md={12}>
                                    <AccordionCustomer defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant='subtitle1'>
                                                <Typography sx={{ textTransform: 'uppercase' }} variant='subtitle1'>
                                                    {translate('t.contact_information')}
                                                </Typography>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {[
                                                {
                                                    title: 'project_coordinator',
                                                    fields: [
                                                        {
                                                            label: 'name',
                                                            value: 'projectCoordinatorName'
                                                        },
                                                        {
                                                            label: 'email',
                                                            value: 'projectCoordinatorEmail'
                                                        },
                                                        {
                                                            label: 'telephone',
                                                            value: 'projectCoordinatorTelephone'
                                                        }
                                                    ]
                                                },
                                                {
                                                    title: 'mine_it',
                                                    fields: [
                                                        {
                                                            label: 'name',
                                                            value: 'mineITName'
                                                        },
                                                        {
                                                            label: 'email',
                                                            value: 'mineITEmail'
                                                        },
                                                        {
                                                            label: 'telephone',
                                                            value: 'mineITTelephone'
                                                        }
                                                    ]
                                                },
                                                {
                                                    title: 'mine_dispatch',
                                                    fields: [
                                                        {
                                                            label: 'name',
                                                            value: 'mineDispatchName'
                                                        },
                                                        {
                                                            label: 'email',
                                                            value: 'mineDispatchEmail'
                                                        },
                                                        {
                                                            label: 'telephone',
                                                            value: 'mineDispatchTelephone'
                                                        }
                                                    ]
                                                },
                                                {
                                                    title: 'tyre_provider',
                                                    fields: [
                                                        {
                                                            label: 'name',
                                                            value: 'tyreProviderName'
                                                        },
                                                        {
                                                            label: 'email',
                                                            value: 'tyreProviderEmail'
                                                        },
                                                        {
                                                            label: 'telephone',
                                                            value: 'tyreProviderTelephone'
                                                        }
                                                    ]
                                                }
                                            ].map((group) => (
                                                <Box key={group.title} sx={{ mb: 2 }}>
                                                    <Typography variant='subtitle2' sx={{ mb: 2 }} fontWeight='bold'>
                                                        {translate(`t.${group.title}`)}
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        {group.fields.map((field) => {
                                                            if (field.label === 'telephone') {
                                                                return (
                                                                    <Grid item md={4} lg={4} sm={6} xs={12}>
                                                                        <UiPhoneInputNumber
                                                                            value={values[field.value]}
                                                                            setFieldValue={setFieldValue}
                                                                            international={true}
                                                                            className='PhoneInputCustom'
                                                                            defaultCountry=''
                                                                            placeholder={translate(`t.${field.label}`)}
                                                                            name={field.value}
                                                                            setIsValid={getSetStateValidPhone(
                                                                                field.value
                                                                            )}
                                                                            isValid={getStateValidPhone(field.value)}
                                                                            required={true}
                                                                            error={errors[field.value]}
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }

                                                            return (
                                                                <InputElement
                                                                    key={field.value}
                                                                    label={translate(`t.${field.label}`)}
                                                                    name={field.value}
                                                                    value={values[field.value]}
                                                                    error={errors[field.value]}
                                                                    touched={touched[field.value]}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={false}
                                                                    fullWidth
                                                                    md={4}
                                                                    lg={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                />
                                                            );
                                                        })}
                                                    </Grid>
                                                </Box>
                                            ))}
                                        </AccordionDetails>
                                    </AccordionCustomer>
                                </Grid>
                            </Grid>
                        </UiScrollbarsSiteSurvey>
                        <ButtonsActionSiteSurvey
                            activeStep={activeStep}
                            values={values}
                            publicMode={publicMode}
                            isSubmitting={isSubmitting}
                            errors={Object.keys(errors).length > 0}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            submitForm={submitForm}
                            handleSubmit={handleSubmit}
                            resetForm={resetForm}
                            setSubmitting={setSubmitting}
                            setModalReturnToProgress={setModalReturnToProgress}
                        />
                        <Button
                            onClick={() => updateSurveyTemporal(values)}
                            sx={{ display: 'none' }}
                            variant='contained'
                            size='small'
                            ref={bottonHiddenUpdate}
                        >
                        </Button>
                    </>
                );
            }}
        </Formik>
    );
};