import React from 'react';
import Wrapper from '../../../helpers/wrapper';
import { StepFourContent } from './StepFour.view';
import { SiteSurveyStepFourForm, StepFourProps } from './StepFour.type';
import { useRecoilValue } from 'recoil';
import { SurveyState } from '../../../states/component/Survey';
import { checkSurvey } from '../../../helpers';
import { findMatchingValue } from '../SurveyHelpers';
// import module

const StepFour: React.FC<StepFourProps> = ({
    activeStep,
    publicMode,
    bottonHiddenUpdate,
    handleNext,
    handleBack,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const surveyData = useRecoilValue(SurveyState);
    const tyreAdditive = checkSurvey(surveyData, 'tyreAdditive');

    const optionsSelect = [
        { value: 'no', label: 'No' },
        { value: 'rim eaze', label: 'Rim Eaze' },
        { value: 'rim guard', label: 'Rim Guard' },
        { value: 'rimexcel', label: 'Rimexcel' },
        { value: 'water cart', label: 'Water Cart' },
        { value: 'seal zint', label: 'Seal-Zint' },
        { value: 'tire life', label: 'Tire Life' },
        { value: 'other', label: 'Other' }
    ];

    const dataStepFour: SiteSurveyStepFourForm = {
        bridgestone: checkSurvey(surveyData, 'bridgestone'),
        michelin: checkSurvey(surveyData, 'michelin'),
        goodyear: checkSurvey(surveyData, 'goodyear'),
        otherBranch: checkSurvey(surveyData, 'otherBranch'),
        tyreAdditive: tyreAdditive ? findMatchingValue(tyreAdditive, optionsSelect) ?? 'other' : '',
        tyreAdditiveOther: tyreAdditive ? !findMatchingValue(tyreAdditive, optionsSelect) ? tyreAdditive : '' : '',
        fullName: checkSurvey(surveyData, 'fullName') ?? '',
        jobTitle: checkSurvey(surveyData, 'jobTitle') ?? '',
        company: checkSurvey(surveyData, 'company') ?? '',
        emailPersonal: checkSurvey(surveyData, 'emailPersonal')
    };

    return (
        <StepFourContent
            data-testid='StepFour-testid'
            activeStep={activeStep}
            surveyData={surveyData}
            publicMode={publicMode}
            valuesInitForm={dataStepFour}
            optionsSelect={optionsSelect}
            bottonHiddenUpdate={bottonHiddenUpdate}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            updateSurveyTemporal={updateSurveyTemporal}
            setModalReturnToProgress={setModalReturnToProgress}
        />
    );
};

export default Wrapper(StepFour);