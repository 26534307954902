export const snakeToCamel = (str) =>
    str.toLowerCase().replace(/([-][a-z])/g, (group) => group.toUpperCase().replace('-', ''));

export const dotToCamel = (str) => str.replace(/([.][a-z])/g, (group) => group.toUpperCase().replace('.', ''));

export const convertQs = (str) => str.replace(/([/][a-z|0-9])/g, (text) => text.replace('/', '-'));

export const firstCapital = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const toSnakeCase = (str: string): string => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.replace(/\s+/g, '_').toLowerCase();
};