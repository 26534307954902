import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './MarkAsShipped.type';
import * as view from './MarkAsShipped.view';
import ApiShipper from '../../../api/ApiShipper';
import Wrapper from '../../../helpers/wrapper';
import * as UserState from '../../../states/global/User';
import { Success } from '../../Popup/Popup';
import { OrderAlphabetically } from '../../../helpers/converter';
import { FromUserTimezoneToTimezone, GetDateMask } from '../../../helpers/Converters';
import { IMarkAsShippedForm } from './MarkAsShipped.type';
import { useTranslation } from 'react-i18next';
// import module

const api = new ApiShipper();

const MarkAsShipped: React.FC<type.MarkAsShippedProps> = ({
    open,
    closeModal,
    shippingOrder,
    shippingOrderId,
    setReloadList,
    setRefresh
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [shippersData, setShippers] = useState<any[]>([]);
    const userState = useRecoilValue<any>(UserState.User);
    const getDateMask = useRecoilValue(GetDateMask);
    const fromUserTimezoneToTimezone = useRecoilValue(FromUserTimezoneToTimezone);

    const [valuesInitForm, setvaluesInitForm] = useState<IMarkAsShippedForm>({
        shipperId: {},
        trackingId: '',
        shippedAt: ''
    });

    const handleSubmit = async (e: any, resetForm: any, setSubmitting) => {
        if (!e.trackingId) delete e.trackingId;
        e.shipperId = e.shipperId.id;

        try {
            const {
                data: { shippingOrder }
            }: any = await api.patchGeneralFree(`stock/shipping-order/${shippingOrderId}/ship`, e);
            if (!shippingOrder) {
                throw shippingOrder;
            }
            Success({ text: translate('t.order_marked_as_shipped') as string + ` [ID ${shippingOrder.id}]` });
            resetForm({});
            setSubmitting(false);
            closeModal();
            setReloadList(true);
        } catch (error) {
            console.warn('Error ', error);
        }
    };

    const handleSubmitNewShiper = async (e: any | object, resetForm: any, setSubmitting) => {
        e.shipperLocation = [{ location: shippingOrder.locationFrom.id }];
        e.type = 'person';
        e.trackingUrl = '';

        try {
            const {
                data: { shipper }
            }: any = await api.create(e);

            if (!shipper) {
                throw shipper;
            }
            resetForm({});
            setSubmitting(false);
            setRefresh(true);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const loadMoreShipper = async (search, _, { page }) => {
        let byLocationId = '';
        if (shippingOrder && shippingOrder.locationFrom.id) {
            byLocationId = `&shipperLocationId=${shippingOrder.locationFrom.id}`;
        }

        let endPoint = `stock/shipper?page=${page}&limit=10&order=name:asc${byLocationId}&type=dropdown`;
        if (search) {
            endPoint = `stock/shipper?page=${page}&limit=10&search=${search}&order=name:asc${byLocationId}&type=dropdown`;
        }
        const {
            data: { items }
        }: any = await api.getGeneral(endPoint);

        if (items && !!items.length) {
            setShippers(OrderAlphabetically(items, 'name'));
        }

        return {
            options: items,
            hasMore: items.length >= 1,
            additional: { page: page + 1 }
        };
    };

    const getShipper = useCallback(async () => {
        const { items }: any = await api.get('?limit=200');
        let shippersSlect: any[] = [];
        const idsLocation: any[] = [];
        const { data }: any = await api.getGeneral(`stock/user-location-access/user/${userState.id}`);
        if (data.items.length) {
            data.items.map((item) => {
                return idsLocation.push(item.location.id);
            });
        }

        if (items.length) shippersSlect = items.map((item) => ({ id: item.id, name: item.name }));

        let listShipper: any = [];

        listShipper = shippersSlect.length ? shippersSlect : [];
        if (idsLocation.length) {
            listShipper = items.filter((shipper) =>
                shipper.shipperLocation.find((location) =>
                    idsLocation.find((idLocation) => idLocation === location.location.id)
                )
            );
        }
        !shippersData.length && setShippers(listShipper);
    }, [shippersData.length, userState.id]);

    useEffect(() => {
        if (!shippersData.length) getShipper();

        if (shippingOrder && !!Object.keys(shippingOrder).length) {
            setvaluesInitForm({
                shipperId: shippingOrder.shipper ? shippingOrder.shipper : {},
                trackingId: shippingOrder.trackingId ?? '',
                shippedAt: shippingOrder.shippedAt ? fromUserTimezoneToTimezone(shippingOrder.shippedAt) : ''
            });
        }
    }, [getShipper, shippersData.length, shippingOrder]);

    return (
        <view.MarkAsShippedContent
            data-testid={'MarkAsShipped-testid'}
            open={open}
            closeModal={closeModal}
            shippingOrder={shippingOrder}
            handleSubmit={handleSubmit}
            valuesInitForm={valuesInitForm}
            shippers={shippersData}
            handleSubmitNewShiper={handleSubmitNewShiper}
            shippingOrderId={shippingOrderId}
            loadMoreShipper={loadMoreShipper}
            fromUserTimezoneToTimezone={fromUserTimezoneToTimezone}
            getDateMask={getDateMask}
        />
    );
};

export default Wrapper(MarkAsShipped);
