import React from 'react';
import * as style from './Reports.style';
import { InitMsg, QuicksightReportsContentEl, ReportEl } from './Reports.style';
import { Paper } from '@mui/material';
import { AutocompleteElement } from '../FormElements';
import { ReportsViewProps } from './Reports.type';
import { Loading } from '../../helpers/Loading';
import { useTranslation } from 'react-i18next';
// import module

export const ReportsContent: React.FC<ReportsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const reportsList = [
        {
            value: 'b9af4b89-ee9d-445b-89ab-eefe9b7957f3',
            name: 'sensor_stats'
        },
        {
            value: 'e315a0fe-c7f2-435d-a907-95992ebf7ca0',
            name: 'sensor_stocks_per_month'
        },
        {
            value: '4122ae3a-a6e2-4db3-8648-8d0c88e96e49',
            name: 'hub_report'
        }
    ];

    return (
        <style.ReportsContent data-testid='ReportsContent'>
            <Paper className='cardPageContainer' elevation={1}>
                <div style={{ width: 500 }}>
                    <AutocompleteElement
                        label={translate('t.qs_reports')}
                        value={''}
                        name='reports'
                        options={reportsList}
                        getOptionLabel={(option): string => {
                            if (option && option.name) {
                                return translate(`t.${option.name}`);
                            }
                            return '';
                        }}
                        onChange={(e, value) => {
                            props.setSelectedReport(value.value);
                        }}
                        optionsPropertyLabel='type'
                        optionsPropertyValue='id'
                        lg={12}
                        md={12}
                        sm={12}
                    />
                </div>
                <QuicksightReportsContentEl>
                    {!props.selectedReport && <InitMsg>{translate('t.select_report_to_preview')}</InitMsg>}
                    {props.isLoading ? (
                        <Loading />
                    ) : (
                        <ReportEl id='quicksight-content' ref={props.qsElement}></ReportEl>
                    )}
                </QuicksightReportsContentEl>
            </Paper>
        </style.ReportsContent>
    );
};
