import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Delete, LocationCity } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import {
    FullDataUser,
    IFormAssigned,
    IValuesFormAssigned,
    IstockRole,
    LoadMore,
    ModelLocation,
    Pagination,
    UserStockLocationAccessProps,
    User as UserType
} from './User.type';
import * as style from './User.style';
import * as view from './User.view';
import ApiStockLocation from '../../api/ApiStockLocation';
import ApiUsers from '../../api/ApiUsers';
import ApiUserStockLocationAccess from '../../api/ApiUserStockLocationAccess';
import ApiRegions from '../../api/ApiRegions';
import ApiCustomer from '../../api/ApiCustomer';
import { Success, Warning } from '../Popup/Popup';
import { ColumnsTable, ModalType, ModelStockRole } from '../../types/common';
import { UserStockLocationAccess } from '../../states/component/UserStockLocationAccess';
import { AutocompleteElement } from '../FormElements';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { TypeRegions } from '../../models/ApiRegions.type';
import { ModelCustomerItrack } from '../../models/ApiCustomer.type';
import { IStockLocation } from '../../models/ApiStockLocation.type';
import { ContainerSelectFilter, TextEllipsis } from '../../styled/global.style';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { OrderAlphabetically } from '../../helpers/converter';
import LocationDropDown from '../LocationDropDown/LocationDropDown';
import ApiLocationType from '../../api/ApiLocationType';
import { LocationType } from '../../models/ApiLocationType.type';
import { useTranslation } from 'react-i18next';
// import module

const api = new ApiUserStockLocationAccess();
const apiRegions = new ApiRegions();
const apiCustomer = new ApiCustomer();
const apiUsers = new ApiUsers();
const apiStockLocation = new ApiStockLocation();
const apiStockLocationType = new ApiLocationType();

const User: React.FC<UserStockLocationAccessProps> = () => {
    const setdata = useSetRecoilState<FullDataUser[]>(UserStockLocationAccess);
    const listUsers: FullDataUser[] = useRecoilValue(UserStockLocationAccess);
    const { t: translate } = useTranslation();
    const [dataUser, setdataUser] = useState<UserType | null>(null);
    const [stockRoles, setStockRoles] = useState<ModelStockRole[]>([]);
    const [dataCustomers, setdataCustomers] = useState<ModelCustomerItrack[]>([]);
    const [stockRole, setStockRole] = useState<ModelStockRole | Record<string, unknown>>();
    const [openModalStockLocations, setOpenModalStockLocations] = useState<boolean>(false);
    const [loadinModalStockLocations, setloadinModalStockLocations] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [filterCustomer, setFilterCustomer] = useState<string>('');
    const [filterRegion, setFilterRegion] = useState<string | number>('');
    const [regions, setRegions] = useState<TypeRegions[] | undefined>([]);
    const [filterStockLocation, setFilterStockLocation] = useState<string>('');
    const [assignedStockLocations, setAssignedStockLocations] = useState<ModelLocation[]>([]);
    const [assignedStockLocationsId, setAssignedStockLocationsId] = useState<Array<number | string>>([]);
    const [availableStockLocations, setAvailableStockLocations] = useState<IStockLocation[]>([]);
    const [stockLocationsRemoved, setStockLocationsRemoved] = useState<IStockLocation[]>([]);
    const [pageCount, setPageCount] = useState<Pagination>({ users: 0, availableStockLocations: 0 });
    const [total, setTotal] = useState<Pagination>({ users: 0, availableStockLocations: 0 });
    const [refresh, setRefresh] = useState<boolean>(false);
    const [filtersAvailableLocation, setFiltersAvailableLocation] = useState([]);
    const [orderAvailableLocation, setOrderAvailableLocation] = useState([]);
    const [locationFilter, setLocationFilter] = useState<
        { locationFilter: string; filterRegion: string; customerRegion: string } | Record<string, unknown>
    >({ locationFilter: '', filterRegion: '', customerRegion: '' });
    const [valuesInitFormAssignedStockLocation, setvaluesInitFormAssignedStockLocation] = useState<IFormAssigned>({
        stockRole: { id: '', name: '' }
    });
    const [dataModal, setdataModal] = useState<ModalType>({
        isAdd: true,
        title: translate('t.add_new_user')
    });
    const [locationType, setLocationType] = useState<LocationType[]>([]);
    const accessorsColumns = { name: 'name', type: 'locationType.name' };
    const FormschemaAssignedStockLocation = Yup.object().shape({
        stockRole: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        })
    });

    async function getData(page, limit, order, filter, { filterRegion, customerRegion, locationFilter }) {
        const filters = `${filterRegion}${customerRegion}${locationFilter}`;

        return await apiUsers.getAll(`user?type=active${filters}`, page, limit, order, filter);
    }

    const getAllUsers = useCallback(
        async (page, limit, order, filter, { filterRegion, customerRegion, locationFilter }): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator } = await getData(page, limit, order, filter, {
                filterRegion,
                customerRegion,
                locationFilter
            });

            const { data }: ModelStockRole = await api.getGeneral('stock/role');

            setdata(items.length ? items : []);
            setStockRoles(data && data.items.length ? OrderAlphabetically(data.items, 'name') : []);
            setPageCount({ ...pageCount, users: paginator.pageCount });
            setTotal({ ...total, users: paginator.totalCount });
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, { filterRegion, customerRegion, locationFilter }) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 200, order, filter, {
                filterRegion,
                customerRegion,
                locationFilter
            });

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const getSelect = async () => {
        const { items }: TypeRegions = await apiRegions.get('?limit=200');
        const dataCustomers: ModelCustomerItrack[] = await apiCustomer.getCustomersCodeBook();

        items && setRegions(OrderAlphabetically(items, 'translationString'));
        dataCustomers && setdataCustomers(OrderAlphabetically(dataCustomers, 'name'));
    };

    const handleClose = (): void => {
        setdataModal({
            isAdd: true,
            title: translate('t.add_new_user')
        });
        setOpenModalStockLocations(false);
        setAssignedStockLocations([]);
        setAssignedStockLocationsId([]);
        setStockLocationsRemoved([]);
        setStockRole({});
        setdataUser(null);
        setvaluesInitFormAssignedStockLocation({
            stockRole: { id: '', name: '' }
        });
    };

    const handleSubmitAssignedStockLocation = async (
        e: IstockRole,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting: (submitting: boolean) => void
    ): Promise<void | boolean> => {
        const valuesForm: IValuesFormAssigned = {};

        const existItemsInTheAssignedStockLocation = !!assignedStockLocations.length;
        valuesForm.stockRole = e.stockRole.id;

        try {
            if (existItemsInTheAssignedStockLocation && dataUser) {
                const assignedStockLocationIds: Array<number | string> = assignedStockLocations.map(
                    (stockL) => stockL.id
                );
                valuesForm.userLocationAccess = assignedStockLocationIds.map((stockLocationId) => ({
                    location: stockLocationId,
                    role: valuesForm.stockRole
                }));

                const {
                    data: { user }
                }: any = await api.patchGeneralFree(`user/${dataUser.id}`, valuesForm);

                Success({ text: `${translate('t.user_modified_success')} - ${user.firstname} ${user.lastname}` });
                handleClose();
                setRefresh(true);
                resetForm({});
                setSubmitting(false);
            } else {
                Warning({ text: translate('p.assigned_stock_location_list_is_empty_please_add_stock_location') });
            }
        } catch (error) {
            console.warn('Error to sending data ', error);
        }
    };

    const deleteRow = async (onClose, row: FullDataUser): Promise<void> => {
        try {
            const user: FullDataUser = await apiUsers.removeGeneral(`user/${row.id}/stock`, '');

            if (!user) {
                throw user;
            }
            setRefresh(true);
            onClose();
            Success({ text: `${translate('t.user_successfully_removed')} - ${row.firstname} ${row.lastname}` });
        } catch (error) {
            console.warn('Error to send data ', error);
        }
    };

    const confirmDeleteRow = (row) => {
        popUpConfirm(
            `${translate('t.user')}: ${row.firstname} ${row.lastname}`,
            translate('p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: translate('t.yes'),
                no: translate('t.no')
            }
        );
    };

    const filtersOutSide: JSX.Element = (
        <Grid container justify-content='flex-start' direction='row' className='containerButtonsFilterHeaderDataTable'>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className='textFieldFormik'>
                <Autocomplete
                    id='stockLocationFilter'
                    fullWidth
                    options={regions ? regions : []}
                    onChange={(_, value) => {
                        setFilterRegion(value ? value.id : '');
                    }}
                    size='small'
                    getOptionLabel={(option) =>
                        option.translationString
                            ? option.translationString
                                .replace('_', ' ')
                                .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
                            : ''
                    }
                    renderInput={(params) => (
                        <TextField
                            value={filterRegion}
                            name='stockLocationFilter'
                            {...params}
                            label={translate('t.region')}
                            variant='outlined'
                        />
                    )}
                />
            </Grid>

            <AutocompleteElement
                label={translate('t.customer')}
                name='customerFilter'
                size='small'
                value={filterCustomer}
                options={dataCustomers ? dataCustomers : []}
                onChange={(_, value) => {
                    setFilterCustomer(value ? value.id : '');
                }}
                optionsPropertyLabel='name'
                optionsPropertyValue='id'
                xl={3}
                lg={4}
                sm={6}
                xs={12}
            />

            <LocationDropDown
                name='stockLocationFilter'
                label={translate('t.location')}
                value={filterStockLocation[0]}
                error={false}
                onChange={(value) => {
                    setFilterStockLocation(value ? value.id : '');
                }}
                required={false}
                xl={5}
                lg={4}
                sm={6}
                xs={12}
                extendUrl='/enabled'
            />
        </Grid>
    );

    const filteringOrSortingForLocation = (list: IStockLocation[], filter, order): IStockLocation[] => {
        if (filter.length || order.length) {
            let filteredOrOrdered = list;
            if (filter.length) {
                const getFilterName = filter.find(f => f.id === accessorsColumns.name)?.value?.value ?? '';
                const getFilterlocationTypeName = filter.find(f => f.id === accessorsColumns.type)?.value?.value ?? '';

                if (getFilterName) {
                    filteredOrOrdered = filteredOrOrdered.filter(
                        (item) => item.name.toLowerCase().includes(getFilterName.toLowerCase())
                    );
                }

                if (getFilterlocationTypeName) {
                    filteredOrOrdered = filteredOrOrdered.filter(
                        (item) =>
                            item?.locationType?.name.toLowerCase().includes(getFilterlocationTypeName.toLowerCase())
                    );
                }

            }
            if (order.length) {
                const newFilteredOrOrdered: IStockLocation[] = [...filteredOrOrdered];
                newFilteredOrOrdered.sort((a, b) => {
                    for (const { id, desc } of order) {
                        const aValue: string = getValue(a, id);
                        const bValue: string = getValue(b, id);

                        if (aValue !== bValue) {
                            return !desc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                        }
                    }
                    return 0;
                });

                filteredOrOrdered = newFilteredOrOrdered;
            }
            return filteredOrOrdered;
        }
        return list;
    };

    const getValue = (item: IStockLocation | string, path: string): string => {
        const properties = path.split('.');
        let value: IStockLocation | string = item;

        for (const property of properties) {
            if (value && typeof value === 'object' && property in value) {
                value = value[property];
            } else {
                return '';
            }
        }

        return value as string;
    };

    const getAvailableStockLocations = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setAvailableStockLocations([]);
            const { items, paginator } = await apiStockLocation.getAll(
                page,
                limit,
                order,
                filter,
                dataModal?.id ? `/enabled?userLocationAccessUserNot=${dataModal?.id}` : '/enabled'
            );

            setFiltersAvailableLocation(filter);
            setOrderAvailableLocation(order);

            const newList: IStockLocation[] | [] = items
                ? items.map((stockLocation) => {
                    if (assignedStockLocationsId.length) {
                        if (assignedStockLocationsId.find((locationID) => locationID === stockLocation.id))
                            stockLocation.notAvailable = true;
                        return stockLocation;
                    }
                    return stockLocation;
                })
                : [];
            if (stockLocationsRemoved && !!stockLocationsRemoved.length) {
                setAvailableStockLocations(
                    filteringOrSortingForLocation(
                        [...stockLocationsRemoved, ...newList],
                        filter,
                        order
                    )
                );
            } else {
                setAvailableStockLocations(newList);
            }
            setPageCount({ ...pageCount, availableStockLocations: paginator.pageCount });
            setTotal({ ...total, availableStockLocations: paginator.totalCount });
            setLoading(false);
        },
        [setAvailableStockLocations,
            assignedStockLocationsId,
            dataModal,
            stockLocationsRemoved,
            setOrderAvailableLocation,
            setFiltersAvailableLocation
        ]
    );

    const OpenModalStockLocations = async (user: UserType): Promise<void> => {
        setloadinModalStockLocations(true);
        setOpenModalStockLocations(true);
        try {
            const { items }: IStockLocation = await apiStockLocation.get();

            const { data }: FullDataUser = await apiUsers.getGeneral(`user/${user.id}`);
            let listAvailableStockLocations: IStockLocation[] | undefined = items;

            if (data && Object.keys(data.user).length) {
                if (data.user.stockRole) {
                    setStockRole(data.user.stockRole);
                    setvaluesInitFormAssignedStockLocation({
                        stockRole: {
                            id: data.user.stockRole.id,
                            name: data.user.stockRole.name
                        }
                    });
                    setdataModal({
                        isAdd: false,
                        title: translate('t.stock_location_assignment'),
                        id: user.id
                    });
                }
                const listStockLocations: ModelLocation[] = data.user.userLocationAccess.map(
                    (stockLocation) => stockLocation.location
                );
                const isExistStockLocation = !!listStockLocations.length;

                if (isExistStockLocation) {
                    setAssignedStockLocations(listStockLocations);
                    const stockLocationsIds: Array<number | string> = listStockLocations.map(
                        (stockLocation) => stockLocation.id
                    );
                    setAssignedStockLocationsId(stockLocationsIds);

                    if (items && !!items.length) {
                        listAvailableStockLocations = items.filter(
                            (availableStockLocation) => !stockLocationsIds.includes(availableStockLocation['id'])
                        );
                    }
                }
            }

            listAvailableStockLocations && setAvailableStockLocations(listAvailableStockLocations);
            setdataUser(user);
            setloadinModalStockLocations(false);
        } catch (error) {
            console.warn('Error get List stock Location');
        }
    };

    const changeLocationAvailability = (location: IStockLocation, isAdd: boolean): IStockLocation[] => {
        if (isAdd) {
            return availableStockLocations.filter((item) => item.id !== location.id);
        }
        const newList: IStockLocation[] = availableStockLocations.filter((locat) => locat.id !== location.id);
        return [location, ...newList];
    };

    const AddOrRemoveStockLocation = (row: IStockLocation, isAdd: boolean): void => {
        if (isAdd) {
            assignedStockLocationsId.push(row.id);
            setAssignedStockLocationsId(assignedStockLocationsId);
            setAvailableStockLocations(changeLocationAvailability(row, true));

            if (assignedStockLocations) {
                const exist: ModelLocation[] | undefined = assignedStockLocations.filter(
                    (location) => location.id === row.id
                );
                if (exist && !exist.length) {
                    setAssignedStockLocations([row, ...assignedStockLocations]);
                }
                setStockLocationsRemoved(stockLocationsRemoved.filter((location) => location.id !== row.id));
            }
            return;
        }

        const newListAssigned: ModelLocation[] =
            assignedStockLocations && !!assignedStockLocations.length
                ? assignedStockLocations.filter((stockLocation) => stockLocation.id !== row.id)
                : [];

        setAssignedStockLocationsId(assignedStockLocationsId.filter((item) => item !== row.id));
        setStockLocationsRemoved([row, ...stockLocationsRemoved]);
        setAvailableStockLocations(
            filteringOrSortingForLocation(
                changeLocationAvailability(row, false),
                filtersAvailableLocation,
                orderAvailableLocation
            )
        );
        setAssignedStockLocations(newListAssigned);
    };

    const getLocationType = async (): Promise<void> => {
        const locationTypeData = await apiStockLocationType.getLocationTypeCodeBook();
        setLocationType(locationTypeData);
    };

    const columnsAvailableStockLocations: ColumnsTable[] = [
        {
            Header: translate('t.name'),
            accessor: accessorsColumns.name,
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 350
        },
        {
            Header: translate('t.location_type'),
            accessor: accessorsColumns.type,
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <Autocomplete
                                getOptionLabel={(option) => option.name}
                                id='skuTypeFilter'
                                onChange={(_, value) => {
                                    const values = !value
                                        ? undefined
                                        : {
                                            name: column.Header,
                                            humanValue: value.name,
                                            value: value.name
                                        };
                                    setFilter(column.id, values);
                                }}
                                defaultValue={
                                    locationType.find((type) => `${type.name}` === `${column.filterValue?.value}`)
                                }
                                options={locationType}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={column.Header}
                                        value={column.filterValue?.value || null}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: translate('t.action'),
            accessor: 'action',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<IStockLocation>) => {
                return original.notAvailable ? (
                    <style.ButtonGrey
                        id='removeAssignedStockLocationNotAvailable'
                        variant='contained'
                        size='small'
                        onClick={() => {
                            return false;
                        }}
                        disabled
                    >
                        {translate('t.added')}
                    </style.ButtonGrey>
                ) : (
                    <style.ButtonSuccess
                        id='removeAssignedStockLocation'
                        variant='contained'
                        size='small'
                        onClick={() => {
                            AddOrRemoveStockLocation(original, true);
                        }}
                    >
                        {translate('t.add')}
                    </style.ButtonSuccess>
                );
            }
        }
    ];

    const loadMoreRoles = async (search, _, NumberPage): Promise<LoadMore> => {
        let endPoint = `stock/role?type=active&page=${NumberPage.page}&limit=10`;
        if (search) {
            endPoint = `stock/role?type=active&page=${NumberPage.page}&limit=10&name=${search}`;
        }
        const { data }: ModelStockRole = await api.getGeneral(endPoint);

        if (data && !!data.items.length) {
            setStockRoles(OrderAlphabetically(data.items, 'name'));
        }

        return {
            options: data ? OrderAlphabetically(data.items, 'name') : [],
            hasMore: NumberPage?.paginator?.pageCount > NumberPage?.paginator?.page,
            additional: { page: NumberPage.page + 1 }
        };
    };

    const columnsAssignedStockLocations: ColumnsTable[] = [
        {
            Header: translate('t.name'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 350
        },
        {
            Header: translate('t.location_type'),
            accessor: 'locationType.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: translate('t.action'),
            accessor: 'action',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                <style.ButtonDanger
                    id='removeAssignedStockLocation'
                    variant='contained'
                    size='small'
                    onClick={() => {
                        AddOrRemoveStockLocation(original, false);
                    }}
                >
                    {translate('t.remove')}
                </style.ButtonDanger>
            )
        }
    ];

    const ActionsButtons = (original: FullDataUser): JSX.Element => {
        return (
            <>
                <Typography
                    className='dataTableActionsBtn'
                    onClick={() =>
                        OpenModalStockLocations({
                            id: original.id,
                            firstname: original.firstname,
                            lastname: original.lastname,
                            stockRol: original.stockRole
                        })
                    }
                >
                    <LocationCity />
                    {/* <span>{Trans('messages.t.stock_location')}</span> */}
                    <span>{translate('jira.jira_configuration')}</span>
                </Typography>

                <Typography
                    className={`dataTableActionsBtn ${!original.stockRole ? 'disabled' : 'danger'}  `}
                    onClick={() => (!original.stockRole ? '' : confirmDeleteRow(original))}
                >
                    <Delete />
                    <span>{translate('t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: translate('t.username'),
            accessor: 'username',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            minWidth: 200,
            Cell: ({ row }: CellProps<FullDataUser>) => (
                <TextEllipsis title={row.original.username}> {row.original.username} </TextEllipsis>
            )
        },
        {
            Header: translate('t.first_name'),
            accessor: 'firstname',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            minWidth: 200,
            Cell: ({ row }: CellProps<FullDataUser>) => (
                <TextEllipsis title={row.original.firstname}> {row.original.firstname} </TextEllipsis>
            )
        },
        {
            Header: translate('t.last_name'),
            accessor: 'lastname',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            minWidth: 200,
            Cell: ({ row }: CellProps<FullDataUser>) => (
                <TextEllipsis title={row.original.lastname}> {row.original.lastname} </TextEllipsis>
            )
        },
        {
            Header: translate('t.email'),
            accessor: 'email',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            minWidth: 280,
            Cell: ({ row }: CellProps<FullDataUser>) => (
                <TextEllipsis title={row.original.email}> {row.original.email} </TextEllipsis>
            )
        },
        {
            Header: translate('t.role'),
            accessor: 'stockRole.name',
            disableGroupBy: true,
            aggregate: 'count',
            minWidth: 180,
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<FullDataUser>) =>
                original.stockRole ? (
                    original.stockRole.name
                ) : (
                    <span style={{ color: '#f60' }}>{translate('t.not_a_stock_control_user')}</span>
                )
        },
        {
            Header: translate('t.customer'),
            accessor: 'customer.name',
            disableGroupBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: translate('t.action'),
            accessor: 'action',
            width: 60,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps<FullDataUser>) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    useEffect(() => {
        getSelect();
    }, []);

    useEffect(() => {
        filterRegion
            ? setLocationFilter({ ...locationFilter, filterRegion: `&customerRegionId=${filterRegion}` })
            : setLocationFilter({ ...locationFilter, filterRegion: '' });
    }, [filterRegion]);

    useEffect(() => {
        filterCustomer
            ? setLocationFilter({ ...locationFilter, customerRegion: `&customerId=${filterCustomer}` })
            : setLocationFilter({ ...locationFilter, customerRegion: '' });
    }, [filterCustomer]);

    useEffect(() => {
        filterStockLocation
            ? setLocationFilter({ ...locationFilter, locationFilter: `&locationId=${filterStockLocation}` })
            : setLocationFilter({ ...locationFilter, locationFilter: '' });
    }, [filterStockLocation]);


    useEffect(() => {
        getLocationType();
    }, []);

    return (
        <view.User
            data-testid={'UserStockLocationAccess-testid'}
            open={openModalStockLocations}
            isLoading={loading}
            closeModal={handleClose}
            formschema={FormschemaAssignedStockLocation}
            handleSubmit={handleSubmitAssignedStockLocation}
            columns={columns}
            valuesInitForm={valuesInitFormAssignedStockLocation}
            dataModal={dataModal}
            fullScreenModal={getFullScreenModal()}
            roles={stockRoles}
            availableStockLocations={availableStockLocations}
            listUsers={listUsers}
            filtersOutSide={filtersOutSide}
            dataUser={dataUser}
            columnsAvailableStockLocations={columnsAvailableStockLocations}
            columnsAssignedStockLocations={columnsAssignedStockLocations}
            assignedStockLocations={assignedStockLocations}
            stockRole={stockRole}
            loadinModalStockLocations={loadinModalStockLocations}
            fetchData={getAllUsers}
            fetchDataExport={getForExport}
            fetchDataStockLocationsAvailable={getAvailableStockLocations}
            total={total}
            pageCount={pageCount}
            refresh={refresh}
            loadMoreRoles={loadMoreRoles}
            extraFiltering={locationFilter}
        />
    );
};

export default User;
